import {__, None, Option, overwriteArray, Some, VariableId} from "@utils";
import {ArrayVariable, BusinessVariable} from "@shared-model";
import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  HeaderPropertiesState,
  LayoutsPropertiesState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  RepeatableContext,
  RepeatableContextComponentState,
  ScreenComponentRefState
} from "../..";
import {RepeatableContainer} from "@screen-common";


export class RepeatableContainerState extends RepeatableContextComponentState {
    static className = "RepeatableContainerState";
    className() {
      return RepeatableContainerState.className;
    }

    readonly layoutsState = new LayoutsPropertiesState("", this.properties);
    readonly paddingsState = new PaddingsPropertiesState("", this.properties);
    readonly bordersState = new BordersPropertiesState(this.properties);
    readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

    constructor(public override innerContext: Option<RepeatableContext>,
      override readonly properties: PropertiesStateHolder,
                override readonly parent: Option<ParentComponentId>) {
      super(properties);
    }

    get contentTextSize() {return this.properties.optionalStringProperty("textSize")}
    get contentTextColor() {return this.properties.optionalStringProperty("textColor")}
    get contentTextFont() {return this.properties.optionalStringProperty("textFont")}

    get header() {return this.properties.optionalI18nTextProperty("header")}

    get headerVisible() {return this.properties.booleanProperty("headerVisible")}
    readonly headerState = new HeaderPropertiesState(this.properties);

    get paginationEnabled() {return this.properties.booleanProperty("paginationEnabled")}
    get pageSize() {return this.properties.numberProperty("pageSize")}

    get minEntries() {
      return this.properties.optionalNumberProperty("minEntries");
    }

    get maxEntries() {
      return this.properties.optionalNumberProperty("maxEntries");
    }

    get entriesLayout() {
      return this.properties.stringProperty("entriesLayout");
    }

    get entriesGapColumn() {
      return this.properties.optionalStringProperty("entriesGapColumn");
    }

    get entriesGapRow() {
      return this.properties.optionalStringProperty("entriesGapRow");
    }

    get entriesLayoutWrap() {
      return this.properties.stringProperty("entriesLayoutWrap");
    }

    pushToInnerContext(entryId: VariableId) {
      this.innerContext.getOrError("No inner context").entries.push(entryId);
    }

    removeFromModel(entryId: VariableId) {
      const innerContext = this.innerContext.getOrError("No inner context").entries;
      const index = __(innerContext).findIndexOf(e => e.id == entryId.id);
      if(index.isDefined()) {
        innerContext.splice(index.get(), 1);
      }
    }

    updateInnerContext(innerContext: RepeatableContext) {
      if(this.innerContext.isDefined() && this.innerContext.get().contextId.id == innerContext.contextId.id) {
        overwriteArray(this.innerContext.get().entries, innerContext.entries);
      } else {
        this.innerContext = Some(innerContext);
      }
    }

    clearInnerContext(): void {
      this.innerContext = None();
    }

    override updateModel(modelName: string, value: Option<BusinessVariable>) {
      switch (modelName) {
        case RepeatableContainer.model:
          if(value.isDefined() && value.get() instanceof ArrayVariable) {
            this.properties.putValue(RepeatableContainer.model, value.get());
          } else if (value.isDefined()) {
            throw new Error("Model is not of expected type Array[Object] but [" + value.get().simpleValueType()+"]");
          } else {
            this.properties.clearValue(RepeatableContainer.model);
          }
          break;
        default: throw new Error("Model ["+modelName+"] is not supported by "+this.className());
      }
    }


    static copy(other: RepeatableContainerState) {
      return new RepeatableContainerState(
        Option.copy(other.innerContext, RepeatableContext.copy),
        PropertiesStateHolder.copy(other.properties),
        Option.copy(other.parent, ParentComponentId.copy));
    }

  }

  export class RepeatableContainerRefState extends ScreenComponentRefState {
    static className = "RepeatableContainerRefState";
    className() {
      return RepeatableContainerRefState.className;
    }

    constructor(override readonly properties: PropertiesStateHolder) {
      super(properties);
    }

    get lengthEditable() {
      return this.properties.booleanProperty("lengthEditable");
    }

    get sortable() {
      return this.properties.booleanProperty("sortable");
    }

    static copy(other: RepeatableContainerRefState) {
      return new RepeatableContainerRefState(PropertiesStateHolder.copy(other.properties));
    }

  }
