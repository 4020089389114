import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {ModalContainerViewModel} from "./ModalContainerViewModel";
import {ContainerChildrenHandler} from "../container-children-handler";

@Component({
  selector: 'my-modal-container',
  templateUrl: './modal-container.component.html',
  host: {
    "[class]": "'ModalContainer componentBody ' + viewModel.customCssClass + ' ' + viewModel.cssClasses",
    "[style]": "viewModel.combinedCss",
    "[class.withHeader]": "viewModel.headerVisible",
    "(click)": "viewModel.onClick()",
    "[class.validationError]": "viewModel.validationError",
  }
})
export class ModalContainerComponent implements OnInit, OnDestroy {
  @Input({required:true}) viewModel!: ModalContainerViewModel;

  readonly children = new ContainerChildrenHandler();

  private subscriptions: Array<number> = [];


  ngOnInit(): void {
    this.subscriptions.push(this.viewModel.shared.eventBus.on(this.viewModel.shared.eventBus.componentsStateUpdated, () => {
      this.viewModel.contentChanged.emit();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => this.viewModel.shared.eventBus.unsubscribe(subscription));
  }

}

