import {
  $$,
  $$Element,
  DragBehavior,
  getElementPositionAndSize,
  getElementPosition,
  getScrollPosition,
  getElementSize, global,
  myRequestAnimationFrameNoAngular,
  mySetTimeoutNoAngular,
  Nothing,
  PositionXY, scrollElementToView,
  setScrollXPosition,
  setScrollYPosition, toastr
} from "@utils";
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef} from "@angular/core";
import {Observable, Subscription} from "rxjs";

class ScrollDrag extends DragBehavior<Nothing> {

  position: PositionXY | null = null;

  constructor(selection: $$Element,
              readonly containerSelection: $$Element,
              readonly $glass: $$Element,
              readonly onDragged: (x: number, y: number) => void) {
    super(selection);
  }

  dragOrigin(draggedElement: $$Element, eventPosition: PositionXY, model: Nothing): { x: number; y: number } {
    return getElementPosition(draggedElement).minusToPosition(getElementPosition(this.containerSelection));
  }

  dragStarted(draggedElement: $$Element, eventPosition: PositionXY, model: Nothing): void {
    draggedElement.addClass("dragged");
    this.$glass.addClass("visible");
    this.onDragged(eventPosition.x, eventPosition.y);
  }

  dragged(draggedElement: $$Element, eventPosition: PositionXY, model: Nothing): void {
    this.onDragged(eventPosition.x, eventPosition.y);
  }

  dragEnded(draggedElement: $$Element, eventPosition: PositionXY, model: Nothing): void {
    draggedElement.classed("dragged", false);
    this.$glass.removeClass("visible");
    this.onDragged(eventPosition.x, eventPosition.y);
  }

  clicked(element: $$Element, eventPosition: PositionXY, model: Nothing): void {
  }
}

class ScrollPositionHolder {

  static readonly INSTANCE = new ScrollPositionHolder();

  private positions: Map<string, {x: number, y: number}> = new Map<string, {x: number; y: number}>();

  rememberPosition(identifier: string, x: number, y: number) {
    if(identifier.trim().length > 0) {
      this.positions.set(identifier, {x, y});
    }
  }

  getPosition(identifier: string): {x: number, y: number} {
    return this.positions.get(identifier) || {x: 0, y: 0};
  }
}


@Component({
  selector: "my-scroll",
  templateUrl: "./scroll.component.html",
  host: {
    "[class.disabled]": "disabled",
    "[class.left-handed]": "leftHanded"
  }
})
export class ScrollComponent implements OnInit, OnDestroy {
  private timeoutRequest?: number;
  private animationFrameRequest?: number;
  private initPosition: { x: number; y: number } = {x: 0, y: 0};
  private initMaxTimestamp: number = Date.now() + 5000;

  @Input() set scrollTopTrigger(value: any) {
    this._scrollTopTrigger = value;
    this.onScrollTopTriggerChanged();
  }

  @Input() scrollBottom: boolean = false;

  @Input() topShadow: boolean = true;
  @Input() bottomShadow: boolean = true;

  @Output() scrolledOutsideAngular = new EventEmitter<void>();
  @Output() scrolledAlmostToEndNoAngular = new EventEmitter<void>();

  @Input() contentSizeChanged?: EventEmitter<void>;
  private subscriptions: Array<Subscription> = [];

  @Input() millisAfterSizeChanged: number = 500;

  @Input() adjustHeight: boolean = false;
  @Input() adjustWidth: boolean = false;

  @Input() contentClass: string = "";

  @Input() horizontal = false;

  @Input() disabled: boolean = false;
  @Input() leftHanded: boolean = false;

  @Input() scrollToComponentMargin?: number;
  @Input() scrollToComponent?: Observable<$$Element>;

  @Input() scrollStateIdentifier: string = ""; // used to remember scroll position

  private _scrollTopTrigger: any;

  private visible = true;

  private $sizer!: $$Element;
  private $container!: $$Element;
  private container!: HTMLElement;
  private $glass!: $$Element;
  private $body!: $$Element;
  private body!: HTMLElement;
  private $content!: $$Element;
  private content!: HTMLElement;
  private $verticalBar!: $$Element;
  private $horizontalBar!: $$Element;
  private $verticalHandle!: $$Element;
  private $verticalBackground!: $$Element;
  private $horizontalHandle!: $$Element;
  private $horizontalBackground!: $$Element;
  private $shadow!: $$Element;


  private lastSizes = "";
  private lastVerticalVisible = false;
  private lastHorizontalVisible = false;

  private updatesLimitTimestamp = Date.now() + 200;


  private verticalClickScrollInProgress: boolean = false;
  private verticalClickScrollTimeout: number|undefined;

  private horizontalClickScrollInProgress: boolean = false;
  private horizontalClickScrollTimeout: number|undefined;

  private pointerX: number = 0;
  private pointerY: number = 0;

  onScrollTopTriggerChanged() {
    setScrollYPosition(this.body, 0);
  }

  constructor(private readonly viewContainerRef: ViewContainerRef) {}

  ngOnInit() {

    this.$container = $$(this.viewContainerRef);
    this.container = this.$container.getAsHtmlElement();
    this.$glass = this.$container.findOrError(".scrollGlass");
    this.$body = this.$container.findOrError(".scrollBody");
    this.$sizer = this.$container.findOrError(".scrollSizer");
    this.body = this.$body.getAsHtmlElement();
    this.$content = this.$container.findOrError(".scrollContent");
    this.content = this.$content.getAsHtmlElement();
    this.$verticalBar = this.$container.findOrError(".verticalScrollBar");
    this.$horizontalBar = this.$container.findOrError(".horizontalScrollBar");
    this.$verticalHandle = this.$container.findOrError(".verticalScrollHandle");
    this.$verticalBackground = this.$container.findOrError(".verticalScrollBackground");
    this.$horizontalHandle = this.$container.findOrError(".horizontalScrollHandle");
    this.$horizontalBackground = this.$container.findOrError(".horizontalScrollBackground");
    this.$shadow = this.$container.findOrError(".topBottomShadowLayer");

    new ScrollDrag(this.$verticalHandle, this.$container, this.$glass, (x: number, y: number) => {
      const containerSize = getElementSize(this.container);
      const contentSize = getElementSize(this.content);
      const scrollY = y * contentSize.height / containerSize.height;
      setScrollYPosition(this.body, scrollY);
    }).init();

    new ScrollDrag(this.$horizontalHandle, this.$container, this.$glass, (x: number, y: number) => {
      const containerSize = getElementSize(this.container);
      const contentSize = getElementSize(this.content);
      const scrollX = x * contentSize.width / containerSize.width;
      setScrollXPosition(this.body, scrollX);
    }).init();

    global.zone.runOutsideAngular(() => {
      this.$body.on("scroll", event => {
        this.updatePositions(false);
      });
    });

    this.$verticalBackground.pointerdown(event => {

      this.$verticalBackground.getAsHtmlElement().setPointerCapture(event.pointerId);

      this.verticalClickScrollInProgress = true;
      if(this.verticalClickScrollTimeout) {
        clearTimeout(this.verticalClickScrollTimeout);
      }
      this.clickScrollY(400, 0.7);
    });

    this.$verticalBackground.pointerup(event => {
      this.$verticalBackground.getAsHtmlElement().releasePointerCapture(event.pointerId);
      this.verticalClickScrollInProgress = false;
      if(this.verticalClickScrollTimeout) {
        clearTimeout(this.verticalClickScrollTimeout);
        this.verticalClickScrollTimeout = undefined;
      }
    });


    this.$verticalHandle.on<WheelEvent>("wheel", event => {
      setScrollYPosition(this.body, getScrollPosition(this.body).y + event.deltaY);
    });
    this.$verticalBackground.on<WheelEvent>("wheel", event => {
      setScrollYPosition(this.body, getScrollPosition(this.body).y + event.deltaY);
    });

    if(this.horizontal) {
      this.$horizontalBackground.pointerdown(event => {

        this.$horizontalBackground.getAsHtmlElement().setPointerCapture(event.pointerId);

        this.horizontalClickScrollInProgress = true;
        if (this.horizontalClickScrollTimeout) {
          clearTimeout(this.horizontalClickScrollTimeout);
        }
        this.clickScrollY(400, 0.7);
      });

      this.$horizontalBackground.pointerup(event => {
        this.$horizontalBackground.getAsHtmlElement().releasePointerCapture(event.pointerId);
        this.horizontalClickScrollInProgress = false;
        if (this.horizontalClickScrollTimeout) {
          clearTimeout(this.horizontalClickScrollTimeout);
          this.horizontalClickScrollTimeout = undefined;
        }
      });
    }

    myRequestAnimationFrameNoAngular(() => {
      if(this.scrollBottom) {
        const scrollBody = $$(this.viewContainerRef).findOrError(".scrollBody");
        setScrollYPosition(scrollBody, scrollBody.getAsHtmlElement().scrollHeight);
      }

      this.updatePositions(true)
    });

    // window.addEventListener("pointermove", this.windowPositionListener);

    if(this.contentSizeChanged) {
      this.subscriptions.push(this.contentSizeChanged.subscribe(() => {
        this.updatesLimitTimestamp = Date.now() + this.millisAfterSizeChanged;
        this.updatePositions(true);
        this.moveToInitPosition();
      }));
    }

    if(this.scrollToComponent) {
      this.subscriptions.push(this.scrollToComponent.subscribe(element => {
        this.onScrollToComponent(element);
      }));
    }

    if(this.scrollStateIdentifier.trim().length > 0) {
      this.initPosition = ScrollPositionHolder.INSTANCE.getPosition(this.scrollStateIdentifier);
      this.moveToInitPosition();
    }

    this.moveToInitPosition();
  }

  moveToInitPosition() {
    if(this.initPosition.y > 0 || this.initPosition.x > 0 && this.initMaxTimestamp > Date.now()) {
      requestAnimationFrame(() => {
        setScrollXPosition(this.body, this.initPosition.x);
        setScrollYPosition(this.body, this.initPosition.y);
      });
    }
  }

  windowPositionListener = (event: PointerEvent) => {
    this.pointerX = event.x;
    this.pointerY = event.y;
  }

  clickScrollY(delay: number, scrollAmount: number) {
    const handlePosition = getElementPositionAndSize(this.$verticalHandle);
    if(this.pointerY < handlePosition.y) {
      // scroll up
      setScrollYPosition(this.body, getScrollPosition(this.body).y - getElementSize(this.container).height * scrollAmount);
    } else if(this.pointerY > handlePosition.endY) {
      // scroll down
      setScrollYPosition(this.body, getScrollPosition(this.body).y + getElementSize(this.container).height * scrollAmount);
    }

    if(this.verticalClickScrollTimeout) {
      clearTimeout(this.verticalClickScrollTimeout);
    }

    if(this.verticalClickScrollInProgress) {
      this.verticalClickScrollTimeout = mySetTimeoutNoAngular(() => {
        this.clickScrollY(20, 0.2);
      }, delay);
    }
  }

  private updatePositions(loop: boolean) {

    const containerSize = getElementSize(this.container);
    const contentSize = getElementSize(this.content);
    const bodySize = getElementSize(this.body);
    const scrollPosition = getScrollPosition(this.body);

    if(this.horizontal || this.adjustWidth) {
      $$(this.content).styles({minWidth: containerSize.width + "px"});
    } else {
      $$(this.content).styles({minWidth: containerSize.width + "px", width: containerSize.width + "px"});
    }

    if(this.adjustHeight) {
      this.$sizer.height(contentSize.height + "px");
    }

    if(this.adjustWidth) {
      this.$sizer.width(Math.max(containerSize.width, contentSize.width) + "px");
    }

    const sizes = containerSize.width + " " + containerSize.height + " " + bodySize.width + " " + bodySize.height + " " + contentSize.width + " " + contentSize.height + " " + scrollPosition.x + " " + scrollPosition.y;
    const containerScroll = getScrollPosition(this.container);
    if (containerScroll.y > 0 || containerScroll.x > 0) {
      this.container.scrollTo(0, 0);
    }

    if (sizes !== this.lastSizes) {
      this.lastSizes = sizes;

      // $body.css("padding", `0 ${2}rem ${2}rem 0`);

      const verticalVisible = contentSize.height > containerSize.height + 2; // plus two for pixels rounding to integer

      if (verticalVisible) {

        const height = containerSize.height / contentSize.height;
        const top = scrollPosition.y / contentSize.height;

        const topShadowOpacity = this.topShadow ? Math.min(1, Math.max(0, (scrollPosition.y / 100))) : 0;
        const topBoxShadow = `inset 0 0.875rem 1.75rem -0.875rem rgba(0,0,0,${topShadowOpacity * 0.2})`;

        const bottomShadowOpacity = this.bottomShadow ? Math.min(1, Math.max(0, ((contentSize.height - scrollPosition.y - containerSize.height) / 100))) : 0;
        const bottomBoxShadow = `inset 0 -0.875rem 1.75rem -0.875rem rgba(0,0,0,${bottomShadowOpacity * 0.2})`;


        if (!this.lastVerticalVisible) {
          this.$verticalBar
            .addClass("visible")
        }
        this.$verticalHandle
          .css("top", top * 100 + "%")
          .css("height", height * 100 + "%");

        this.$shadow
          .css("box-shadow", topBoxShadow + "," + bottomBoxShadow);

        if(scrollPosition.y > contentSize.height - containerSize.height - containerSize.height * 0.5) {
          this.scrolledAlmostToEndNoAngular.emit();
        }
      } else {
        if (this.lastVerticalVisible) { // do nothing if not needed
          this.$verticalBar
            .removeClass("visible");
          this.$shadow
            .css("box-shadow", "none");
        }
      }

      this.lastVerticalVisible = verticalVisible;

      if(this.horizontal) {

        const horizontalVisible = contentSize.width > containerSize.width + 2; // plus two for pixels rounding to integer


        if (horizontalVisible) {

          const width = containerSize.width / contentSize.width;
          const left = scrollPosition.x / contentSize.width;

          if (!this.lastHorizontalVisible) {
            this.$horizontalBar
              .addClass("visible")
          }
          this.$horizontalHandle
            .css("left", left * 100 + "%")
            .css("width", width * 100 + "%");
        } else {
          if (this.lastHorizontalVisible) {
            this.$horizontalBar.removeClass("visible");
          }
        }


        this.lastHorizontalVisible = horizontalVisible;

      }

      this.scrolledOutsideAngular.emit();

      this.saveScrollPosition();

    }


    if (this.visible && loop) {

      if(this.animationFrameRequest) {
        cancelAnimationFrame(this.animationFrameRequest);
        this.animationFrameRequest = undefined;
      }
      if(this.timeoutRequest) {
        clearTimeout(this.timeoutRequest);
        this.timeoutRequest = undefined;
      }

      if (Date.now() < this.updatesLimitTimestamp) {
        this.animationFrameRequest = myRequestAnimationFrameNoAngular(() => this.updatePositions(true));
      } else {
        this.timeoutRequest = mySetTimeoutNoAngular(() => this.updatePositions(true), 300); // this is required for case when scroll content changes without receiving event
      }
    }
  }

  ngOnDestroy() {
    this.visible = false;
    window.removeEventListener("pointerenter", this.windowPositionListener);
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private onScrollToComponent(element: $$Element) {
    scrollElementToView(this.$body, element, this.scrollToComponentMargin);
  }

  saveScrollPosition() {
    const size = getElementSize(this.body);
    if(size.notZero()) {
      const position = getScrollPosition(this.body);
      ScrollPositionHolder.INSTANCE.rememberPosition(this.scrollStateIdentifier, position.x, position.y);
    }
  }
}

