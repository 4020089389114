<div class="days">
  <div class="durationPickerLabel">{{'common_days' | i18n}}</div>
  <button tabindex="-1" class="durationPickerAction" (click)="addDays(1)"><div class="icon"><i class="mi-navigate-up"></i></div></button>
  <div class="durationPickerValue">{{daysSelected}}</div>
  <button tabindex="-1" class="durationPickerAction" (click)="addDays(-1)"><div class="icon"><i class="mi-navigate-down"></i></div></button>
</div>

<div class="hours hoursInDay{{hoursInDay}}">
  <div class="durationPickerLabel">{{'common_hours' | i18n}}</div>
  <button tabindex="-1" class="entry" *ngFor="let hour of hours" (click)="selectHours(hour)" [ngClass]="{selected: hour === hoursSelected}">{{hour}}</button>
</div>


<div class="minutes" [ngClass]="{allMinutesMode: allMinutesMode}">
  <div class="durationPickerLabel"><button tabindex="-1" (click)="toggleMinutesMode()" [disabled]="allMinutesMode && notDividableByFive(minutesSelected)">{{'common_minutes' | i18n}}</button></div>
  <button tabindex="-1" class="entry" *ngFor="let minute of minutes" (click)="selectMinutes(minute)" [ngClass]="{selected: minute === minutesSelected}">{{minute}}</button>
</div>

<div class="seconds" *ngIf="useSeconds">
  <div class="durationPickerLabel"><button tabindex="-1" (click)="toggleSecondsMode()">{{'common_seconds' | i18n}}<div class="icon"><i class="mi-expand"></i></div></button></div>
  <button tabindex="-1" class="entry" *ngFor="let second of seconds" (click)="selectSeconds(second)" [ngClass]="{selected: second === secondsSelected}">{{second}}</button>
</div>
