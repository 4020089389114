import {
  AggregateId,
  AggregateVersion, AggregateWrapper,
  AnyFlowId,
  EventBus,
  FlowId, mySetTimeoutNoAngular,
  None,
  Option,
  randomString,
  RemoteFlowId,
  RemoteOrganizationIdentifier, required,
  restUrl,
  ScreenInstanceId,
  Some,
  toastr
} from "@utils";
import {
  I18nService,
  NewScreenInstanceEvents,
  ScreenInstancesListUpdate,
  SessionEventBus,
  SessionServiceProvider
} from "../..";
import {Injectable} from "@angular/core";
import {AuthenticatedHttp, ProcessFlowEventInfo, TasksChanged} from "@shared-model";
import {PersonNotification} from "../notifications/notifications.model";
import {ProcessFlowDetails} from "../../../modules/task-form.module/model/ProcessFlow";
import {Subject} from "rxjs";
import {OfflineService} from "../offline/offline.service";
import {debug, global} from "@utils";

type SubscriptionCode = string;
type SubscriptionRequestId = number;

class ListenConfirmed {
    channelId: string = "";
  }
  class ListenResponse {
    error: string|undefined;
    events: ListenResponseData[]|undefined;
    subscriptionsIds: Array<string> = [];
  }
  class ListenResponseData {
    dataType: string = "";
    data: any;
    metadata: any;
  }


  export class ServerEventBus extends EventBus {
    flowEvent(rawEventInfo: ProcessFlowEventInfo) {}
    screenInstanceEvents(eventsNotCopied: NewScreenInstanceEvents) {}
    countUpdate(n: number) {}
    tasksUpdated(tasksChanged: TasksChanged) {}
    // activeFlowsUpdated(flows: Array<ProcessFlowDetails>, organizationId: AggregateId) {}
    // allFlowsUpdated(flows: ProcessFlowDetails[], organizationId: AggregateId) {}
    // flowDetailsUpdated(flow: ProcessFlowDetails, flowId: FlowId) {}
    // instanceInfoUpdated(instanceInfo: ProcessInstanceInfo, instanceId: AggregateId) {}
    myRolesUpdated() {}
    // recentDesignsUpdated(recentDesigns: ProcessInfo[]) {}
    newNotificationsReceived(notifications: Array<PersonNotification>) {}
    screenInstancesChange(instancesListUpdate: ScreenInstancesListUpdate) {}
  }


  export class SubscriptionType {
    constructor(readonly name: string) {}

    static FlowEventInfo = new SubscriptionType("FlowEventInfo");
    static ActiveFlowsCount = new SubscriptionType("ActiveFlowsCount");
    static ActiveFlows = new SubscriptionType("ActiveFlows");
    static AllFlows = new SubscriptionType("AllFlows");
    static FlowDetails = new SubscriptionType("FlowDetails");
    static TasksChange = new SubscriptionType("TasksChange");
    static AvailableTasks = new SubscriptionType("AvailableTasks");
    static AssignedTasks = new SubscriptionType("AssignedTasks");
    static InstanceInfo = new SubscriptionType("InstanceInfo");
    static MyRoles = new SubscriptionType("MyRoles");
    static Notifications = new SubscriptionType("Notifications");
    static ScreenInstance = new SubscriptionType("ScreenInstance");
    static ScreenInstancesChange = new SubscriptionType("ScreenInstancesChange");

  }

  class SubscribePendingRequest {
    constructor(readonly subscriptionRequestId: number,
                readonly onSubscribe: (subscriptionCode: SubscriptionCode) => void,
                readonly onReinitNeeded?: () => void|undefined) {}
  }


  @Injectable({
    providedIn: 'root',
  })
  export class ServerEventsService {
    static uniqueIdentifier = randomString(8);

    private channelId: Option<string> = None();
    private remoteChannels: {[organization: string]: string} = {};

    public serverEventBus = new ServerEventBus();

    private delayedSubscriptions: Array<() => void> = [];

    private remoteOrganizations: Array<RemoteOrganizationIdentifier> = [];

    private sharedWorkerAvailable: boolean = !!(<any>window).SharedWorker;
    private worker?: Worker|SharedWorker = undefined;

    private debugEnabled = true;
    flowDetailsUpdatedSubject: Subject<ProcessFlowDetails> = new Subject<ProcessFlowDetails>();
    private stopListening?: () => void;

    private readonly pingInterval: number = 1000;
    private readonly pongMaxWaitTime = 4000;
    private pingTimeoutId?: number;
    private pongCheckTimeoutId?: number;
    private lastPongTimestamp = 0;
    private pendingSubscribeRequest: Map<SubscriptionRequestId, SubscribePendingRequest> = new Map();
    private reinitRequestForSubscriptionId: Map<SubscriptionCode, () => void> = new Map();

    private subscriptionRequestIdGenerator: SubscriptionRequestId = 1;

    private delayedMessages: Array<Record<string, any>> = [];

    constructor(readonly authenticatedHttp: AuthenticatedHttp,
                readonly i18nService: I18nService,
                readonly sessionServiceProvider: SessionServiceProvider,
                readonly sessionEventBus: SessionEventBus,
                readonly offlineService: OfflineService,
                // readonly organizationSessionInfo: OrganizationSessionInfoClientSide,
                // readonly $window: ng.IWindowService,
                // readonly remoteOrganizationsQueryService: RemoteOrganizationsQueryService,
                // readonly $timeout: ng.ITimeoutService
                ) {

      console.log("ServerEventsService created");

      sessionEventBus.on(sessionEventBus.userLoggedIn, (otherTab: boolean) => {
        console.log("Will init worker because of user log in");
        if(!otherTab) {
          this.postMessageToWorker({messageType: "userLoggedIn"});
        }
        this.initSessionSubscriptions();
      });

      sessionEventBus.on(sessionEventBus.userLoggedOut, (sessionToken: string) => {
        this.unsubscribeAll(sessionToken);
      });


      this.initWorker();
    }

    private initSendPing() {
      debug("Initializing send ping (" + this.pingTimeoutId+")");
      if(this.pingTimeoutId) {
        clearTimeout(this.pingTimeoutId);
      }
      this.scheduleSendPing();
    }

    private scheduleSendPing() {
      this.pingTimeoutId = window.setTimeout(() => {
        this.pingTimeoutId = undefined;
        try {
          this.postMessageToWorker({
            messageType: "ping",
          });
        } catch (e) {
          // ignore, just keep pinging
        }
        this.scheduleSendPing();
      }, this.pingInterval);
    }

    private initCheckPong() {

      debug("Initializing check pong (" + this.pongCheckTimeoutId+")");

      if(this.pongCheckTimeoutId) {
        clearTimeout(this.pongCheckTimeoutId);
      }
      this.lastPongTimestamp = 0;
      this.scheduleCheckPong();
    }


    private scheduleCheckPong() {
      this.pongCheckTimeoutId = window.setTimeout(() => {
        this.pongCheckTimeoutId = undefined;
        if(this.checkPong()) {
          this.scheduleCheckPong();
        }
      }, this.pingInterval);
    }

    private unsubscribeAll(sessionToken: string) {
      this.channelId = None();
      this.remoteChannels = {};
      this.postMessageToWorker({messageType: "userLoggedOut"});
    }

    private initSubscriptions() {

      this.remoteOrganizations.forEach(remoteOrganization => {
        this.initRemote(remoteOrganization);
      });

      this.initSessionSubscriptions()
    }

    private reinitiateSubscriptions() {
      debug("Reinitiating subscriptions ("+this.reinitRequestForSubscriptionId.size+")");
      Array.from(this.reinitRequestForSubscriptionId.values()).forEach(reinit => reinit());
      this.reinitRequestForSubscriptionId.clear();
    }

    private emergencyReload(error: string) {
      console.error("Error while listening to server events, reloading page to re-establish connection" + error);
      mySetTimeoutNoAngular(() => window.location.reload(), 1000);
      throw new Error("Error while listening to server events, reloading page to re-establish connection: " + error);
    }


    private debug(...args: Array<any>): void {
      if(this.debugEnabled) {
        if (args.length === 1) {
          console.log("Page: " + args[0]);
        } else if (args.length === 2) {
          console.log("Page: " + args[0], args[1]);
        } else if (args.length === 3) {
          console.log("Page: " + args[0], args[1], args[2]);
        } else if (args.length === 4) {
          console.log("Page: " + args[0], args[1], args[2], args[3]);
        } else if (args.length === 5) {
          console.log("Page: " + args[0], args[1], args[2], args[3], args[4]);
        } else if (args.length === 6) {
          console.log("Page: " + args[0], args[1], args[2], args[3], args[4], args[5]);
        } else {
          console.log("Page:", args);
        }
      }
    }

    private postMessageToWorker(message: Record<string, any>) {

      if(this.worker) {
        // flag check is
        if (this.sharedWorkerAvailable) {
          (<SharedWorker>this.worker).port.postMessage(message);
        } else if (!this.sharedWorkerAvailable) {
          (<Worker>this.worker).postMessage(message);
        } else {
          throw new Error("No worker");
        }
      } else {
        this.delayedMessages.push(message);
      }
    }

    private listenOnWorkerMessage(onMessage: (message: any) => void) {

      if(this.sharedWorkerAvailable) {

        const listener = (e: { data: { messageType: any; channelId: string; events: Array<any>; error: string; }; }) => {
          onMessage(e.data)
        };

        console.log("Add listener");
        (<SharedWorker>this.worker).port.addEventListener("message", listener);
        this.stopListening = () => {
          console.log("Remove listener");
          this.sessionServiceProvider.getOrganizationSessionInfoIfAvailable(sessionInfo => {
            if(this.worker) {
              this.postMessageToWorker({messageType: "pageClosed", sessionId: sessionInfo.sessionToken});
              (<SharedWorker>this.worker).port.removeEventListener("message", listener);
              (<SharedWorker>this.worker).port.close();
            }
          }, () => {
            if(this.worker) {
              (<SharedWorker>this.worker).port.removeEventListener("message", listener);
              (<SharedWorker>this.worker).port.close();
            }
          });
          if(this.worker) {
            (<SharedWorker>this.worker).port.removeEventListener("message", listener);
            (<SharedWorker>this.worker).port.close();
          }
        };

      } else if(!this.sharedWorkerAvailable) {
        const listener = (event: MessageEvent<{ messageType: any; channelId: string; events: Array<any>; error: string; }>) => {
          onMessage(event.data);
        };
        console.log("Add listener");
        (<Worker>this.worker).onmessage = listener;
        this.stopListening = () => {
          console.log("Remove listener");
          if(this.worker) {
            (<Worker>this.worker).removeEventListener("message", listener);
            (<Worker>this.worker).terminate();
          }
        };

      } else {
        throw new Error("No worker");
      }
    }

    private initWorker() {

      this.channelId = None();

      this.debug("Initiating or connecting to worker");

      if(this.stopListening) {
        this.stopListening();
        this.stopListening = undefined;
      }

      if(this.sharedWorkerAvailable) {
        console.log("Starting shared worker");
        this.worker = new SharedWorker("/serverEventsWorker.min.js?nocache="+encodeURIComponent(global.config.buildTimestamp));
        this.worker.port.start();
      } else {
        console.log("Starting non shared worker");
        this.worker = new Worker("/serverEventsWorker.min.js?nocache="+encodeURIComponent(global.config.buildTimestamp));
      }

      this.delayedMessages.forEach(message => {
        this.postMessageToWorker(message);
      });
      this.delayedMessages = [];


      this.listenOnWorkerMessage((message: { messageType: any; channelId: string; events: Array<any>; error: string; log?: Array<any>, workerId?: number; }) => {
        this.handleWorkerMessage(message);
      })


      this.initSendPing();
      this.initCheckPong();
      this.initSubscriptions();


    }


    private destroyWorker() {
      if(this.worker) {
        if(this.sharedWorkerAvailable) {
          (<SharedWorker>this.worker).port.close();
        } else {
          (<Worker>this.worker).terminate();
        }
        if(this.pingTimeoutId) {
          clearTimeout(this.pingTimeoutId);
        }
        this.lastPongTimestamp = 0;
        this.worker = undefined;
      }
    }


    private initSessionSubscriptions() {

      console.log("Will init worker subscription if session available");

      this.sessionServiceProvider.getOrganizationSessionInfoIfAvailable(organizationSessionInfo => {

        console.log("Session available - initiating wokrer 'listen'");

        this.postMessageToWorker({
          messageType: "listen", "serviceIdentifier": ServerEventsService.uniqueIdentifier, "urlPrefix": restUrl(""),
          "sessionId": organizationSessionInfo.sessionToken
        });

        if (!(<any>window).isUnloading) {
          window.addEventListener("unload", () => {
            this.postMessageToWorker({
              messageType: "pageClosed",
              sessionId: organizationSessionInfo.sessionToken
            });
          });
        }

      }, () => {
        console.log("Session not available (will not initialize worker listen)");
      });
    }



    private handleWorkerMessage(data: { messageType: any; channelId: string; events: Array<any>; error: string; log?: Array<any>, workerId?: number, subscriptionId?: SubscriptionCode, subscriptionRequestId?: SubscriptionRequestId}) {
      switch(data.messageType) {
        case "userSessionAvailable":
          // try to get session info and redirect from login page
          this.sessionServiceProvider.getSessionService(sessionService => {
            if(!sessionService.isLoggedIn()) {
              sessionService.loadSessionInfo(() => {
                this.sessionEventBus.userLoggedIn(true);
              });
            }
          });
          break;
        case "listenResponse":
          this.channelId = Some(data.channelId);
          this.debug("Connected to channel "+data.channelId);
          this.replayDelayedSubscriptions();
          this.reinitiateSubscriptions();
          break;
        case "events":
          this.debug("Got response ", data.messageType, data.events.length);

          // timeout is used to force scope update
          data.events.forEach((event) => {
            this.handleUpdateDataFromServer(event.dataType, event.data, event.metadata);
          });
          break;
        case "ping":
          this.postMessageToWorker({messageType: "pong"});
          break;
        case "pong":
          this.lastPongTimestamp = Date.now();
          break;
        case "emergencyReload":
          this.offlineService.setOffline();
          // this.emergencyReload(data.error);
          break;
        case "unableToConnect":
          this.offlineService.setOffline();
          // toastr.info(this.i18nService.translate("common_reconnecting"));
          // this.emergencyReload(data.error);
          break;
        case "noSessionAvailable":
          this.offlineService.setOffline();
          // this.emergencyReload(data.error);
          break;
        case "subscribed":
          const subscriptionRequest = required(this.pendingSubscribeRequest.get(required(data.subscriptionRequestId, "subscriptionRequestId")), "subscriptionRequest");
          this.pendingSubscribeRequest.delete(required(data.subscriptionRequestId, "subscriptionRequestId"))
          if(subscriptionRequest.onReinitNeeded) {
            this.reinitRequestForSubscriptionId.set(required(data.subscriptionId, "subscriptionId"), required(subscriptionRequest.onReinitNeeded, "reinitRequest"));
          }
          subscriptionRequest.onSubscribe(required(data.subscriptionId, "subscriptionId"));
          break;
        case "subscribeFailed":
          toastr.clearMessagesWithTag("subscription");
          toastr.info("Subscription failed [" + data.error+"]", "subscription");
          this.offlineService.setOffline();
          break;
        case "unsubscribeFailed":
          toastr.clearMessagesWithTag("subscription");
          toastr.info("Unsubscribe failed [" + data.error+"]", "subscription");
          this.offlineService.setOffline();
          break;
        case "channelOpeningFailed":
          toastr.clearMessagesWithTag("subscription");
          toastr.info("Channel opening failed [" + data.error+"]", "subscription");
          this.offlineService.setOffline();
          break;
        case "channelOpeningNotAuthorized": // ignore
          break;
        case "log":
          if(data.log) {
            debug("Worker log ("+data.workerId+")", ...data.log);
          }
          break;
        default:
          this.debug("Got response ", data.messageType);
      }
    }

    private replayDelayedSubscriptions() {
      const delayed = this.delayedSubscriptions;
      this.delayedSubscriptions = [];
      while(delayed.length > 0) {
        const subscription = delayed.shift();
        if(subscription !== undefined) {
          subscription();
        }
      }
    }


    private initRemote(remoteOrganizationIdentifier: RemoteOrganizationIdentifier) {
      // TODO enable remote
      // delete this.remoteChannels[remoteOrganizationIdentifier.id];
      // this.authenticatedHttp.getWithDetailedError("server-events/open-remote-channel/"+remoteOrganizationIdentifier.id+"/"+ServerEventsService.uniqueIdentifier, (data: ListenConfirmed) => {
      //   this.remoteChannels[remoteOrganizationIdentifier.id] = data.channelId;
      //   this.listenAgainForServerEvents(Some(remoteOrganizationIdentifier));
      //   this.replayDelayedSubscriptions();
      // });
    }

    private channelFor(remoteOrganizationIdentifier: Option<RemoteOrganizationIdentifier>): Option<string> {
      if(remoteOrganizationIdentifier.isEmpty()) {
        return this.channelId;
      } else {
        return Option.of(this.remoteChannels[remoteOrganizationIdentifier.get().id]);
      }
    }


    /**
     * @param onReinitNeeded is called when we lost subscription to server and ne need to reinit that, so also we need to load base state again
     */
    subscribe(url: (channelId: string) => string, onSuccess: (subscriptionId: string) => void, onReinitNeeded?: () => void) {
      if(this.channelId.isDefined()) {
        this.debug("Will subscribe by worker " + url(this.channelId.get()));
        this.sessionServiceProvider.getOrganizationSessionInfo(organizationSessionInfo => {

          const subscriptionRequestId = this.subscriptionRequestIdGenerator++;

          this.postMessageToWorker({
            messageType: "subscribe",
            "sessionId": organizationSessionInfo.sessionToken,
            subscriptionUrl: url(this.channelId.get()),
            subscriptionRequestId: subscriptionRequestId
          });

          this.pendingSubscribeRequest.set(subscriptionRequestId, new SubscribePendingRequest(subscriptionRequestId, onSuccess, onReinitNeeded));
        });
      } else {
        this.delayedSubscriptions.push(() => this.subscribe(url, onSuccess, onReinitNeeded));
      }

    }


    subscribeForFlowEventInfo(flowId: AnyFlowId, flowVersion: AggregateVersion, onSuccess: (subscriptionCode: SubscriptionCode) => void, onReinitNeeded: () => void): void {
      let endpoint = "";
      let channelId = "";
      let flow = "";

      if(flowId instanceof FlowId) {
        endpoint = "subscribe-for-flow-events-info/";
        // channelId = this.channelId.get();
        flow = flowId.id;
      } else if(flowId instanceof RemoteFlowId) {
        endpoint = "remote-subscribe-for-flow-events-info/"+flowId.remoteOrganization+"/";
        // channelId = this.channelFor(Some(new RemoteOrganizationIdentifier(flowId.remoteOrganization))).get();
        flow = flowId.id;
      } else {
        throw new Error("Incorrect flow id")
      }

      // TODO fix handling remote
      this.subscribe(channelId => `server-events/${endpoint}${channelId}/${flow}/${flowVersion.asInt}`, subscriptionId => onSuccess(subscriptionId), onReinitNeeded);

    }


    subscribeForScreenInstance(instanceId: ScreenInstanceId, lastVersion: number, onSuccess: (subscriptionCode: SubscriptionCode) => void, onReinitNeeded: () => void): void {
      this.subscribe(channelId => `server-events/subscribe-for-screen-instance/${channelId}/${instanceId.id}/${lastVersion}`, subscriptionId => onSuccess(subscriptionId), onReinitNeeded);
    }

    subscribeForScreenInstancesListChange(onSuccess: (subscriptionCode: SubscriptionCode) => void, onReinitNeeded: () => void): void {
      this.subscribe(channelId => `server-events/subscribe-screen-instances-list-change/${channelId}`, subscriptionId => onSuccess(subscriptionId), onReinitNeeded);
    }


    subscribeForInstanceInfo(instanceId: AggregateId, onSuccess: (subscriptionCode: SubscriptionCode) => void): void {
      this.subscribe(channelId => `server-events/subscribe-for-instance/${channelId}/${instanceId.id}`, subscriptionId => onSuccess(subscriptionId));
    }

    subscribeForTasksChange(remoteOrganization: Option<RemoteOrganizationIdentifier>, onSuccess: (subscriptionCode: SubscriptionCode) => void, onReinitNeeded: () => void): void {

      const channel = this.channelFor(remoteOrganization);

      if (channel.isDefined()) {

        // TODO fix remote
        this.subscribe(channelId =>
          remoteOrganization.isDefined() ?
            `server-events/remote-subscribe-tasks-change/${remoteOrganization.get().id}/${channelId}` :
            `server-events/subscribe-tasks-change/${channelId}`,
            subscriptionId => onSuccess(subscriptionId), onReinitNeeded);
      } else {
        const clone = Option.copy(remoteOrganization).map(RemoteOrganizationIdentifier.copy);
        this.delayedSubscriptions.push(() => this.subscribeForTasksChange(clone, onSuccess, onReinitNeeded));
      }
    }

    subscribeForMyRoleUpdates(onSuccess: (subscriptionCode: SubscriptionCode) => void): void {
      this.subscribe(channelId => `server-events/subscribe-for-my-roles/${channelId}`, subscriptionId => onSuccess(subscriptionId));
    }


    subscribeForFlow(flowId: AnyFlowId, onSuccess: (subscriptionCode: SubscriptionCode) => void): void {
      this.subscribe(channelId => `server-events/subscribe-for-flow-details/${channelId}/${flowId.urlSerialized()}`, subscriptionId => onSuccess(subscriptionId));
    }

    subscribeForNotifications(onSuccess: (subscriptionCode: SubscriptionCode) => void, onReinitNeeded: () => void): void {
      this.subscribe(channelId => `server-events/subscribe-for-notifications/${channelId}`, subscriptionId => onSuccess(subscriptionId), onReinitNeeded);
    }

    unsubscribeRemote(remoteOrganization: RemoteOrganizationIdentifier, subscriptionCode: SubscriptionCode): void {
      if(this.channelId.isDefined()) {
        this.authenticatedHttp.get("server-events/remote-unsubscribe/"+remoteOrganization.id+"/" + this.channelId.get() + "/" + subscriptionCode,
          (data: string) => {
            // do nothing
          });
      }
    }

    unsubscribe(subscriptionCode: SubscriptionCode): void {
      this.reinitRequestForSubscriptionId.delete(subscriptionCode);

      this.sessionServiceProvider.getOrganizationSessionInfoIfAvailable(organizationSessionInfo => {

        this.postMessageToWorker({
          messageType: "unsubscribe",
          sessionId: organizationSessionInfo.sessionToken,
          subscriptionId: subscriptionCode
        });
      });
    }


    private lastUpdates: { [name: string]: any } = {};

    private handleUpdateDataFromServer(dataType: string, data: any, metadata: any): void {

      switch(dataType) {
        case SubscriptionType.FlowEventInfo.name: this.handleEventInfoFromServer(data); break;
        // case SubscriptionType.ActiveFlowsCount.name: this.handleCountUpdateFromServer(<number>data); break;
        // case SubscriptionType.ActiveFlows.name: this.handleActiveFlowsFromServer((<ProcessFlowDetails[]>data).map(pfd => ProcessFlowDetails.copy(pfd)), <AggregateId>metadata); break;
        // case SubscriptionType.AllFlows.name: this.handleAllFlowsFromServer((<ProcessFlowDetails[]>data).map(pfd => ProcessFlowDetails.copy(pfd)), <AggregateId>metadata); break;
        case SubscriptionType.FlowDetails.name: this.flowDetailsUpdatedSubject.next(ProcessFlowDetails.copy(data)); break;
        case SubscriptionType.TasksChange.name:
          // if (!_.isEqual(this.lastUpdates[dataType], data)) {
          //   this.lastUpdates[dataType] = data;
            this.handleTasksChangedFromServer(TasksChanged.copy(data));
          // }
          break;
        // case SubscriptionType.InstanceInfo.name: this.handleInstanceInfoFromServer(ProcessInstanceInfo.copy(data), <AggregateId>metadata); break;
        // case SubscriptionType.MyRoles.name: this.handleRolesChangedFromServer(<boolean>data, <AggregateId>metadata); break;
        case SubscriptionType.Notifications.name: this.handleNotificationsFromServer(<Array<AggregateWrapper<PersonNotification>>>data); break;
        case SubscriptionType.ScreenInstance.name: this.handleScreenInstanceEvents(<NewScreenInstanceEvents>data); break;
        case SubscriptionType.ScreenInstancesChange.name: this.handleScreenInstancesChange(<ScreenInstancesListUpdate>data); break;
        default: throw new Error("Unexpected data type: ["+dataType+"]");
      }
    }

    private handleTasksChangedFromServer(tasksChanged: TasksChanged): void {
      this.serverEventBus.tasksUpdated(tasksChanged);
    }

    private handleScreenInstanceEvents(data: NewScreenInstanceEvents) {
      this.serverEventBus.screenInstanceEvents(data);
    }

    private handleNotificationsFromServer(data: Array<AggregateWrapper<PersonNotification>>): void {
      this.serverEventBus.newNotificationsReceived(data.map(n => PersonNotification.copy(n.id, n.version, n.aggregate)));
    }

    private handleEventInfoFromServer(eventInfo: ProcessFlowEventInfo): void {
      this.serverEventBus.flowEvent(eventInfo);
    }

    private handleScreenInstancesChange(data: ScreenInstancesListUpdate) {
      this.serverEventBus.screenInstancesChange(ScreenInstancesListUpdate.copy(data));
    }

    get flowDetailsUpdated() {
      return this.flowDetailsUpdatedSubject.asObservable();
    }

    // return true if check has to be repeated
    private checkPong(): boolean {
      if(this.lastPongTimestamp > 0 && this.lastPongTimestamp + this.pongMaxWaitTime < Date.now()) {
        console.error("No pong received from worker, reinitiating worker");
        try {
          this.destroyWorker();
          this.initWorker();
        } catch (e) {
          console.error("Error while reinitiating worker", e);
          // ignore just keep checking
        }
        return false;
      } else {
        return true;
      }
    }
  }

