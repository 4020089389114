import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {createArrayFrom0ToNExclusive, Duration, LocalTime} from "@utils";


@Component({
  selector: 'my-duration-picker-board',
  templateUrl: './duration-picker-board.component.html',
  // styleUrls: ['./duration-picker.component.shadow.scss']
})
// Directive annotation is here so compiler would not complain, but it should have no side effects
export class DurationPickerBoardComponent implements OnInit {

  private _duration: Duration | null = null;
  get duration(): Duration|null {return this._duration;}
  @Input({required:true}) set duration(value: Duration|null) {this._duration = value; this.onValueChange();}
  @Output() durationChange = new EventEmitter<Duration|null>();


  @Output() picked = new EventEmitter<Duration>();

  private _hoursInDay: number|undefined;
  get hoursInDay(): number | undefined {return this._hoursInDay;}
  @Input({required:true}) set hoursInDay(value: number | undefined) {this._hoursInDay = value;this.onValueChange();}

  private _useSeconds: boolean|undefined;
  get useSeconds(): boolean | undefined {return this._useSeconds;}
  @Input() set useSeconds(value: boolean | undefined) {this._useSeconds = value;this.onValueChange();}

  hours: Array<string> = [];
  minutes: Array<string> = [];
  seconds: Array<string> = [];

  daysSelected: string = "0";
  hoursSelected: string | undefined;
  minutesSelected: string | undefined;
  secondsSelected: string | undefined;

  allMinutesMode: boolean = false;
  allSecondsMode: boolean = false;


  private hourClicked = false;
  private minuteClicked = false;
  private secondsClicked = false;

  private newInternalValue: Duration|null = null;

  ngOnInit() {
    if(this.hoursInDay === undefined) {
      throw new Error("hoursInDay is not set");
    } else {
      this.hours = createArrayFrom0ToNExclusive(Math.min(24, this.hoursInDay)).map(t => t.toString());
      this.setMinutesMode(false);
      this.setSecondsMode(false);
      this.onValueChange();
    }
  }

  private setMinutesMode(all:boolean){
    if (all) {
      this.minutes = createArrayFrom0ToNExclusive(60).map(t => this.pad(t));
    } else {
      this.minutes = createArrayFrom0ToNExclusive(12).map(t => this.pad(t * 5));
    }
    this.allMinutesMode = all;
  }

  private setSecondsMode(all:boolean){
    if (all) {
      this.seconds = createArrayFrom0ToNExclusive(60).map(t => this.pad(t));
    } else {
      this.seconds = createArrayFrom0ToNExclusive(12).map(t => this.pad(t * 5));
    }
    this.allSecondsMode = all;
  }

  private onValueChange(){
    if(this.hoursInDay !== undefined && this.useSeconds !== null) {
      if (this._duration == undefined) {
        this.daysSelected = "0";
        this.hoursSelected = undefined;
        this.minutesSelected = undefined;
        this.secondsSelected = undefined;
        this.setMinutesMode(false);
        this.setSecondsMode(false);
      } else {

        if (this._duration != this.newInternalValue) {
          const days = this.extractDays(this._duration, this.hoursInDay);
          const hours = this.extractHours(this._duration, this.hoursInDay);
          const minutes = this.extractMinutes(this._duration);
          const seconds = this.extractSeconds(this._duration);

          this.daysSelected = days.toString();
          this.hoursSelected = hours.toString();
          this.minutesSelected = this.pad(minutes);
          this.secondsSelected = this.pad(seconds);

          this.setMinutesMode(minutes % 5 !== 0);
          this.setSecondsMode(seconds % 5 !== 0);
        }
        this.newInternalValue = null;
      }
    }
  }

  addDays(days: number) {
    const currentDays = parseInt(this.daysSelected);
    const newDays = currentDays + days < 0 ? 0 : currentDays + days;
    this.daysSelected = newDays.toString();
    this.updateValue();
  }

  selectSeconds(seconds: string) {
    this.secondsClicked = true;
    this.secondsSelected = seconds;
    this.updateValue();
  }

  selectMinutes(minutes: string) {
    this.minuteClicked = true;
    this.minutesSelected = minutes;
    this.updateValue();
  }

  selectHours(hours: string) {
    this.hourClicked = true;
    this.hoursSelected = hours;
    this.updateValue();
  }

  toggleSecondsMode() {
    this.setSecondsMode(!this.allSecondsMode);
  }

  toggleMinutesMode() {
    this.setMinutesMode(!this.allMinutesMode);
  }

  updateValue(){


    if(this.hoursSelected !== undefined || this.minutesSelected !== undefined || this.secondsSelected !== undefined) {
      const days = this.daysSelected ? parseInt(this.daysSelected) : 0;
      const hours = this.hoursSelected ? parseInt(this.hoursSelected) : 0;
      const minutes = this.minutesSelected ? parseInt(this.minutesSelected) : 0;
      const seconds = this.secondsSelected ? parseInt(this.secondsSelected) : 0;


      if(this.hoursInDay === undefined) {
        throw new Error("hoursInDay is not set");
      } else {
        this._duration = Duration.ofHoursMinutesSeconds(
          days * this.hoursInDay + hours,
          minutes,
          seconds
        );
        this.durationChange.emit(this._duration);
      }


    }


    this.newInternalValue = this._duration;

    if (this.hourClicked && this.minuteClicked && (this.secondsClicked || !this._useSeconds)) {

      this.hourClicked = false;
      this.minuteClicked = false;
      this.secondsClicked = false;

      if(this._duration !== null) {
        this.picked.emit(this._duration);

      } else {
        throw new Error("duration is null");
      }
    }
  }

  private extractSeconds(duration: Duration) {
    return Math.floor(duration.seconds % 60);
  }

  private extractMinutes(duration: Duration) {
    return Math.floor(duration.seconds % 3600 / 60);
  }

  private extractHours(duration: Duration, hoursInDay:number) {
    return Math.floor(duration.seconds % (3600 * hoursInDay) / 3600);
  }

  private extractDays(duration: Duration, hoursInDay:number) {
    return Math.floor(duration.seconds / (3600 * hoursInDay));
  }

  private pad(val: number): string {
    return val < 10 ? '0' + val : val.toString();
  }

  notDividableByFive(minutesSelected: string|undefined) {
    return minutesSelected !== undefined && parseInt(minutesSelected) % 5 !== 0;
  }
}
