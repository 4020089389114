import {
  ArrayVariableType,
  BooleanVariableType,
  BusinessVariableType,
  DateTimeVariableType,
  DateVariableType, EdgeType,
  ExpressionWithAst, GridProcessModelInterface,
  NodeType,
  NumberVariableType,
  ObjectVariableType, ProcessEdgeId, ProcessNodeId, ProcessPhaseId, ProcessRoleId,
  RootVariableType, ScreenRef,
  StringVariableType,
  TimeVariableType,
  VariableTypePath
} from "@shared-model";
import {
  FormElement,
  FormSection, FormSectionInfo,
  FormSectionRef,
  InputElement,
  InputElementRef,
  InputElementWithRef,
  StaticElement,
  StaticElementRef
} from "./FormModel";
import {
  __,
  ___,
  AggregateId, AutomaticActionId, Duration, Either,
  FormElementId,
  FormElementRefId,
  GridSize,
  GridXY,
  GridXYPathEndings,
  i18n,
  I18nText,
  LocalDateTime,
  None,
  Option,
  PersonId,
  range, required, ScreenReleaseId,
  Some,
  toastr,
  Typed
} from "@utils";
import {ProcessResource} from "./NodeProperties";
import {TaskDistributionMethod} from "./TaskDistribution";
import {ElementsFactory} from "./ElementsFactory";
import {GridProcessActor} from "./GridProcessActor";
import {GridProcessAnnotation, GridProcessNode, OutputMappingV1, ProcessValueType} from "./GridProcessNode";
import {GridProcessEdge} from "./GridProcessEdge";
import {CustomProcessInfo} from "./CustomProcessInfoModel";
import {ExpressionParser, FormSectionId, ScreenComponentRefIdentifier} from "@shared";
import {
  ActorVariableUsage,
  AfterActionVariableUsage,
  BeforeActionVariableUsage,
  ConditionNodeVariableUsage,
  ExternalProcessOutputMappingVariableUsage,
  ExternalProcessResultMappingVariableUsage,
  InputFieldRefVariableUsage,
  RepeatableSectionVariableUsage,
  VariableUsage,
  VariableUsageType
} from "./VariableUsage";
import {EstreeVariableUsageAnalyzer} from "@utils";
import {AutomaticAction, AutomaticActionFactory, AutomaticActionRef} from "@screen-common";

export class ProcessReleaseProperties {

  constructor(readonly resources: Array<ProcessResource>) {
  }

  static copy(other: ProcessReleaseProperties) {
    return new ProcessReleaseProperties(other.resources.map(r => ProcessResource.copy(r)));
  }

  static empty() {
    return new ProcessReleaseProperties([]);
  }

}

export class PersonalDataVariable {
  constructor(readonly path: VariableTypePath,
              readonly ipPath: VariableTypePath,
              readonly sensitiveData: boolean) {}

  static copy(other: PersonalDataVariable) {
    return new PersonalDataVariable(
      VariableTypePath.copy(other.path),
      VariableTypePath.copy(other.ipPath),
      other.sensitiveData);
  }
}


export class VariableExpression {

  constructor(readonly path: VariableTypePath,
              readonly expressionWithAst: ExpressionWithAst,
              readonly enabled: boolean) {}

  static copy(other: VariableExpression) {
    return new VariableExpression(VariableTypePath.copy(other.path), ExpressionWithAst.copy(other.expressionWithAst), other.enabled);
  }

  nonEmpty() {
    return this.expressionWithAst.expression.trim().length > 0 || this.enabled;
  }
}

export class GridProcessModelFlowType {
  constructor(readonly name: string) {}

  static persistentFlow = new GridProcessModelFlowType("persistentFlow");
  static volatileFlow = new GridProcessModelFlowType("volatileFlow");
  static transientFlow = new GridProcessModelFlowType("transientFlow");

  isVolatileFlow(): boolean {
    return this.name === GridProcessModelFlowType.volatileFlow.name;
  }

  isAutomaticRemovedFlow(): boolean {
    return this.name === GridProcessModelFlowType.transientFlow.name;
  }

  static copy(other: GridProcessModelFlowType) {
    switch (other.name) {
      case GridProcessModelFlowType.persistentFlow.name : return GridProcessModelFlowType.persistentFlow;
      case GridProcessModelFlowType.volatileFlow.name : return GridProcessModelFlowType.volatileFlow;
      case GridProcessModelFlowType.transientFlow.name : return GridProcessModelFlowType.transientFlow;
      default: throw new Error("Invalid flow type: " + other.name);
    }
  }
}

export class GridProcessModelConfig {

  constructor(readonly flowType: GridProcessModelFlowType) {}

  static copy(other: GridProcessModelConfig) {
    return new GridProcessModelConfig(GridProcessModelFlowType.copy(other.flowType));
  }
}

export class CasePreviewScreenComponent {
  constructor(readonly id: number,
              readonly screenComponentRef: ScreenComponentRefIdentifier,
              readonly name: I18nText,
              readonly customAccess: boolean,
              readonly rolesAllowed: Array<number>) {}

  static copy(other: CasePreviewScreenComponent) {
    return new CasePreviewScreenComponent(
      other.id,
      other.screenComponentRef,
      I18nText.copy(other.name),
      other.customAccess,
      other.rolesAllowed.slice());
  }
}

export class GridProcessPhase {
  constructor(readonly id: ProcessPhaseId,
              readonly gridX: number,
              readonly columns: number,
              readonly name: I18nText,
              readonly identifier: string) {}

  static copy(other: GridProcessPhase) {
    return new GridProcessPhase(
      other.id,
      other.gridX,
      other.columns,
      I18nText.copy(other.name),
      other.identifier);
  }
}

export class GridProcessPhasesDefinition {
  constructor(readonly roleId: Option<ProcessRoleId>,
              readonly phases: Array<GridProcessPhase>) {}

  static copy(other: GridProcessPhasesDefinition) {
    return new GridProcessPhasesDefinition(
      Option.copy(other.roleId),
      other.phases.map(GridProcessPhase.copy));
  }
}

export class ProcessBooleanValue {
  constructor(readonly valueType: ProcessValueType,
              readonly fixedValue: boolean,
              readonly variableName: string,
              readonly expression: string) {}

  static copy(other: ProcessBooleanValue) {
    return new ProcessBooleanValue(ProcessValueType.copy(other.valueType), other.fixedValue, other.variableName, other.expression);
  }

  isFixedTrue() {
    return this.valueType.name === ProcessValueType.fixed.name && this.fixedValue;
  }

  isFixedFalse() {
    return this.valueType.name === ProcessValueType.fixed.name && !this.fixedValue;
  }

  isDynamic() {
    return this.valueType.name === ProcessValueType.expression.name || this.valueType.name === ProcessValueType.variableValue.name;
  }
}

export class ProcessDurationValue {
  constructor(readonly valueType: ProcessValueType,
              readonly fixedValue: Duration,
              readonly organizationTime: boolean,
              readonly variableName: string,
              readonly expression: string) {}

  static copy(other: ProcessDurationValue) {
    return new ProcessDurationValue(ProcessValueType.copy(other.valueType), Duration.copy(other.fixedValue), other.organizationTime, other.variableName, other.expression);
  }
}

// Reflects server side
export class GridProcessModel implements GridProcessModelInterface {

  private static emptyHeaderColumns = 10;
  private static minimumHeaderColumns = 8;
  private static minimumActorRows = 5;
  private static emptyActorRow = 10;

  constructor(readonly releaseCode: string,
              readonly releaseNumber: number,
              readonly releaseComment: string,
              readonly description: I18nText,
              readonly closed: boolean,
              readonly published: boolean,
              readonly processId: AggregateId,
              readonly screenReleaseId: Option<ScreenReleaseId>,
              readonly casePreview: Array<CasePreviewScreenComponent>,
              readonly nodeIdGenerator: number,
              readonly roleIdGenerator: number,
              readonly edgeIdGenerator: number,
              readonly annotationIdGenerator: number,
              readonly actionIdGenerator: number,
              readonly actionRefIdGenerator: number,
              readonly previewFormIdGenerator: number,
              readonly actors: Array<GridProcessActor>,
              readonly nodes: Array<GridProcessNode>,
              readonly edges: Array<GridProcessEdge>,
              readonly annotations: Array<GridProcessAnnotation>,
              readonly variablesTypes: Array<RootVariableType<BusinessVariableType>>,
              readonly actions: Array<[number, Typed<AutomaticAction>]>,
              readonly formSections: Array<FormSection>,
              readonly flowDescriptionFormula: ExpressionWithAst,
              readonly importanceFormula: ExpressionWithAst,
              readonly colorFormula: ExpressionWithAst,
              readonly formElements: Array<Typed<FormElement>>,
              readonly variableExpressions: Array<VariableExpression>,
              readonly personalDataVariables: Array<PersonalDataVariable>,
              readonly properties: ProcessReleaseProperties,
              readonly lastUpdated: LocalDateTime,
              readonly lastUpdatedBy: PersonId,
              readonly config: GridProcessModelConfig,
              readonly customProcessInfo: Option<CustomProcessInfo>,
              readonly phasesDefinitions: Array<GridProcessPhasesDefinition>) {}


  //To ensure that objects methods are present
  static copy(other: GridProcessModel) {
    return new GridProcessModel(
      other.releaseCode,
      other.releaseNumber,
      other.releaseComment,
      I18nText.copy(other.description),
      other.closed,
      other.published,
      other.processId,
      Option.copy(other.screenReleaseId, ScreenReleaseId.copy),
      other.casePreview.map(CasePreviewScreenComponent.copy),
      other.nodeIdGenerator,
      other.roleIdGenerator,
      other.edgeIdGenerator,
      other.annotationIdGenerator,
      other.actionIdGenerator,
      other.actionRefIdGenerator,
      other.previewFormIdGenerator,
      other.actors.map(GridProcessActor.copy),
      other.nodes.map(GridProcessNode.copy),
      other.edges.map(GridProcessEdge.copy),
      other.annotations.map(GridProcessAnnotation.copy),
      other.variablesTypes.map(RootVariableType.copy),
      other.actions.map(a => [a[0], AutomaticActionFactory.copyTyped(a[1])]),
      other.formSections.map( FormSection.copy),
      ExpressionWithAst.copy(other.flowDescriptionFormula),
      ExpressionWithAst.copy(other.importanceFormula),
      ExpressionWithAst.copy(other.colorFormula),
      other.formElements.map(ElementsFactory.copyTyped),
      other.variableExpressions.map(VariableExpression.copy),
      other.personalDataVariables.map(PersonalDataVariable.copy),
      ProcessReleaseProperties.copy(other.properties),
      LocalDateTime.copy(other.lastUpdated),
      other.lastUpdatedBy,
      GridProcessModelConfig.copy(other.config),
      Option.copy(other.customProcessInfo, CustomProcessInfo.copy),
      other.phasesDefinitions.map(GridProcessPhasesDefinition.copy)
    );
  }

  // static empty(): GridProcessModel {
  //   return new GridProcessModel("", 0, "", null, I18nText.empty(), null, false, false, null, [],
  //     [GridProcessNode.emptyStart(new FormSectionId(1))], [], [],
  //     [FormSection.empty(new FormSectionId(1), "Section 1")], ExpressionWithAst.empty(), ExpressionWithAst.empty(), ExpressionWithAst.empty(),
  //     [], [],
  //      ProcessReleaseProperties.empty(), LocalDateTime.now(), null, new GridProcessModelConfig(GridProcessModelFlowType.persistentFlow), None());
  // }


  static sortByXY(nodeA: GridProcessNode,nodeB: GridProcessNode) {
    if(nodeA.gridXY.gridX > nodeB.gridXY.gridX || nodeA.gridXY.gridX === nodeB.gridXY.gridX && nodeA.gridXY.gridY > nodeB.gridXY.gridY) {
      return 1;
    }
    if(nodeB.gridXY.gridX > nodeA.gridXY.gridX || nodeB.gridXY.gridX === nodeA.gridXY.gridX && nodeB.gridXY.gridY > nodeA.gridXY.gridY) {
      return -1;
    }
    return 0;
  }

  findActorByGridY(gridY:number): Option<GridProcessActor> {
    return __(this.actors)
      .find((actor:GridProcessActor) =>
        __(range(actor.rowsCount)).find((row: number) => actor.gridY + row === gridY).isDefined());
  }

  findActorForNode(node: GridProcessNode): Option<GridProcessActor> {
    return this.findActorByGridY(node.gridXY.gridY);
  }

  findNodeById(nodeId: ProcessNodeId): GridProcessNode {
    const optionalNode = this.findOptionalNodeById(nodeId);
    if (optionalNode.isDefined()) {
      return optionalNode.get();
    } else {
      throw new ReferenceError("Node not found for id " + nodeId);
    }
  }

  findNodesByActor(actorId: number): Array<GridProcessNode> {
    const actorFound = this.findActorById(actorId);
    if(actorFound.isDefined()) {
      const actor = actorFound.get();
      return this.nodes.filter(node => node.gridXY.gridY >= actor.gridY && node.gridXY.gridY <= actor.gridY + actor.rowsCount - 1);
    } else {
      return [];
    }
  }

  findStartNodes(): Array<GridProcessNode> {
    return this.nodes.filter(node => node.nodeType.isAnyStart());
  }

  findStartNodesTriggeredByCasesScheduler(): Array<GridProcessNode> {
    return this.findStartNodes().filter(node => node.startTriggers.casesSchedulerTrigger.enabled)
  }

  findOptionalNodeById(nodeId: ProcessNodeId): Option<GridProcessNode> {
    return __(this.nodes).find((node:GridProcessNode) => node.id === nodeId);
  }

  isItPossibleToAddNewNode(gridXY:GridXY):boolean {
    return this.nodes.every((node:GridProcessNode) => node.gridXY.nonEqual(gridXY));
  }

  isItPossibleToAddNewActor(gridY:number, name: string):boolean {
    if (__(this.actors).exists((actor: GridProcessActor) => actor.name.getCurrentWithFallback() == name)) {
      toastr.warning(i18n("designMap_Map_Warning_RoleActorNameExists"));
      return false;
    } else {
      return this.actors.every((actor: GridProcessActor) => actor.gridY !== gridY);
    }
  }

  getActorsCount() {
    return this.actors.length;
  }

  findActorById(id:number) {
    return __(this.actors).find(actor => actor.id === id);
  }

  findActorByName(name: string) {
    return __(this.actors).find(actor => actor.name.getCurrentWithFallback() === name);
  }

  edgeByIdExists(id:number): boolean {
    return __(this.edges).find(edge => edge.id === id).isDefined();
  }

  findEdgeById(id:number): GridProcessEdge {
    const foundEdge = __(this.edges).find(edge => edge.id === id);
    if (foundEdge.isEmpty()) {
      throw new ReferenceError("Edge not found for id " + id);
    } else {
      return foundEdge.get();
    }
  }

  findOptionalEdgeById(id:number): Option<GridProcessEdge> {
    return __(this.edges).find(edge => edge.id === id);
  }

  findInEdgesForNode(nodeId: ProcessNodeId):Array<GridProcessEdge> {
    return __(this.edges).filter((edge:GridProcessEdge) => {
      return edge.toNodeId === nodeId;
    });
  }

  findOutEdgesForNode(nodeId: ProcessNodeId):Array<GridProcessEdge> {
    return __(this.edges).filter((edge:GridProcessEdge) => {
      return edge.fromNodeId === nodeId ;
    });
  }

  findOutAlternativeEdgesForNode(nodeId: ProcessNodeId):Array<GridProcessEdge> {
    return __(this.edges).filter((edge:GridProcessEdge) => {
      return edge.fromNodeId === nodeId && edge.edgeType.isAnyAlternative();
    });
  }

  findEdgesWithNode(nodeId: ProcessNodeId):Array<GridProcessEdge> {
    return __(this.edges).filter((edge:GridProcessEdge) => {
      return edge.fromNodeId === nodeId || edge.toNodeId === nodeId;
    });
  }

  countNodesByType(nodeType: NodeType): number {
    return this.nodes.filter((node: GridProcessNode) => {return node.nodeType === nodeType}).length
  }

  maxFormElementId(): FormElementId {
    return new FormElementId(Math.max(0, __(this.formElements.map(fe => Typed.value(fe).id.id)).maxOrZero()));
  }

  maxFormElementRefId(): FormElementRefId {
    return new FormElementRefId(Math.max(0, __(this.formSections.map(fs => fs.maxElementId().id)).maxOrZero()));
  }

  maxNodeGridX() {
    return Math.max(0, __(this.nodes.map(n => n.gridXY.gridX)).maxOrZero());
  }

  maxNodeGridY() {
    return Math.max(0, __(this.nodes.map(n => n.gridXY.gridY)).maxOrZero());
  }

  maxActorGridY() {
    return Math.max(0, __(this.actors.map(a => a.gridY + a.rowsCount - 1)).maxOrZero());
  }

  maxEdgeGridXY() {
    const edgeGridDensity = 6;
    const maxEdgeXPosition = __(this.edges.map(e => __(e.edgePath.points.map(p => p.gridX)).maxOrZero())).maxOrZero();
    const maxEdgeYPosition = __(this.edges.map(e => __(e.edgePath.points.map(p => p.gridY)).maxOrZero())).maxOrZero();
    return new GridXY(Math.floor(maxEdgeXPosition / edgeGridDensity) , Math.floor(maxEdgeYPosition / edgeGridDensity))
  }

  maxSectionId(): FormSectionId {
    return new FormSectionId(Math.max(0, __(this.formSections.map(s => s.id.id)).maxOrZero()));
  }

  findNodeByGridXY(gridXY:GridXY):Option<GridProcessNode> {
    const found: Array<GridProcessNode> = this.nodes.filter((node:GridProcessNode) => node.gridXY.isEqual(gridXY));
    if (found.length === 1) {
      return Some(found[0]);
    } else if (found.length === 0) {
      return None();
    } else {
      throw new Error("Found more than one node with the same coordinates " + gridXY.gridX + " " + gridXY.gridY);
    }
  }

  isItPossibleToAddNewEdgeToEmptyCell(newEdge:GridXYPathEndings, edgeType: EdgeType):boolean {
    if(newEdge.from.nonEqual(newEdge.to)) {
      const notExistAlready = this.edges.every((edge:GridProcessEdge) => {
        return this.findNodeById(edge.fromNodeId).gridXY.nonEqual(newEdge.from) || this.findNodeById(edge.toNodeId).gridXY.nonEqual(newEdge.to)
      });
      const fromNodeOption = this.findNodeByGridXY(newEdge.from);
      if(notExistAlready && fromNodeOption.isDefined()) {
        const fromNode = fromNodeOption.get();
        return this.canAddOutEdgeToNode(fromNode, edgeType);
      }
    }
    return false;
  }

  isItPossibleToAddNewEdge(newEdge:GridXYPathEndings, newEdgeType: EdgeType):boolean {
    if(newEdge.from.nonEqual(newEdge.to)) {
      const notYetExist = this.findEdgesByEndings(newEdge.from, newEdge.to).filter(e => e.edgeType.name === newEdgeType.name).length === 0;

      const fromNodeOption = this.findNodeByGridXY(newEdge.from);
      const toNodeOption = this.findNodeByGridXY(newEdge.to);

      if(notYetExist && fromNodeOption.isDefined() && toNodeOption.isDefined()) {
        const toNode = toNodeOption.get();
        const fromNode = fromNodeOption.get();

        return this.canAddOutEdgeToNode(fromNode, newEdgeType) &&
               this.canAddInEdgeToNode(toNode) &&
               !this.edgeAlreadyExists(fromNode.id, toNode.id);
      }
    }
    return false;
  }

  edgeAlreadyExists(fromNodeId: ProcessNodeId, toNodeId: ProcessNodeId): boolean {
    return __(this.edges).exists(edge => edge.fromNodeId === fromNodeId && edge.toNodeId === toNodeId)
  }

  isItPossibleToMoveEdge(edgeId: ProcessEdgeId, newStart: Option<GridXY>, newFinish: Option<GridXY>): boolean {
    const edge = this.findEdgeById(edgeId);
    const newNodeStart = newStart.flatMap(xy => this.findNodeByGridXY(xy));
    const newNodeEnd = newFinish.flatMap(xy => this.findNodeByGridXY(xy));


    let ok = edge.edgeType.isNormal();

    if(newNodeStart.isDefined()) {
      if(newNodeStart.get().id === edge.toNodeId ||
        !this.canAddOutEdgeToNode(newNodeStart.get(), edge.edgeType) ||
        this.findEdgeByNodes(newNodeStart.get().id, edge.toNodeId).isDefined() ||
        this.edgeAlreadyExists(newNodeStart.get().id,  edge.toNodeId)) {
        ok = false;
      }
    }

    if(newNodeEnd.isDefined()) {
      if(newNodeEnd.get().id === edge.fromNodeId ||
        !this.canAddInEdgeToNode(newNodeEnd.get()) ||
        this.findEdgeByNodes(edge.fromNodeId, newNodeEnd.get().id).isDefined() ||
        this.edgeAlreadyExists(edge.fromNodeId, newNodeEnd.get().id))
      ok = false;
    }

    return (newNodeStart.isDefined() || newNodeEnd.isDefined()) && ok;
  }

  private canAddInEdgeToNode(toNode: GridProcessNode) {
    // we are adding in edge to to node
    let canAddToToNode = true;
    if (toNode.nodeType.isCondition() && this.findOutEdgesForNode(toNode.id).length > 1 && this.findInEdgesForNode(toNode.id).length == 1) {
      canAddToToNode = false;
    }
    return canAddToToNode;
  }

  private canAddOutEdgeToNode(fromNode: GridProcessNode, edgeType: EdgeType) {
    // we are adding out edge to from node
    let canAddToFromNode = true;

    if (fromNode.nodeType.isFinish()) {
      canAddToFromNode = false;
    } else if (fromNode.nodeType.isAnyStart()) {
      if (!edgeType.isNormal() || this.findOutEdgesForNode(fromNode.id).length > 0) {
        canAddToFromNode = false;
      }
    } else if(fromNode.nodeType.isAnyAction()) {
      if(!edgeType.isAnyAlternative() && this.findOutEdgesForNode(fromNode.id).filter(e => e.edgeType.name == edgeType.name).length > 0) {
        canAddToFromNode = false;
      }
    } else if(fromNode.nodeType.isExternal() || fromNode.nodeType.isExternalFinish()) {
      if (!edgeType.isNormal() || this.findOutEdgesForNode(fromNode.id).length > 0) {
        canAddToFromNode = false;
      }
    } else if (fromNode.nodeType.isCondition()) {
      if (!edgeType.isNormal() || this.findInEdgesForNode(fromNode.id).length > 1 && this.findOutEdgesForNode(fromNode.id).length == 1) {
        canAddToFromNode = false;
      }
    }
    return canAddToFromNode;
  }


  isItPossibletoMoveNode(id:number, gridXY:GridXY):boolean {
    return this.nodes.every((node:GridProcessNode) =>node.gridXY.nonEqual(gridXY));
  }


  edgeInEdgesArray(edge:GridProcessEdge, edgesArray:Array<GridProcessEdge>) {
    return edgesArray.filter((edgeToDelete) => edge.id === edgeToDelete.id).length === 1;
  }

  gridSize(fakeNodeGridXY:GridXY = new GridXY(0, 0)):GridSize {
    return new GridSize(
      Math.max(fakeNodeGridXY.gridX + 2, this.maxNodeGridX() + 2, GridProcessModel.minimumHeaderColumns) + GridProcessModel.emptyHeaderColumns - 1,
      Math.max(fakeNodeGridXY.gridY + 3, this.maxActorGridY() + 3, this.maxNodeGridY() + 3, GridProcessModel.minimumActorRows + GridProcessModel.emptyActorRow) - 1)
  }

  realGridSize(): GridSize {
    const minGridSizeXY = new GridXY(1,1);
    const maxGridX = Math.max(minGridSizeXY.gridX, this.maxNodeGridX() + 1, this.maxEdgeGridXY().gridX + 1);
    const maxGridY = Math.max(minGridSizeXY.gridY, this.maxActorGridY() + 1, this.maxEdgeGridXY().gridY + 1, this.maxNodeGridY() + 1);
    return new GridSize(maxGridX, maxGridY);
  }

  private findEdgesByEndings(from:GridXY, to:GridXY): Array<GridProcessEdge> {
    const fromNode = this.findNodeByGridXY(from);
    const toNode = this.findNodeByGridXY(to);
    if(fromNode.isDefined() && toNode.isDefined()) {
      return this.edges.filter(edge => edge.fromNodeId === fromNode.get().id && edge.toNodeId === toNode.get().id);
    } else {
      return [];
    }
  }

  findEdgeByNodes(from:number, to: number):Option<GridProcessEdge> {
    const fromNode = this.findNodeById(from);
    const toNode = this.findNodeById(to);
    return __(this.edges).find((edge: GridProcessEdge) => edge.fromNodeId === fromNode.id && edge.toNodeId === toNode.id);
  }

  findEdgeByName(name: string):Option<GridProcessEdge> {
    return __(this.edges).find((edge: GridProcessEdge) => edge.name.getCurrentWithFallback() === name);
  }


  findEdgesForNodesPath(nodesIds: Array<number>): Array<number> {

    if(nodesIds.length < 2) {
      return [];
    } else {
      let node = nodesIds[0];
      const edgesIds: Array<number> = [];

      for(let i=1; i< nodesIds.length; i++) {
        const edge = this.findEdgeByNodes(node, nodesIds[i]);
        edgesIds.push(edge.get().id);
        node = nodesIds[i];
      }
      return edgesIds;
    }
  }

  findEdgesForNodesCycle(nodes: Array<number>): Array<number> {
    if(nodes.length < 2) {
      return [];
    } else {
      let edgesIds = this.findEdgesForNodesPath(nodes);

      const edge = this.findEdgeByNodes(__(nodes).last(), __(nodes).first());
      edgesIds.push(edge.get().id);

      return edgesIds;
    }
  }


  countSectionsRefForSection(sectionId: FormSectionId): number {
    return __(this.nodes).flatMap(n => n.form.sectionsRefs).filter((sr: FormSectionRef) => sr.sectionId.id === sectionId.id).length;
  }

  findSectionById(sectionId: FormSectionId): Option<FormSection> {
    return __(this.formSections).find(s => s.id.id === sectionId.id);
  }

  getSectionById(sectionId: FormSectionId): FormSection {
    return __(this.formSections).find(s => s.id.id === sectionId.id).get();
  }

  findRootVariableByName(variableName: string): Option<RootVariableType<BusinessVariableType>> {
    const found =  this.variablesTypes.filter(v => v.name === variableName);

    if(found.length === 1) {
      return Some(found[0]);
    } else if (found.length > 1) {
      throw new Error("Found multiple variables with name " + variableName);
    } else {
      return None();
    }
  }

  findContextByPath(path: VariableTypePath): Option<BusinessVariableType> {
    const objectName = path.head();
    const obj = this.findRootVariableByName(objectName).map(v => v.unwrappedVariableType());
    if(obj.get().className() === ArrayVariableType.className) {
      return Some(obj.get());
    } else if(obj.get().className() === ObjectVariableType.className) {
      return Some(obj.get());
    } else {
      throw new Error("Only Array[Object] or Object supported, but was " + obj.get().typeName());
    }
  }

  //TODO make it recursive
  findVariableByPath(path: VariableTypePath): Option<BusinessVariableType> {
    if(path.isRoot()) {
      return this.findRootVariableByName(path.last()).map(v => v.unwrappedVariableType());
    } else {
      const objectName = path.head();
      const obj = this.findRootVariableByName(objectName).map(v => v.unwrappedVariableType());

      if(obj.isEmpty()) {
        throw new Error("Object "+objectName+" does not exist");
      } else {
        if(obj.get().className() === ArrayVariableType.className) {
          const subtype = (<ArrayVariableType<BusinessVariableType>>obj.get()).subtypeUnwrapped();
          if(subtype.className() === ObjectVariableType.className) {
            return (<ObjectVariableType>subtype).fieldTypeByName(path.last());
          } else {
            throw new Error("Only Array[Object] or Object supported, but was " + obj.get().typeName());
          }
        } else if(obj.get().className() === ObjectVariableType.className) {
          return (<ObjectVariableType>obj.get()).fieldTypeByName(path.last());
        } else {
          throw new Error("Only Array[Object] or Object supported, but was " + obj.get().typeName());
        }
      }
    }
  }

  countVariableBindings(variableTypePath: VariableTypePath): number {
    let usages: Array<VariableUsage> = [];

    usages = usages.concat(this.findActionsVariableWrite(variableTypePath.last()));
    usages = usages.concat(this.findOutputMappingsVariableWrite(variableTypePath.last()));
    usages = usages.concat(this.findRepeatableSectionVariableBindings(variableTypePath.last()));
    return usages.length + this.countInputFieldsVariableBinding(variableTypePath);
  }

  countVariableBindingsWithoutNode(variableTypePath: VariableTypePath, excludedNodeId: ProcessNodeId): number {
    return this.findInputFieldsRefsVariableBinding(variableTypePath).filter(u => this.countReferencesToSectionWithoutNode(u.sectionId, excludedNodeId) > 0).length +
      this.findActionsVariableWrite(variableTypePath.last()).filter(u => u.nodeId !== excludedNodeId).length +
      this.findOutputMappingsVariableWrite(variableTypePath.last()).filter(u => u.nodeId !== excludedNodeId).length +
      this.findRepeatableSectionVariableBindings(variableTypePath.last()).filter(u => this.countReferencesToSectionWithoutNode(u.sectionId, excludedNodeId) > 0).length;
  }


  countReferencesToSectionWithoutNode(sectionId: FormSectionId, excludedNodeId: ProcessNodeId) {
    return ___(this.nodes).filter(n => n.id !== excludedNodeId).map(n => n.form.sectionsRefs.filter(ref => ref.sectionId.id === sectionId.id).length)
      .reduce(0, (sum: number, e: number) => sum + e);
  }

  findVariableUsages(expressionParser: ExpressionParser, variableTypePath: VariableTypePath): Array<VariableUsage> {
    const usages: Array<VariableUsage> = [];
    return usages
      .concat(this.findInputFieldsRefsVariableBinding(variableTypePath))
      .concat(this.findConditionNodeVariableRead(expressionParser, variableTypePath))
      .concat(this.findActionsVariableWrite(variableTypePath.last()))
      .concat(this.findActionsVariableRead(expressionParser, variableTypePath.last()))
      .concat(this.findOutputMappingsVariableWrite(variableTypePath.last()))
      .concat(this.findRepeatableSectionVariableBindings(variableTypePath.last()))
      .concat(this.findActorsVariableRead(expressionParser, variableTypePath.last()));
  }

  findInputFieldsRefsVariableBinding(variableTypePath: VariableTypePath): Array<InputFieldRefVariableUsage> {
    return __(this.formSections).flatMap(section => {
      return ___(section.inputElementsRefsUnwrapped())
        .map(elementRef => new InputElementWithRef(this.inputElementById(elementRef.elementId), elementRef))
        .filter(elementWithRef => elementWithRef.element.variableTypePath.isEqual(variableTypePath))
        .flatMap(elementRef => {
        return this.nodes.flatMap(node => node.form.sectionsRefs.filter(sectionRef => sectionRef.sectionId.id === section.id.id).map(sr => {
          const usageType = (elementRef.elementRef.readOnly.isTrue() || sr.readOnly) ? VariableUsageType.read : VariableUsageType.write;
          return new InputFieldRefVariableUsage(usageType, section.id, node.id);
        }))
      }
    ).value()})
  }

  countInputFieldsVariableBinding(variableTypePath: VariableTypePath): number {
    return this.inputElementsUnwrapped().filter(element => element.variableTypePath.isEqual(variableTypePath)).length;
  }

  countElementReferences(elementId: FormElementId) {
    const usagesPerSection = this.formSections.map(section => {
      const inputReferences = section.inputElementsRefsUnwrapped().filter(ref => ref.elementId.id == elementId.id).length;
      const staticReferences = section.staticElementsRefsUnwrapped().filter(ref => ref.elementId.id == elementId.id).length;
      return inputReferences + staticReferences;
    });
    return __(usagesPerSection).sum();
  }

  inputElementRefsForElement(element: InputElement): Array<InputElementRef> {
    return __(this.formSections).flatMap(section => {
      return section.inputElementsRefsUnwrapped().filter(ref => ref.elementId.id === element.id.id);
    });
  }

  staticElementRefsForElement(element: StaticElement): Array<StaticElementRef> {
    return __(this.formSections).flatMap(section => {
      return section.staticElementsRefsUnwrapped().filter(ref => ref.elementId.id === element.id.id);
    });
  }

  findConditionNodeVariableRead(expressionParser: ExpressionParser, variableTypePath: VariableTypePath): Array<ConditionNodeVariableUsage> {
    if(variableTypePath.isRoot()) {
      return __(this.nodes).filter(n => n.nodeType.isCondition()).flatMap(node => {
        const variableUsages = node.conditionProperties.conditions.filter(condition => {
          throw new Error("Finding variables read in condition expression is not yet implemented");
          // let parsed = expressionParser.parseExpressionImmediate(condition.expression.expression);
          // return parsed.isDefined() && EstreeVariableUsageAnalyzer.findVariableInNode(parsed.get()).indexOf(variableTypePath.last()) >= 0
        });
        if(variableUsages.length > 0) {
          return [new ConditionNodeVariableUsage(VariableUsageType.read, node.id)]
        } else {
          return [];
        }
      });
    } else {
      return [];
    }
  }

  findActionsVariableWrite(rootVariableName: string): Array<BeforeActionVariableUsage> {
    const beforeResults = __(this.nodes).flatMap((node: GridProcessNode) => {
      return __(node.automaticActions.before)
        .flatMap((a: AutomaticActionRef) => {

          const action = this.getActionById(a.actionId);

          if(action.saveTo.some(r => r.path.toString() === rootVariableName)) {
            return [new BeforeActionVariableUsage(VariableUsageType.write, node.id, action.id)];
          } else {
            return [];
          }
        })
    });

    const afterResults = __(this.nodes).flatMap((node: GridProcessNode) => {
        return __(node.automaticActions.after)
          .flatMap((a: AutomaticActionRef) => {
            const action = this.getActionById(a.actionId);

            if(action.saveTo.some(r => r.path.toString() === rootVariableName)) {
              return [new AfterActionVariableUsage(VariableUsageType.write, node.id, action.id)];
            } else {
              return [];
            }
          })
    });
    return beforeResults.concat(afterResults);
  }


    findActionsVariableRead(expressionParser: ExpressionParser, rootVariableName: string): Array<BeforeActionVariableUsage> {
      const beforeResults = __(this.nodes).flatMap((node: GridProcessNode) => {
        return __(node.automaticActions.before)
          .flatMap((a: AutomaticActionRef) => {
            const action = this.getActionById(a.actionId);
            let usages: Array<VariableUsage> = [];
            toastr.info("Finding variables in action not yet implemented");
            const statement = expressionParser.parseExpressionImmediate("");//action.expression.expression);
            if(statement.isDefined()) {
              usages = usages.concat(EstreeVariableUsageAnalyzer.findVariableInNode(statement.get()).filter(v => v === rootVariableName).map(v => new BeforeActionVariableUsage(VariableUsageType.read, node.id, action.id)));
            }
            return usages;
          })
      });


      const afterResults = __(this.nodes).flatMap((node: GridProcessNode) => {
        return __(node.automaticActions.after)
          .flatMap((a: AutomaticActionRef) => {
            const action = this.getActionById(a.actionId);
            let usages: Array<VariableUsage> = [];
            toastr.info("Finding variables in action not yet implemented");
            const statement = expressionParser.parseExpressionImmediate("");//action.expression.expression);
            if(statement.isDefined()) {
              usages = usages.concat(EstreeVariableUsageAnalyzer.findVariableInNode(statement.get()).filter(v => v === rootVariableName).map(v => new AfterActionVariableUsage(VariableUsageType.read, node.id, action.id)));
            }
            return usages;
          })
      });
      return <Array<BeforeActionVariableUsage>>beforeResults.concat(afterResults);
    }

  findOutputMappingsVariableWrite(rootVariableName: string): Array<ExternalProcessResultMappingVariableUsage> {
    return <Array<ExternalProcessResultMappingVariableUsage>>___(this.nodes).filter(n => n.hasExternalProcessOutputMapping()).flatMap((node: GridProcessNode) => {
      const variablesMapping: Array<VariableUsage> = node.externalProcessProperties.outputMappings
        .filter(m => m.variableName.contains(rootVariableName))
        .map((m: OutputMappingV1) => new ExternalProcessOutputMappingVariableUsage(VariableUsageType.write, node.id, m.externalVariableName));
      if(node.externalProcessProperties.result.contains(rootVariableName)) {
        variablesMapping.push(new ExternalProcessResultMappingVariableUsage(VariableUsageType.write, node.id));
      }
      return variablesMapping;
    }).value();
  }

  findRepeatableSectionVariableBindings(rootVariableName: string): Array<RepeatableSectionVariableUsage> {
    return __(this.formSections)
      .filter(section => section.forEachVariableName.contains(rootVariableName))
      .map(section => {
        const nodes = this.nodesWithSection(section.id);
        return new RepeatableSectionVariableUsage(VariableUsageType.write, section.id, nodes.map(n => n.id));
    });
  }

  findActorsVariableRead(expressionParser: ExpressionParser, rootVariableName: string): Array<ActorVariableUsage> {
    return __(this.actors).flatMap((actor: GridProcessActor) => {
      if(actor.taskDistribution.method.name === TaskDistributionMethod.expression.name) {
        let usages: Array<ActorVariableUsage> = [];
        if(actor.taskDistribution.expressionWithAst.isDefined()) {
          const statement = expressionParser.parseExpressionImmediate(actor.taskDistribution.expressionWithAst.get().expression);
          if (statement.isDefined()) {
            usages = usages.concat(EstreeVariableUsageAnalyzer.findVariableInNode(statement.get())
                .filter(v => v === rootVariableName)
                .map(v => new ActorVariableUsage(VariableUsageType.read, actor.id)));
          }
        }
        return usages;
      } else {
        return [];
      }
    });
  }

  sectionsForNode(nodeId: ProcessNodeId): Array<FormSection> {
    const node = this.findNodeById(nodeId);
    if(node.nodeType.isStartForm() || node.nodeType.isActionForm()) {
      return node.form.sectionsRefs.map(sr => this.getSectionById(sr.sectionId));
    } else {
      return [];
    }

  }

  nodesWithSection(sectionId: FormSectionId): Array<GridProcessNode> {
    return this.nodes.filter(node => node.form.sectionsRefs.filter(ref => ref.sectionId.id === sectionId.id).length > 0);
  }

  sectionsInfosForNode(nodeId: ProcessNodeId): Array<FormSectionInfo> {
    const node = this.findNodeById(nodeId);
    if(node.nodeType.isStartForm() || node.nodeType.isActionForm()) {
      return this.findNodeById(nodeId).form.sectionsRefs.map(sr => new FormSectionInfo(sr, this.getSectionById(sr.sectionId)));
    } else {
      return [];
    }

  }

  sectionsRefsForNode(nodeId: ProcessNodeId): Array<FormSectionRef> {
    return this.findNodeById(nodeId).form.sectionsRefs;
  }

  variablesTypesForNode(nodeId: ProcessNodeId): Array<RootVariableType<BusinessVariableType>> {

    return __(this.sectionsForNode(nodeId)).flatMap(section => {
      if(section.forEachVariableName.isDefined()) {
        return [this.findRootVariableByName(section.forEachVariableName.get()).get()];
      } else {
        return section.inputElementsRefsUnwrapped().map(elementRef => {
          const element = this.inputElementById(elementRef.elementId);
          return this.findRootVariableByName(element.variableTypePath.last());
        }).filter(v => v.isDefined()).map(v => v.get());
      }
    });

  }

  inputElementsUnwrapped(): Array<InputElement> {
    return this.formElements.map(e => Typed.value(e)).filter((e: FormElement) => (<InputElement>e).variableTypePath !== undefined).map(e => <InputElement>e)
  }

  inputElementById(elementId: FormElementId): InputElement {
    return <InputElement>__(this.formElements).find(e => Typed.value(e).id.id == elementId.id).map(e => Typed.value(e)).get();
  }

  elementById(elementId: FormElementId): FormElement {
    return __(this.formElements).find(e => Typed.value(e).id.id == elementId.id).map(e => Typed.value(e)).get();
  }

  variableExpressionForPath(path: VariableTypePath) {
    return __(this.variableExpressions).find(e => e.path.isEqual(path));
  }

  variableExpressionForPathOrEmpty(path: VariableTypePath) {
    return this.variableExpressionForPath(path).getOrElse(new VariableExpression(path, ExpressionWithAst.empty(), false));
  }

  findVariableExpressionsByPathPrefix(prefix: VariableTypePath): Array<VariableExpression> {
    return this.variableExpressions.filter(ve => ve.path.hasPrefix(prefix))
  }
  //TODO expression check
  nodeUnsupportedFieldsForScheduler(startNode: GridProcessNode): Array<string> {
    let unsupportedFormElements: Array<string> = [];
    if(startNode.nodeType.isStartForm()) {
      const startNodeFormSections: Array<FormSection> = this.sectionsForNode(startNode.id);
      startNodeFormSections.forEach(formSection => formSection.inputElementsRefsUnwrapped()
        .filter(elementRef => elementRef.required.isTrue()).forEach(elementRef => {
          const element = this.inputElementById(elementRef.elementId);
          const rootVariableName = element.variableTypePath.head();
          const rootVariableType = this.findRootVariableByName(rootVariableName).get();
          switch (rootVariableType.unwrappedVariableType().className()) {
            case StringVariableType.className: break;
            case NumberVariableType.className: break;
            case DateTimeVariableType.className: break;
            case DateVariableType.className: break;
            case TimeVariableType.className: break;
            case BooleanVariableType.className: break;
            default: unsupportedFormElements.push(elementRef.label.getCurrentWithFallback()); break;
          }
        })
      );
    } else {
      throw new Error("Triggers are only for start node type!");
    }
    return unsupportedFormElements;
  }

  findNodesWithSection(sectionId: FormSectionId): Array<GridProcessNode> {
    return this.nodes.filter(n => __(n.form.sectionsRefs).exists(r => r.sectionId.id === sectionId.id));
  }

  findNodesWithElementReferingToVariable(variablePath: VariableTypePath) {
    const elements = this.inputElementsUnwrapped().filter(e => e.variableTypePath.isEqual(variablePath));
    const sections = this.formSections.filter(s => __(s.inputElementsRefsUnwrapped()).exists(e => __(elements).exists(el => el.id.id == e.elementId.id)));
    return this.nodes.filter(n => __(n.form.sectionsRefs).exists(r => __(sections).exists(s => s.id.id == r.sectionId.id)));
  }

  findNodesWithElement(elementId: number) {
    const sections = this.formSections.filter(s =>
      __(s.inputElementsRefsUnwrapped()).exists(e => e.elementId.id === elementId) ||
      __(s.staticElementsRefsUnwrapped()).exists(e => e.elementId.id === elementId));
    return this.nodes.filter(n => __(n.form.sectionsRefs).exists(r => __(sections).exists(s => s.id.id == r.sectionId.id)));
  }

  isFormNotEmpty(nodeId: ProcessNodeId): boolean {
    return __(this.sectionsForNode(nodeId)).exists(s => s.inputElementsRefs.length > 0) ||
      __(this.sectionsForNode(nodeId)).exists(s => s.staticElementsRefs.length > 0)
  }

  getActionById(actionId: Either<AutomaticActionId, string>): AutomaticAction {
    const unwrapped = this.actions.map(a => Typed.value(a[1]));
    if(actionId.isLeft()) {
      const id = actionId.getLeft();
      return required(unwrapped.find(a => a.id.id === id.id), "Action");
    } else if (actionId.isRight()) {
      const identifier = actionId.getRight();
      return required(unwrapped.find(a => a.identifier.contains(identifier)), "Action");
    } else {
      throw new Error("ActionId is not defined");
    }

  }

  actionsUnwrapped() {
    return this.actions.map(a => Typed.value(a[1]));
  }
}
