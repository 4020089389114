import {
  AttachmentEntry, BusinessEntityInfoViewModel,
  ComponentViewModelUtils,
  ComponentViewModelWithLabel, EmailInfoViewModel,
  FileInfoViewModel, FlowInfoViewModel,
  ScreenContainerViewModel,
  ScreenInstanceServerModel,
  ScreenSharedViewModel, ScreenWrapperViewModel,
  SingleAttachmentInputComponentRefState,
  SingleAttachmentInputComponentState,
  UnknownInfoViewModel
} from "../..";
import {FileUri, i18n, I18nText, None, NoneSingleton, Option, ScreenInstanceId, Some, toastr, VariableId} from "@utils";
import {ViewableFile, ViewableFileUrl} from "@shared";
import {
  BusinessEntityVariable,
  CaseVariable,
  EmailVariable,
  FileVariableV2,
  ScreenExternalEventBus
} from "@shared-model";
import {
  CssBuilder,
  SingleAttachmentDisplayMode,
  SingleAttachmentInputComponentDefinition,
  SingleAttachmentInputComponentRef
} from "@screen-common";

export class SingleAttachmentInputComponentViewModel extends ComponentViewModelWithLabel {

  override typeName = "SingleAttachmentInput";

  public tooltip: Option<string> = NoneSingleton;
  public placeholder: string = "";
  public css: string = "";
  public addRemoveEnabled: boolean = false;
  public required: boolean = false;
  public invalid: boolean = false; //TODO

  public allowedExtensions: Array<string> = [];
  public allowedExtensionsDefined: boolean = false;
  readonly instanceId: ScreenInstanceId;

  public attachmentDefined: boolean = false;
  public entry: AttachmentEntry|null = null;
  public entries: Array<any> = []; // used to simplify angular type handling and unify with multi attachment

  public uploadAllowed: boolean = true;
  public anchorVisible: boolean = false;
  public detailed: boolean = false;
  public minimal: boolean = false;
  public combinedCss = "";
  public combinedCssClasses = "";
  attachFromRepository: boolean = false;

  constructor(override readonly shared: ScreenSharedViewModel,
              readonly externalEventBus: ScreenExternalEventBus,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              readonly context: VariableId,
              override readonly definition: SingleAttachmentInputComponentDefinition,
              override readonly componentScreenId: string,
              readonly ref: SingleAttachmentInputComponentRef,
              override readonly refScreenId: string,
              override readonly componentState: SingleAttachmentInputComponentState,
              readonly refState: SingleAttachmentInputComponentRefState,
              readonly serverModel: ScreenInstanceServerModel
  ) {
    super(parent, definition, componentState, refState, shared);
    this.instanceId = this.serverModel.getInstanceId();
    this.update();
  }

  onDelete() {
    if(this.entry != null) {
      this.serverModel.clearModelWithAction(this.componentRefPath(), SingleAttachmentInputComponentDefinition.MODEL, "onChange");
    }
    this.attachmentDefined = false;
  }

  onCancel() {
    this.entry = null;
    this.entries = [];
    this.attachmentDefined = false;
  }

  updateComponent(deep: boolean): void {

    const cssBuilder = new CssBuilder();

    ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    ComponentViewModelUtils.toBackgroundCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState);
    ComponentViewModelUtils.toOuterShadowCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);

    this.placeholder = this.definition.placeholder.currentValue(() => this.componentState.placeholder).valueOrDefault(None()).getOrElse(I18nText.ofCurrent(i18n("Wybierz"))).getCurrentWithFallback();
    this.tooltip = this.definition.tooltip.currentValue(() => this.componentState.tooltip).valueOrDefault(None()).map(t => t.getCurrentWithFallback());
    this.required = this.ref.required.currentValue(() => this.refState.required).valueOrDefault(false);

    this.addRemoveEnabled = this.ref.addRemoveEnabled.currentValue(() => this.refState.addRemoveEnabled).valueOrDefault(false);

    const modelAllowedExtensions = this.definition.allowedExtensions.currentValue(() => this.componentState.allowedExtensions).valueOrDefault(None());
    this.allowedExtensions = modelAllowedExtensions.getOrElse([]);
    this.allowedExtensionsDefined = modelAllowedExtensions.isDefined();

    const displayMode = SingleAttachmentDisplayMode.of(this.definition.displayMode.currentValue(() => this.componentState.displayMode).valueOrDefault(SingleAttachmentDisplayMode.full.name));

    this.anchorVisible = this.definition.anchorVisible.currentValue(() => this.componentState.anchorVisible).valueOrDefault(true);

    this.detailed = displayMode == SingleAttachmentDisplayMode.full;
    this.minimal = displayMode == SingleAttachmentDisplayMode.minimal;

    const attachment = this.componentState.model.valueOrDefault(None());

    if(attachment.isDefined()) {

      const value = attachment.get();

      if(value instanceof FileVariableV2) {
        this.serverModel.loadFileInfo(this.componentRefPath(), value.value, (fileInfo) => {
          const info = FileInfoViewModel.placeholder(value);
          info.updateInfo(fileInfo);
          this.attachmentDefined = true;
          this.entry = info;
          this.entries = [this.entry];
        });
      } else if(value instanceof EmailVariable) {
        this.serverModel.loadEmailInfo(0, value.value, (requestNumber, emailInfo) => {
          const info = EmailInfoViewModel.placeholder(value);
          info.updateInfo(emailInfo);
          this.entry = info;
          this.entries = [this.entry];
        });
      } else if(value instanceof CaseVariable) {
        this.serverModel.loadFlowInfo(0, value.value, (requestNumber, flowInfo) => {
          if(flowInfo.isDefined()) {
            const info = FlowInfoViewModel.placeholder(value);
            info.updateInfo(flowInfo.get());
            this.entry = info;
            this.entries = [this.entry];
          } else {
            const info = FlowInfoViewModel.placeholder(value);
            this.entry = info;
            this.entries = [this.entry];
          }
        });
      } else if(value instanceof BusinessEntityVariable) {

        this.serverModel.loadBusinessEntityInfo(value.value, (entityInfo) => {
          const info = BusinessEntityInfoViewModel.placeholder(value);
          info.updateInfo(entityInfo)
          this.attachmentDefined = true;
          this.entry = info;
          this.entries = [this.entry];
        });
      } else {
        this.attachmentDefined = true;
        this.entry = UnknownInfoViewModel.placeholder(value);
        this.entries = [this.entry];
      }



    } else {
      this.attachmentDefined = false;
      this.entry = null;
      this.entries = [];
    }

    super.updatePosition();

    this.combinedCss = cssBuilder.toCss() +this.sizeCss;
    this.combinedCssClasses = cssBuilder.toCssClasses();
  }


  fileUploadCompleted(uploadId: number, fileUri: FileUri) {
    const downloadUrl = this.serverModel.getFileDownloadUri(this.componentRefPath(), fileUri);
    if(this.entry instanceof FileInfoViewModel && this.entry.uploadId === uploadId) {
      this.entry.completeFileUpload(fileUri, downloadUrl);
    }
  }

  fileUploadProgress(uploadId: number, uploadedBytes: number, totalBytes: number) {
    if(this.entry instanceof FileInfoViewModel && this.entry.uploadId === uploadId) {
      this.entry.updateFileUpload(uploadedBytes, totalBytes);
    }
  }

  extensionValid(fileName: string) {
    return true;
  }

  fileUploadError(uploadId: number) {
    toastr.error("File upload error");
  }

  newFileUploadStarted(uploadId: number, fileName: any) {
    this.attachmentDefined = true;
    this.entry = FileInfoViewModel.newUpload(fileName, uploadId);
    this.entries = [this.entry];
  }

  toggleFileViewerVisibility() {
    let viewableFiles: Array<ViewableFile> = [];
    if(this.entry instanceof FileInfoViewModel) {
      const file = this.entry;
      viewableFiles = [new ViewableFile(file.name, file.size, file.version > 1 ? Some(file.version) : None(), new ViewableFileUrl(file.downloadUrl), false, file.modified, file.uri, false, None(), None(), None(), None(), !file.exists)];
    } else {
      throw new Error("Not a file");
    }
    this.externalEventBus.filesPreviewRequested(viewableFiles, 0);
  };

  toggleEmailViewerVisibility() {
    if(this.entry instanceof EmailInfoViewModel) {
      this.externalEventBus.emailPreviewRequested(this.entry.uri);
    }
  };
}
