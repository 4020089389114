<div class="durationPicker">
  <input #DurationPickerInput
         type="text"
         placeholder="{{viewModel.placeholder}}"
         class="textInput"
         [ngClass]="{focused: viewModel.focused}"
         [(ngModel)]="viewModel.textModel"
         (ngModelChange)="viewModel.onKeyboardInput()"
         (focus)="onFocused()"
         (keydown.enter)="onAccepted()"
         [disabled]="viewModel.disabled"/>


  <my-drop-menu cssClass="theme-drop-menu temporalComponentPickerPopup" [anchor]="DurationPickerInput" [(visible)]="viewModel.focused" (closed)="popupClosed()">
    <ng-container *ngIf="viewModel.focused">
      <div class="pickerActions">
        <button class="setEmpty" tabindex="-1" (click)="viewModel.clear()">{{'common_clear' | i18n}}</button>
      </div>
      <my-duration-picker-board [(duration)]="viewModel.value"
                                (durationChange)="viewModel.updateValue($event)"
                                (picked)="viewModel.onPopupValueChange($event)"
                                [hoursInDay]="viewModel.hoursInDays"
                                [useSeconds]="viewModel.useSecondsInPicker" />
    </ng-container>
  </my-drop-menu>

</div>
