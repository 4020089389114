import {
  BusinessEntityVariable,
  BusinessVariable, CaseVariable,
  EmailBasicInfo,
  EmailVariable,
  FileInfoModel,
  FileVariableV2, FlowAndTasksInfoForUser
} from "@shared-model";
import {
  BusinessEntityIdWithType,
  FileProtocol,
  FileUri, FlowId,
  getFileExtensionOrUndefined,
  getFileMainName,
  LocalDateTime,
  None,
  Option
} from "@utils";
import {BusinessEntitySummary} from "@shared-model";

export interface AttachmentEntry {
    value: BusinessVariable|null; // null is for uploaded file
    placeholder: boolean;
    isProcessed(): boolean;
    entryType: string;
    uploadInProgress: boolean;
    isFile: boolean;
  }

  export class BusinessEntityInfoViewModel implements AttachmentEntry {
    readonly entryType = "businessEntity"

    public summary = "";

    readonly uploadInProgress: boolean = false;
    readonly isFile: boolean = false;
    constructor(
        readonly value: BusinessEntityVariable,
        public id: BusinessEntityIdWithType,
        public placeholder: boolean) {

    }

    isProcessed(): boolean {
      return false;
    }

    static placeholder(entity: BusinessEntityVariable) {
      return new BusinessEntityInfoViewModel(entity, entity.value, true);
    }

    updateInfo(entityInfo: BusinessEntitySummary) {
      const name = entityInfo.nameUnwrapped().map(v => v.valueToSimpleString()).join(" ");
      const description = entityInfo.descriptionUnwrapped().map(v => v.valueToSimpleString()).join(" ");
      this.summary = entityInfo.code+" "+name+" "+description;
      this.placeholder = false;
    }

  }


  export class UnknownInfoViewModel implements AttachmentEntry {
    readonly entryType = "unknown";
    readonly variableTypeName: string;
    readonly placeholder: boolean = false;
    readonly uploadInProgress: boolean = false;
    readonly isFile: boolean = false;

    constructor(readonly value: BusinessVariable) {
      this.variableTypeName = value.simpleValueType()
    }

    static placeholder(value: BusinessVariable) {
      return new UnknownInfoViewModel(value);
    }

    updateInfo(info: any) {
      throw new Error("Updating unkown not supported");
    }

    isProcessed(): boolean {
      return false;
    }

  }



  export class DirectoryInfoViewModel implements AttachmentEntry {
    readonly entryType = "directory";
    readonly uploadInProgress: boolean = false;
    readonly isFile: boolean = false;
    constructor(
      readonly value: FileVariableV2,
      public uri: FileUri,
      public browseUrl: string,
      readonly exists: boolean,
      readonly name: string,
      readonly repositoryPath: Option<string>,
      public placeholder: boolean) {}

    static placeholder(directory: FileVariableV2) {
      return new DirectoryInfoViewModel(directory, directory.value, "", true, "", None(), true);
    }

    isProcessed(): boolean {
      return false;
    }
  }

  export class FileInfoViewModel implements AttachmentEntry {

    readonly entryType = "file";

    public visibleName = "";
    public visibleExtension?: string;
    public tooltipName = "";
    readonly isFile: boolean = true;
    constructor(
      readonly value: FileVariableV2|null, // null is for uploaded file
      public uri: FileUri,
      public exists: boolean,
      public name: string,
      public size: number,
      public version: number,
      public modified: LocalDateTime,
      public downloadUrl: string,
      public uploadId: number,
      public uploadedSize: number,
      public uploadInProgress: boolean,
      public uploadProgressPercentage: string,
      public placeholder: boolean
    ) {

      this.visibleName = getFileMainName(name);
      this.visibleExtension = getFileExtensionOrUndefined(name);

    }

    isProcessed(): boolean {
      return this.uploadInProgress;
    }

    static placeholder(file: FileVariableV2) {
      return new FileInfoViewModel(file, file.value, true, "", 0, 0, LocalDateTime.now(), "", -1, 0, false, "", true);
    }

    static newUpload(fileName: string, uploadId: number) {
      return new FileInfoViewModel(
        null,
        new FileUri(FileProtocol.InternalFile, ""),
        true,
        fileName,
        0,
        0,
        LocalDateTime.now(),
        "",
        uploadId,
        0,
        true,
        "0%",
        false
      );
    }

    updateInfo(info: FileInfoModel) {
      this.exists = info.exists;
      this.name = info.name;
      this.size = info.size;
      this.version = info.version;
      this.modified = LocalDateTime.copy(info.modified);
      this.downloadUrl = info.downloadUrl;
      this.uploadedSize = 0;
      this.uploadInProgress = false;
      this.uploadProgressPercentage = "";
      this.placeholder = false

      if(info.repositoryPath.isDefined() && info.repositoryPath.get().indexOf("/") >= 0) {
        this.visibleName = "/../" + getFileMainName(info.name);
        this.visibleExtension = getFileExtensionOrUndefined(info.name);
        this.tooltipName = info.repositoryPath.getOrElse(info.name);
      } else {
        const fullPath = info.repositoryPath.getOrElse(info.name);
        this.visibleName = getFileMainName(fullPath);
        this.visibleExtension = getFileExtensionOrUndefined(fullPath);
        this.tooltipName = "";
      }

    }

    completeFileUpload(fileUri: FileUri, downloadUrl: string) {
      this.uri = fileUri;
      this.downloadUrl = downloadUrl;
      this.uploadInProgress = false;
      this.uploadProgressPercentage = "";
      this.uploadedSize = 0;
    }

    updateFileUpload(uploadedBytes: number, totalBytes: number) {
      this.uploadInProgress = true;
      this.uploadProgressPercentage = (uploadedBytes / totalBytes) * 100 + "%";
      this.uploadedSize = uploadedBytes;
      this.size = totalBytes;
    }

    wasUploaded() {
      return this.uploadId >= 0 && !this.uploadInProgress;
    }
  }



export class EmailInfoViewModel implements AttachmentEntry {
  readonly entryType = "email";
  public senderReceiverName: string = "";
  public senderReceiverAddress: string = "";
  public subject: string = "";
  uploadInProgress = false;
  isFile = false;
  constructor(
    readonly value: EmailVariable,
    public uri: FileUri,
    public received: boolean,
    public created: LocalDateTime,
    public exists: boolean,
    public placeholder: boolean) {
  }

  static placeholder(email: EmailVariable) {
    return new EmailInfoViewModel(email, email.value,false, LocalDateTime.now(), true, true);
  }

  updateInfo(info: EmailBasicInfo) {
    if(info.senderReceiverName.length > 0) {
      this.senderReceiverName = info.senderReceiverName;
      this.senderReceiverAddress = info.senderReceiverAddress;
    } else {
      this.senderReceiverName = info.senderReceiverAddress;
      this.senderReceiverAddress = "";
    }
    this.received = info.received;
    this.subject = info.subject;
    this.created = info.created;
    this.exists = !info.deleted;
    this.placeholder = false;
  }
  isProcessed(): boolean {
    return false;
  }
}


export class FlowInfoViewModel implements AttachmentEntry {
  readonly entryType = "flow";
  public codeAvailable: boolean = false;
  public code: string = "";
  public browseUrl: string = "";
  public caseName: string = "";
  public processName: string = "";
  public inProgress: boolean = false;
  public terminated: boolean = false;
  public completed: boolean = false;

  navigationVisible: boolean = false;
  isFile: boolean = false;
  uploadInProgress: boolean = false;
  //navigation: FlowNavigationPopupViewModel;


  constructor(
    readonly value: CaseVariable,
    public id: FlowId,
    public placeholder: boolean) {}

  static placeholder(flow: CaseVariable) {
    return new FlowInfoViewModel(flow, flow.value, true);
  }

  updateInfo(info: FlowAndTasksInfoForUser) {
    const flowCode = info.flowCode();
    this.codeAvailable = flowCode.isDefined();
    this.code = flowCode.getOrElse("");
    this.browseUrl = flowCode.map(code => "/#/flow/" + code).getOrElse("/#/flow?flowId="+info.flowIdUnwrapped().id);
    this.placeholder = false;
    this.processName = info.processName.getOrElse("");
    this.caseName = info.flowInfo.map(f => f.flowDescription).getOrElse("");
    // this.navigation = new flownavigation.FlowNavigationPopupViewModel(this.code, () => {
    //   this.navigationVisible = false;
    // });
    // this.navigation.update(info.flowInfo, info.processName, info.tasks);
    this.inProgress = info.flowInfo.exists(e => e.finished.isEmpty());
    this.terminated = info.flowInfo.exists(e => e.finished.exists(f => f.status.isTerminated()));
    this.completed = info.flowInfo.exists(e => e.finished.exists(f => f.status.isCompleted()));
  }

  isProcessed(): boolean {
    return false;
  }

  toggleNavigationVisibility() {
    this.navigationVisible = !this.navigationVisible;
  }

}
