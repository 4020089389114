import {__, None, Option, overwriteArray, Some, VariableId} from "@utils";
import {ArrayVariable, BusinessVariable} from "@shared-model";
import {
  BackgroundsPropertiesState,
  BordersPropertiesState,
  HeaderPropertiesState,
  LayoutsPropertiesState,
  PaddingsPropertiesState, ParentComponentId,
  PropertiesStateHolder,
  RepeatableContext,
  RepeatableContextComponentState,
  ScreenComponentRefState
} from "../..";
import {TableContainerDefinition} from "@screen-common";

export class TableContainerState extends RepeatableContextComponentState {
  static className = "TableContainerState";
  className() {
    return TableContainerState.className;
  }

  readonly layoutsState = new LayoutsPropertiesState("", this.properties);
  readonly paddingsState = new PaddingsPropertiesState("", this.properties);
  readonly bordersState = new BordersPropertiesState(this.properties);
  readonly backgroundsState = new BackgroundsPropertiesState("", this.properties);

  constructor(public innerContext: Option<RepeatableContext>,
              readonly properties: PropertiesStateHolder,
              override readonly parent: Option<ParentComponentId>) {
    super(properties);
  }

  get contentTextSize() {return this.properties.optionalStringProperty("textSize")}
  get contentTextColor() {return this.properties.optionalStringProperty("textColor")}
  get contentTextFont() {return this.properties.optionalStringProperty("textFont")}

  get header() {return this.properties.optionalI18nTextProperty("header")}

  get headerVisible() {return this.properties.booleanProperty("headerVisible")}

  readonly headerState = new HeaderPropertiesState(this.properties);

  get paginationEnabled() {return this.properties.booleanProperty("paginationEnabled")}
  get pageSize() {return this.properties.numberProperty("pageSize")}

  get minEntries() {
    return this.properties.optionalNumberProperty("minEntries");
  }

  get maxEntries() {
    return this.properties.optionalNumberProperty("maxEntries");
  }

  get entriesLayout() {
    return this.properties.stringProperty("entriesLayout");
  }

  pushToInnerContext(entryId: VariableId) {
    this.innerContext.getOrError("No inner context").entries.push(entryId);
  }

  removeFromModel(entryId: VariableId) {
    const innerContext = this.innerContext.getOrError("No inner context");
    const index = __(innerContext.entries).findIndexOf(e => e.id == entryId.id);
    if(index.isDefined()) {
      innerContext.entries.splice(index.get(), 1);
    }
  }

  updateInnerContext(innerContext: RepeatableContext) {
    if(this.innerContext.isDefined() && this.innerContext.get().contextId.id == innerContext.contextId.id) {
      overwriteArray(this.innerContext.get().entries, innerContext.entries);
    } else {
      this.innerContext = Some(innerContext);
    }
  }

  clearInnerContext(): void {
    this.innerContext = None();
  }

  override updateModel(modelName: string, value: Option<BusinessVariable>) {
    switch (modelName) {
      case TableContainerDefinition.model:
        if(value.isDefined() && value.get() instanceof ArrayVariable) {
          this.properties.putValue(TableContainerDefinition.model, value.get());
        } else if (value.isDefined()) {
          throw new Error("Model is not of expected type Array[Object] but [" + value.get().simpleValueType()+"]");
        } else {
          this.properties.clearValue(TableContainerDefinition.model);
        }
        break;
      default: throw new Error("Model ["+modelName+"] is not supported by "+this.className());
    }
  }


  static copy(other: TableContainerState) {
    return new TableContainerState(
      Option.copy(other.innerContext, RepeatableContext.copy),
      PropertiesStateHolder.copy(other.properties),
      Option.copy(other.parent, ParentComponentId.copy));
  }

}

export class TableContainerRefState extends ScreenComponentRefState {
  static className = "TableContainerRefState";
  className() {
    return TableContainerRefState.className;
  }

  constructor(readonly properties: PropertiesStateHolder) {
    super(properties);
  }

  get lengthEditable() {
    return this.properties.booleanProperty("lengthEditable");
  }

  get sortable() {
    return this.properties.booleanProperty("sortable");
  }

  static copy(other: TableContainerRefState) {
    return new TableContainerRefState(PropertiesStateHolder.copy(other.properties));
  }

}
