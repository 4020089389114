import {DeferredValue, EventBus, ObjectId, Option, PositionXY} from "@utils";
import {
  BusinessVariable,
  BusinessVariableType, DataTableColumnModel,
  EmailVariable,
  FileVariableV2,
  FlowAndTasksInfoForUser, ObjectVariable
} from "@shared-model";
import {Subject} from "rxjs";


export interface FlowInfoProvider {
  getFlowInfo(flowCode: string): DeferredValue<Option<FlowAndTasksInfoForUser>>;
}

export interface VariableNameProvider {
  getName(variable: BusinessVariable, column?: number): DeferredValue<Option<string>>;

  getNameAndLink(variable: BusinessVariable, rowIndex: number): DeferredValue<Option<[string, string]>>;
}


export class CellNewValue {
  constructor(readonly rowIndex: number, readonly recordId: ObjectId, readonly columnName: string, readonly value: Option<BusinessVariable>) {}
}

export class DataTableEventBus extends EventBus {
  cellsValueChanged(newValues: Array<CellNewValue>) {}
  rowAdded(rowIndex: number, recordId: ObjectId, values: {[name: string]: BusinessVariable}) {}
  rowsDeleted(rowsIndices: Array<number>) {} // including toIndex
  columnsDeleted(names: Array<string>) {}
  columnAdded(columnIndex: number, name: string) {}

  columnNameChanged(oldName: string, newName: string) {}
  columnTypeChanged(name: string, newType: BusinessVariableType) {}

  fileSelected(file: FileVariableV2, rowIndex: number, columnIndex: number, fileIndex: number) {}
  emailSelected(email: EmailVariable, rowIndex: number, columnIndex: number, fileIndex: number) {}
  caseCodeSelected(caseCode: string, cursorPosition: PositionXY) {}

  rowActivated(rowIndex: number, columnIndex: Option<number>) {}
  rowDeactivated() {}


}

export interface DataInitModel {
  uniqueIdentifier: string,
  columns: Array<DataTableColumnModel>,
  rawData: Array<ObjectVariable>,
  variableNameProvider?: VariableNameProvider,
  flowInfoProvider?: FlowInfoProvider,
  firstRowNumber?: number
}

export class DataTableDataProvider {


  private _dataModel?: DataInitModel;
  private dataModelSubject = new Subject<DataInitModel>();
  dataModelObservable = this.dataModelSubject.asObservable();

  initDataModel(uniqueIdentifier: string,
                columns: Array<DataTableColumnModel>,
                rawData: Array<ObjectVariable>,
                firstRowNumber?: number) {
    this._dataModel = {
      uniqueIdentifier,
      columns,
      rawData,
      firstRowNumber
    };
    this.dataModelSubject.next(this._dataModel);
  }

  get dataModel(): DataInitModel|undefined {
    return this._dataModel;
  }
}
