import {
  $$,
  __,
  ___,
  AutomaticActionId,
  AutomaticActionRefId,
  CachedLine,
  Duration,
  Either, FormElementId, FormElementRefId,
  getElementPosition,
  getElementPositionAndSize,
  GridSize,
  GridXY,
  GridXYPath,
  i18n,
  I18nText,
  IdentifierInputType,
  LocalDateTime,
  MultiTypeInput,
  MyIcons,
  myRequestAnimationFrame,
  mySetTimeout,
  Option,
  PositionXY,
  ProcessStart,
  range,
  RectXY,
  removeFromArray,
  removeFromArrayBy,
  required, restUrl,
  ShiftXY,
  Size,
  toastr,
  Trilean,
  WrapperConfig,
  wrapSingleStringText,
} from "@utils";
import {DesignMapConfig} from "./Config";
import {EditorCanvasModel} from "./EditorCanvasModel";
import {XYCalculator} from "./XYCalculator";
import {
  EdgeType,
  NodeMode,
  NodeType,
  ProcessAnnotationId,
  ProcessCommentsGroupId,
  ProcessEdgeId,
  ProcessInfo,
  ProcessMapComments,
  ProcessNodeId,
  ProcessPhaseId,
  ProcessPreviewFormId,
  ProcessRoleId,
  VariablePath
} from "@shared-model";
import {roundPathCorners} from "./roundPathCorners";
import {EdgeLabelRect, EdgeWithShift, EdgeXYCalculator} from "./EdgeXYCalculator";
import {FormSectionId, GravatarService, I18nService, ScreenComponentRefIdentifier} from "@shared";
import {
  GridProcessModel,
  GridProcessPhasesDefinition,
  ProcessBooleanValue,
  ProcessDurationValue
} from "../model/GridProcessModel";
import {
  EmailElementType,
  ErrorHandlingType,
  GridProcessNode,
  ProcessServiceId,
  ProcessValueType
} from "../model/GridProcessNode";
import {
  BooleanValue,
  CloseParallelType,
  ConditionProperties,
  ConditionType,
  ExpressionValue,
  LogicOperation,
  LogicOperationValue,
  LogicRule,
  NumberValue,
  OpenType,
  StringValue,
  VariableValue
} from "../model/ConditionProperties";
import {AutomaticAction, AutomaticActionRef, ScreenWithRelease, VariableContext} from "@screen-common";
import {
  ConditionRuleViewModel,
  LogicOperationValueViewModel,
  LogicOperationViewModel,
  LogicRuleViewModel
} from "../../designer.module/process-map-editor/map-editor/condition-rule/condition-rule.component";
import {NodeAssignment} from "../model/NodeAssignment";
import {TaskDistribution} from "../model/TaskDistribution";
import {TasksVisibility} from "../model/ProcessModel";
import {SharedServiceViewModel} from "../../designer.module/process-map-editor/map-editor/map-canvas-editor-view.model";
import {NodeMetadata, ValueAdded} from "../model/NodeMetadata";
import {DurationMethod, ExpectedDuration} from "../model/ExpectedDuration";
import {ActionIneffectiveness} from "../model/ActionIneffectiveness";
import {ParameterViewModel} from "../../designer.module/components/input-parameters/parameter.component";
import {
  ProcessMapEditorModelEventBus
} from "../../designer.module/process-map-editor/map-editor/process-map-editor.model-event-bus";
import {
  NodeStartTriggerInputParameterAdded,
  NodeStartTriggerInputParameterChanged,
  NodeStartTriggerInputParameterDeleted,
  NodeStartTriggerOutputParameterAdded,
  NodeStartTriggerOutputParameterChanged,
  NodeStartTriggerOutputParameterDeleted
} from "../../designer.module/process-map-editor/map-editor/process-map-editor.ui-events";
import {LegacyProcessNodeViewModel, LegacyVariablesViewModel} from "./LegacyProcessNodeViewModel";
import {EdgeDelayMethod, GridProcessEdge} from "../model/GridProcessEdge";

export class DesignCanvasViewPort {
  constructor(
    public position: PositionXY,
    public scale: number) {
  }
}

export class EdgeEndPosition {
  constructor(
    readonly edgeId: ProcessEdgeId,
    public x: number,
    public y: number,
    public rotateDegrees: number,
    public start: boolean,
    public edgeDefault: boolean) {}
}

export class BackgroundViewModel {
  constructor(
    public x: number,
    public y: number,
    public width: number,
    public height: number) {}
}

export class EdgeConditionViewModel {
  public edge?: ProcessEdgeViewModel;
  public nextNode?: ProcessNodeViewModel;

  constructor(readonly edgeId: ProcessEdgeId,
              readonly rule: ConditionRuleViewModel) {}

  getRuleByIndexPath(indexPath: ReadonlyArray<number>): LogicRuleViewModel {

    if(indexPath.length === 0) {
      throw new Error("Path cannot be empty");
    } else if(indexPath[0] !== 0) {
      throw new Error("Path must start with 0");
    } else if(this.rule.operationType !== "rule") {
      throw new Error("Path is only supported for rule condition, but was '"+this.rule.operationType+"'");
    } else {
      return this.rule.condition.getRuleByIndexPath(indexPath.slice(1));
    }

  }

  static empty(edgeId: ProcessEdgeId) {
    return new EdgeConditionViewModel(edgeId, new ConditionRuleViewModel("rule", LogicRuleViewModel.empty(), ""));
  }
}

export class ProcessNodeConditionViewModel {

  conditionTypeSelectorVisible: boolean = false;
  isOpen: boolean = false;
  isCloseParallel: boolean = false;
  isOpenSimple: boolean = false;
  isOpenParallelAll: boolean = false;
  isOpenParallelAccepted: boolean = false;

  isCloseParallelAll: boolean = false;
  isCloseParallelFirst: boolean = false;

  constructor(readonly conditions: Array<EdgeConditionViewModel>,
              public defaultEdge?: ProcessEdgeId,
              public conditionType?: ConditionType,
              public openType?: OpenType,
              public closeParallelType?: CloseParallelType,) {}

  static empty() {
    return new ProcessNodeConditionViewModel([]);
  }

  getConditionForEdge(edgeId: ProcessEdgeId) {
    return required(this.conditions.find(c => c.edgeId === edgeId), "condition");
  }
}

export class ProcessAnnotationLineViewModel {
  constructor(
    public x1: number,
    public y1: number,
    public x2: number,
    public y2: number) {}

}

export class ProcessAnnotationViewModel {

  dragged = false;
  textEdited = false;
  selected: boolean = false;

  // display values
  x: number = 0;
  y: number = 0;
  width: number = 0;
  height: number = 0;
  textLines: Array<TextLineViewModel> = []

  lines: Array<ProcessAnnotationLineViewModel> = [];

  constructor(
    readonly id: ProcessAnnotationId,
    public gridX: number,
    public gridY: number,
    public shiftX: number,
    public shiftY: number,
    public text: I18nText,
    public nodes: Array<ProcessNodeId>,
    public edges: Array<ProcessEdgeId>,
    ) {}

  gridXY() {
    return new GridXY(this.gridX, this.gridY);
  }

  gridShiftXY() {
    return new ShiftXY(this.shiftX, this.shiftY);
  }
}

export class EmailHeaderMappingViewModel {
  constructor(
    public header: string,
    public variable: string) {}

  static empty() {
    return new EmailHeaderMappingViewModel("", "");
  }

}

export class StartupViewModel {

  viaOtherProcess: boolean = false;
  viaEmail: boolean = false;
  viaSchedule: boolean = false;
  viaApi: boolean = false;
  viaIframe: boolean = false;


  allowedContexts: Array<VariableContext> = [];
  variablesContext: VariableContext = VariableContext.externalContext;

  emailSubject: string = "";
  emailFrom: string = "";
  emailSent: string = "";
  emailReceived: string = "";
  emailBody: string = "";
  emailAttachments: string = "";
  emailEmail: string = "";
  emailEML: string = "";
  emailFlowId: string = "";
  emailRecipients: string = "";
  emailHeaders: Array<EmailHeaderMappingViewModel> = [];
  emailMoreOptions: boolean = false;

  inputParameters: Array<ParameterViewModel> = [];
  outputValues: Array<ParameterViewModel> = [];
  iframeAnonymousUrl: string = "";

  static empty() {
    return new StartupViewModel();
  }

  static of(node: GridProcessNode) {
    const startup = new StartupViewModel();

    const startTriggers = node.startTriggers;
    const emailTrigger = startTriggers.emailTrigger;
    startup.viaEmail = emailTrigger.enabled;
    startup.viaApi = startTriggers.apiTrigger.enabled;
    startup.viaIframe = startTriggers.outOfPlatformTrigger.enabled;
    startup.viaSchedule = startTriggers.casesSchedulerTrigger.enabled;
    startup.viaOtherProcess = startTriggers.otherProcessTrigger.enabled;

    startup.emailSubject = emailTrigger.getMapping(EmailElementType.subject.mailElementName);
    startup.emailFrom = emailTrigger.getMapping(EmailElementType.from.mailElementName);
    startup.emailSent = emailTrigger.getMapping(EmailElementType.sentDate.mailElementName);
    startup.emailReceived = emailTrigger.getMapping(EmailElementType.receivedDate.mailElementName);
    startup.emailBody = emailTrigger.getMapping(EmailElementType.body.mailElementName);
    startup.emailAttachments = emailTrigger.getMapping(EmailElementType.attachments.mailElementName);
    startup.emailEmail = emailTrigger.getMapping(EmailElementType.emailEmail.mailElementName);
    startup.emailEML = emailTrigger.getMapping(EmailElementType.wholeEmail.mailElementName);
    startup.emailFlowId = emailTrigger.getMapping(EmailElementType.flowId.mailElementName);
    startup.emailRecipients = emailTrigger.getMapping(EmailElementType.recipients.mailElementName);

    startup.inputParameters = node.startTriggers.input.map(p => new ParameterViewModel(p.id, p.name, p.valueTypeUnwrapped(), p.required, undefined));
    startup.outputValues = node.startTriggers.output.map(p => new ParameterViewModel(p.id, p.name, p.valueTypeUnwrapped(), false, undefined));

    startup.emailHeaders = emailTrigger.headerMappings.map(m => {
      return new EmailHeaderMappingViewModel(m.headerName, m.variableName.getOrElse(""));
    })

    startup.emailMoreOptions = startup.emailSent.length > 0 || startup.emailEML.length > 0 || startup.emailFlowId.length > 0 || startup.emailRecipients.length > 0;

    return startup;
  }


  addHeader() {
    this.emailHeaders.push(EmailHeaderMappingViewModel.empty());
  }

  deleteHeader(header: EmailHeaderMappingViewModel) {
    removeFromArray(this.emailHeaders, header);
  }

  toggleEmailMoreOptions() {
    this.emailMoreOptions = !this.emailMoreOptions;
  }
}


export class ExpectedDurationViewModel {
  constructor(
    public method: DurationMethod,
    public duration: Duration|null = null,
    public expression: string = "",
    public field: string|null = null,
    public editableForCaseRole: Array<number> = [],
    public editableForInstanceRole: Array<number> = []) {}

  static of(expectedDuration: ExpectedDuration) {
    return new ExpectedDurationViewModel(
      expectedDuration.method,
      expectedDuration.durationMillis.map(durationMillis => Duration.ofMilliseconds(durationMillis)).getOrElse(Duration.ZERO),
      expectedDuration.expressionWithAst.map(expressionWithAst => expressionWithAst.expression).getOrElse(""),
      expectedDuration.field.getOrNull(),
      expectedDuration.editableForCaseRole,
      expectedDuration.editableForInstanceRole
    );
  }

  static newEmpty() {
    return new ExpectedDurationViewModel(DurationMethod.onActionAppear);
  }
}

export class ActionIneffectivenessViewModel {
  constructor(
    public name: string,
    public description: string,
    public analysis: string) {}

  static of(actionIneffectiveness: ActionIneffectiveness) {
    return new ActionIneffectivenessViewModel(actionIneffectiveness.name, actionIneffectiveness.description, actionIneffectiveness.analysis);
  }

  static newEmpty() {
    return new ActionIneffectivenessViewModel("", "", "");
  }
}

export class ProcessNodeMetadataViewModel {
  constructor(
    public valueAdded: ValueAdded,
    public averageRealizationDuration: Duration|null,
    public expectedMaximumDuration: ExpectedDurationViewModel,
    public automatic: boolean,
    public ineffectiveness: Array<ActionIneffectivenessViewModel>) {}

  static of(nodeMetadata: NodeMetadata) {
    return new ProcessNodeMetadataViewModel(
      nodeMetadata.valueAdded,
      Duration.ofMilliseconds(nodeMetadata.averageRealizationDurationMillis),
      ExpectedDurationViewModel.of(nodeMetadata.expectedMaximumDuration),
      nodeMetadata.automatic,
      nodeMetadata.ineffectiveness.map(actionIneffectiveness => ActionIneffectivenessViewModel.of(actionIneffectiveness))
    );
  }

  static newEmpty() {
    return new ProcessNodeMetadataViewModel(ValueAdded.valueAdded, Duration.ZERO, new ExpectedDurationViewModel(DurationMethod.onActionAppear), false, []);
  }

  static newEmptyForFinish() {
    return new ProcessNodeMetadataViewModel(ValueAdded.valueAdded, Duration.ZERO, new ExpectedDurationViewModel(DurationMethod.onCaseStart), false, []);
  }

}

export class ProcessNodeExternalProcessViewModelInputMapping {
  constructor(readonly id: number,
              public variableName: string,
              public value: MultiTypeInput) {}
}

export class ProcessNodeExternalProcessViewModelOutputMapping {
  constructor(readonly id: number,
              public externalVariableName: string,
              public saveToVariablePath: VariablePath) {}
}

export class ProcessNodeExternalProcessViewModel {

  identifiersMode: boolean = false;
  processStart: ProcessStart|undefined;
  // deprecated
  processServiceId: ProcessServiceId|undefined;
  applicationIdentifier: MultiTypeInput = IdentifierInputType.empty();
  processInstanceIdentifier: MultiTypeInput = IdentifierInputType.empty();
  startNodeIdentifier: MultiTypeInput = IdentifierInputType.empty();
  input: Array<ProcessNodeExternalProcessViewModelInputMapping> = [];
  output: Array<ProcessNodeExternalProcessViewModelOutputMapping> = [];
  killSubFlowOnTerminate: boolean = false;
  errorHandling: ErrorHandlingType = ErrorHandlingType.finishGracefully

  static empty() {
    return new ProcessNodeExternalProcessViewModel();
  }

  static of(node: GridProcessNode) {
    const external = new ProcessNodeExternalProcessViewModel();
    external.applicationIdentifier = node.externalProcessProperties.applicationIdentifierUnwrapped();
    external.processInstanceIdentifier = node.externalProcessProperties.processInstanceIdentifierUnwrapped();
    external.startNodeIdentifier = node.externalProcessProperties.startNodeIdentifierUnwrapped();
    external.identifiersMode = node.externalProcessProperties.identifiersMode;
    external.processStart = node.externalProcessProperties.processStart.getOrUndefined();
    external.processServiceId = node.externalProcessProperties.processServiceId.getOrUndefined();
    external.input = node.externalProcessProperties.inputMappings.map(i => {
      return new ProcessNodeExternalProcessViewModelInputMapping(i.id, i.name, i.valueUnwrapped());
    });
    external.output = node.externalProcessProperties.outputMappings.map(i => {
      try {
        return new ProcessNodeExternalProcessViewModelOutputMapping(i.id, i.externalVariableName, VariablePath.parse(i.variableName.getOrElse("")));
      } catch(e) {
        return new ProcessNodeExternalProcessViewModelOutputMapping(i.id, i.externalVariableName, VariablePath.empty());
      }
    });

    external.killSubFlowOnTerminate = node.externalProcessProperties.killOnTerminate;
    external.errorHandling = node.externalProcessProperties.errorHandling;
    return external;
  }
}

export class ProcessBooleanValueViewModel {
  valueType: "fixed"|"variableValue"|"expression" = "fixed";
  fixedValue: boolean = true;  // 1 hour
  variableName: string = "";
  expression: string = "";

  static empty(value: boolean) {
    const viewModel = new ProcessBooleanValueViewModel()
    viewModel.fixedValue = value;
    return viewModel;
  }

  static of(value: ProcessBooleanValue) {
    const viewModel = new ProcessBooleanValueViewModel();
    viewModel.valueType = value.valueType.name;
    viewModel.fixedValue = value.fixedValue;
    viewModel.variableName = value.variableName;
    viewModel.expression = value.expression;
    return viewModel;
  }

  toProcessBooleanValue(): ProcessBooleanValue {
    return new ProcessBooleanValue(ProcessValueType.of(this.valueType), this.fixedValue, this.variableName, this.expression);
  }

  changeValueType(valueType: "fixed" | "variableValue" | "expression") {
    this.valueType = valueType;
  }
}

export class ProcessDurationValueViewModel {
  valueType: "fixed"|"variableValue"|"expression" = "fixed";
  fixedValue: Duration = new Duration(60 * 60, 0);  // 1 hour
  organizationTime: boolean = false;
  variableName: string = "";
  expression: string = "";

  static empty(seconds: number) {
    const viewModel = new ProcessDurationValueViewModel()
    viewModel.fixedValue = new Duration(seconds, 0);
    return viewModel;
  }

  static of(duration: ProcessDurationValue) {
    const viewModel = new ProcessDurationValueViewModel();
    viewModel.valueType = duration.valueType.name;
    viewModel.fixedValue = duration.fixedValue;
    viewModel.organizationTime = duration.organizationTime;
    viewModel.variableName = duration.variableName;
    viewModel.expression = duration.expression;
    return viewModel;
  }

  toProcessDurationValue(): ProcessDurationValue {
    return new ProcessDurationValue(ProcessValueType.of(this.valueType), this.fixedValue, this.organizationTime, this.variableName, this.expression);
  }

  changeValueType(valueType: "fixed"|"variableValue"|"expression") {
    this.valueType = valueType;
  }
}

export class EventViewModel {

  waitExpression: string = "";
  waitCheckActively: boolean = false;
  waitInterval: ProcessDurationValueViewModel = ProcessDurationValueViewModel.empty(60 * 60); // 1 hour

  delayType: "nodeReached"|"caseStarted" = "nodeReached";
  delay: ProcessDurationValueViewModel = ProcessDurationValueViewModel.empty(24 * 60 * 60); // 24 hours

  eventTimeout: ProcessDurationValueViewModel = ProcessDurationValueViewModel.empty(30 * 24 * 60 * 60);  // 30 days

  static empty() {
    return new EventViewModel();
  }

  static of(node: GridProcessNode) {
    const event = new EventViewModel();
    event.waitExpression = node.waitEvent.expression;
    event.waitCheckActively = node.waitEvent.checkActively;
    event.waitInterval = ProcessDurationValueViewModel.of(node.waitEvent.interval);

    event.delayType = node.delayEvent.delayType.name;
    event.delay = ProcessDurationValueViewModel.of(node.delayEvent.delay);

    event.eventTimeout = ProcessDurationValueViewModel.of(node.eventTimeout.timeout);

    return event;
  }

}


export class ProcessNodeViewModel {
  selected: boolean = false;
  disabled: boolean = false;
  cursorDrop: boolean = false;
  dragged: boolean = false;
  textEdited: boolean = false;

  // display values
  x: number = 0;
  y: number = 0;
  width: number = 0;
  height: number = 0;
  labelLines: Array<TextLineViewModel> = []
  icon: string|undefined = undefined;
  iconX: number = 0;
  iconY: number = 0;
  iconSize: number = 0;

  infoIcon: string|undefined = undefined;
  infoIconX: number = 0;
  infoIconY: number = 0;
  infoIconSize: number = 0;

  nodeTypeName: string = "";

  // execution status
  visited: boolean = false;
  realizationDuration: TextLineViewModel|undefined;
  realizationDurationTooltip: string|undefined;
  delay: TextLineViewModel|undefined;
  timestamp: TextLineViewModel|undefined;
  timestampTooltip: string|undefined;
  created: LocalDateTime|undefined;
  completed: LocalDateTime|undefined;
  deadline: LocalDateTime|undefined;
  readonly hasCreatedTimestamp: boolean;
  repetitions: TextLineViewModel|undefined;

  isConditional: boolean = false;

  hasForm: boolean = false;
  canBeParallelOrSequential: boolean = false;
  isWait: boolean = false;
  isDelay: boolean = false;
  isEmailEvent: boolean = false;
  isMessageEvent: boolean = false;
  isAnyEvent: boolean = false;

  canBeTerminating: boolean = false;

  hasAutomaticStartup: boolean = false;
  hasIframeStartup: boolean = false;

  hasStartLabel: boolean = false;
  hasActions: boolean = false;
  hasExternal: boolean = false;
  hasBeforeActions: boolean = false;
  hasAfterActions: boolean = false;
  interface: SharedServiceViewModel|undefined;
  hasDelay: boolean = false;
  hasWait: boolean = false;


  constructor(
    readonly modelEventBus: ProcessMapEditorModelEventBus|undefined, // undefined in preview
    readonly id: ProcessNodeId,
    public nodeType: NodeType,
    public mode: NodeMode,
    public gridX: number,
    public gridY: number,
    public label: I18nText,
    public startLabel: I18nText,
    public description: I18nText,
    public instruction: I18nText,
    public identifier: string,
    public metaData: ProcessNodeMetadataViewModel,
    readonly beforeActions: Array<AutomaticActionRef>,
    readonly afterActions: Array<AutomaticActionRef>,
    readonly condition: ProcessNodeConditionViewModel|undefined,
    readonly screenComponentRef: ScreenComponentRefIdentifier|undefined,
    public terminating: boolean,
    readonly startup: StartupViewModel,
    readonly event: EventViewModel,
    readonly externalProcess: ProcessNodeExternalProcessViewModel,
    readonly legacy: LegacyProcessNodeViewModel,
 ) {
    this.nodeTypeName = nodeType.name;
    this.hasCreatedTimestamp = nodeType.isAnyStart() || nodeType.isAnyAction() || nodeType.isExternal() || nodeType.isExternalFinish();
  }

  gridXY() {
    return new GridXY(this.gridX, this.gridY);
  }

  requiredCondition() {
    return required(this.condition, "condition");
  }

  isAutomatic() {
    return this.nodeType.isStartAutomatic() || this.nodeType.isActionAutomatic();
  }


  maxActionRefId() {
    return Math.max(
      ___(this.beforeActions).map(a => a.id.id).maxOrZero(),
      ___(this.afterActions).map(a => a.id.id).maxOrZero()
    );
  }


  inputParameterAdded(param: ParameterViewModel) {
    required(this.modelEventBus, "modelEventBus").uiEventHappened(new NodeStartTriggerInputParameterAdded(this.id, param.id, param.name, param.valueType, param.requiredParam, param.defaultValue, false));
  }

  inputParameterDeleted(paramId: number) {
    required(this.modelEventBus, "modelEventBus").uiEventHappened(new NodeStartTriggerInputParameterDeleted(this.id, paramId, false));
  }

  inputParameterChanged(param: ParameterViewModel) {
    required(this.modelEventBus, "modelEventBus").uiEventHappened(new NodeStartTriggerInputParameterChanged(this.id, param.id, param.name, param.valueType, param.requiredParam, param.defaultValue, false));
  }

  outputParameterAdded(param: ParameterViewModel) {
    required(this.modelEventBus, "modelEventBus").uiEventHappened(new NodeStartTriggerOutputParameterAdded(this.id, param.id, param.name, param.valueType, false));
  }

  outputParameterDeleted(paramId: number) {
    required(this.modelEventBus, "modelEventBus").uiEventHappened(new NodeStartTriggerOutputParameterDeleted(this.id, paramId, false));
  }

  outputParameterChanged(param: ParameterViewModel) {
    required(this.modelEventBus, "modelEventBus").uiEventHappened(new NodeStartTriggerOutputParameterChanged(this.id, param.id, param.name, param.valueType, false));
  }
}


export class TempEdgeViewModel {
  pathD: string = "";
  endX: number = 0;
  endY: number = 0;
  endR = 0;
  endTranslate: string = "";
  endRotate: string = "";
  alternative: boolean;
  constructor(readonly id: ProcessEdgeId,
              readonly edgeType: EdgeType,
              public path: GridXYPath,
              readonly endHeight: number,
              readonly endWidth: number) {
    this.alternative = edgeType.isAnyAlternative();
  }
}

export class ProcessEdgeViewModel {
  selected: boolean = false;
  disabled: boolean = false;
  dragged: boolean = false;

  // display values
  pathD: string = "";

  alternative: boolean = false;
  otherAlternative: boolean = false;
  textEdited: boolean = false;

  labelPlaceholder: boolean = false;

  drawPriority: number = 0;
  delayHoursInDay: 8|24 = 24;


  constructor(
    readonly id: ProcessEdgeId,
    public fromNodeId: ProcessNodeId,
    public toNodeId: ProcessNodeId,
    public path: GridXYPath,
    public edgeType: EdgeType,
    public name: I18nText,
    public terminating: boolean,
    public outEnabled: ProcessBooleanValueViewModel,
    public inEnabled: ProcessBooleanValueViewModel,
    public waitEventExpression: string,
    public waitEventCheckActively: boolean,
    public waitEventInterval: ProcessDurationValueViewModel,
    public delayEventType: "nodeReached"|"caseStarted" = "nodeReached",
    public delayEvent: ProcessDurationValueViewModel,
    public rolesAllowed: Array<ProcessRoleId>,
    public delay: boolean, // Deprecated
    public delayMethod: EdgeDelayMethod, // Deprecated
    public delayDuration: Duration|null, // Deprecated
    public delayExpression: string, // Deprecated
    public delayBasedOnOrganizationTime: boolean, // Deprecated
    readonly start: ProcessEdgeStartViewModel,
    readonly end: ProcessEdgeEndViewModel,
    readonly label: ProcessEdgeLabelViewModel,
    readonly labelBackground: ProcessEdgeLabelBackgroundViewModel) {

    this.delayHoursInDay = delayBasedOnOrganizationTime ? 8 : 24;

  }
}


export class RowsHeaderViewModel {
  constructor(
    public y: number) {}
}

export class TextLineViewModel {
  constructor(
    readonly x: number,
    readonly y: number,
    readonly dx: number,
    readonly dy: number,
    readonly width: number,
    readonly height: number,
    readonly text: string) {}

  updateY(y: number) {
    return new TextLineViewModel(this.x, y, this.dx, this.dy, this.width, this.height, this.text);
  }
}

export class ProcessRoleViewModel {
  selected: boolean = false;
  textEdited: boolean = false;

  public roleNameLines: Array<TextLineViewModel> = [];
  public personNameLines: Array<TextLineViewModel> = [];


  public width: number = 0;
  public height: number = 0;
  public x: number = 0;
  public y: number = 0;

  readonly hasPhasesDefinitionIcon = MyIcons.MI_SECTION_HORIZONTAL;
  public hasPhasesDefinitionIconX = 0;
  public hasPhasesDefinitionIconY = 0;

  public icon: string|undefined;
  public iconX: number = 0;
  public iconY = 0;
  public avatarBlob: string | undefined;
  public avatarX= 0;
  public avatarY= 0;
  public avatarWidth= 0;
  public avatarHeight= 0;
  public avatarR= 0;
  public initials: string | undefined;
  public initialsX= 0;
  public initialsY= 0;
  public personName: string = "";
  public strokeDasharray: string = "";

  public hasPhasesDefinition: boolean = false;

  constructor(
    public id: ProcessRoleId,
    public gridY: number,
    public rows: number,
    public roleName: I18nText,
    public identifier: string,
    public description: I18nText = I18nText.empty(),
    public nodeAssignment: NodeAssignment = NodeAssignment.role,
    public taskDistribution: TaskDistribution = TaskDistribution.selfAssign,
    public roleMembersCanChangeTaskAssignment: Trilean = Trilean.FALSE,
    public roleMembersCanChangeTaskAssignmentExpression: string = "",
    public incompatibleRoles: Array<number> = [],
    public tasksVisibility: TasksVisibility = TasksVisibility.assignedOrInQueue,
    public assigneeLimit: number = 1,
    public canChangeCaseImportance: Trilean = Trilean.TRUE,
    public canChangeCaseImportanceExpression: string = "",
    public canChangeCaseUrgency: Trilean = Trilean.TRUE,
    public canChangeCaseUrgencyExpression: string = "",
    public canChangeCaseLabels: Trilean = Trilean.TRUE,
    public canChangeCaseLabelsExpression: string = "",
    public commentsAccess: boolean = true
  ) {}

}

export class ProcessRowHeaderViewModel {
  selected: boolean = false;
  textEdited: boolean = false;

  notHiddenByRole: boolean = false;

  public width: number = 0;
  public height: number = 0;
  public x: number = 0;
  public y: number = 0;

  public icon: string|undefined;
  public iconX: number = 0;
  public iconY = 0;
  strokeDasharray = "";

  constructor(
    readonly gridY: number,
  ) {}
}

export class RolesHeaderViewModel {
  constructor(
    public x: number,
    public width: number,
    public height: number) {}
}

export class ProcessColumnHeaderViewModel {
  selected: boolean = false;
  textEdited: boolean = false;
  constructor(
    readonly gridX: number,
    readonly x: number,
    readonly y: number,
    readonly width: number,
    readonly height: number,
    readonly strokeDasharray: string,
    readonly dx: number,
    readonly dy: number,
    readonly label: Array<TextLineViewModel>) {}
}

export class ProcessPhaseViewModel {

  selected: boolean = false;
  textEdited: boolean = false;

  public phaseNameLines: Array<TextLineViewModel> = [];

  public y: number = 0;
  public width: number = 0;
  public x: number = 0;
  strokeDasharray: string = "";

  public startDragHandleX: number = 0;
  public endDragHandleX: number = 0;
  public dragHandleWidth: number = 10;
  dragged: boolean = false

  roleIdSerialized: string = "";

  constructor(
    readonly roleId: ProcessRoleId|undefined,
    readonly id: ProcessPhaseId,
    public gridX: number,
    public columns: number,
    public phaseName: I18nText,
    public identifier: string) {
    if(roleId !== undefined) {
      this.roleIdSerialized = roleId.toString();
    } else {
      this.roleIdSerialized = "main";
    }
  }

  gridEndX() {
    return this.gridX + this.columns - 1;
  }

  static copy(other: ProcessPhaseViewModel) {
    return new ProcessPhaseViewModel(
      other.roleId,
      other.id,
      other.gridX,
      other.columns,
      I18nText.copy(other.phaseName),
      other.identifier);

  }
}

export class ProcessPhasesDefinitionViewModel {

  additional: boolean;
  textEdited: boolean = false;
  collapsed: boolean = false;

  public x: number = 0;
  public y: number = 0;
  public width: number = 0;
  public height: number = 0;
  public dx: number = 0;
  public dy: number = 0;

  constructor(
    readonly roleId: ProcessRoleId|undefined,
    readonly phases: Array<ProcessPhaseViewModel>) {
    this.additional = roleId !== undefined;
    this.collapsed = this.additional;
  }

  getNextPhaseId() {
    return ___(this.phases).map(p => p.id).maxOrZero() + 1;
  }

  getPhaseById(id: ProcessPhaseId) {
    return required(this.phases.find(p => p.id == id), "phase");
  }

  findPhaseAt(column: number) {
    return this.phases.find(p => column >= p.gridX  && column < p.gridX + p.columns);
  }

  findPhaseAtOrAfter(column: number) {
    const sorted = __(this.phases).sortBy(p => p.gridX);
    return sorted.find(p => p.gridX >= column);
  }

  findPhaseAtOrBefore(column: number) {
    const sorted = __(this.phases).sortBy(p => p.gridX).reverse();
    return sorted.find(p => p.gridX + p.columns - 1 <= column);
  }

  getPhaseAt(column: number) {
    return required(this.findPhaseAt(column), "phase");
  }

  static copy(other: ProcessPhasesDefinitionViewModel) {
    return new ProcessPhasesDefinitionViewModel(
      other.roleId,
      other.phases.map(ProcessPhaseViewModel.copy));
  }

  findPhasesBetween(fromGridX: number, toGridX: number) {
    return __(range(toGridX - fromGridX + 1).map(i => fromGridX + i).map(x => this.findPhaseAt(x)).filter(p => p !== undefined)).unique();
  }
}

export class CornerViewModel {
  constructor(
    public width: number,
    public height: number,
    public strokeDasharray: string,
    public x: number,
    public y: number,
    public labelX: number,
    public labelY: number,
    public labelWidth: number,
    public labelHeight: number,
    public label: Array<TextLineViewModel>) {}
}

export class ProcessEdgeStartViewModel {
  icon: string|undefined;
  iconSize: number = 0;
  iconX: number = 0;
  iconY: number = 0;
  innerR: number = 0;
  clickR = 10;

  constructor(
    readonly edgeId: ProcessEdgeId,
    public cx: number,
    public cy: number,
    public r: number,
    public edgeDefault: boolean) {
    this.innerR = r - 1;
  }

  static empty(edgeId: ProcessEdgeId) {
    return new ProcessEdgeStartViewModel(edgeId, 0, 0, 0, false);
  }
}

export class ProcessEdgeEndViewModel {
  centerX: number;
  centerY: number;
  clickR = 10;
  constructor(
    readonly edgeId: ProcessEdgeId,
    public x: number,
    public y: number,
    readonly width: number,
    readonly height: number,
    readonly r: number,
    public transform: string) {
    this.centerX = x + width / 2;
    this.centerY = y + height / 2;
  }

  static empty(edgeId: ProcessEdgeId, width: number, height: number, r: number) {
    return new ProcessEdgeEndViewModel(edgeId, 0, 0, width, height, r,"");
  }

  updateXY(x: number, y: number) {
    this.x = x;
    this.y = y;
    this.centerX = x + this.width / 2;
    this.centerY = y + this.height / 2;
  }
}

export class ProcessEdgeLabelViewModel {
  constructor(
    public x: number,
    public y: number,
    public transform: string|undefined,
    public text: string) {}

  static empty() {
    return new ProcessEdgeLabelViewModel(0, 0, "", "");
  }
}

export class ProcessEdgeLabelBackgroundViewModel {
  disabled: boolean = false;
  constructor(
    public x: number,
    public y: number,
    readonly rx: number,
    readonly ry: number,
    public width: number,
    public height: number) {}

  static empty(rx: number, ry: number) {
    return new ProcessEdgeLabelBackgroundViewModel(0, 0, rx, ry, 0, 0);
  }
}


export class ProcessNodeTemplateViewModel {
  nodeTypeName: string = "";

  icon: string|undefined = undefined;
  iconX: number = 0;
  iconY: number = 0;
  iconSize: number = 0;
  constructor(readonly nodeType: NodeType,
              readonly width: number,
              readonly height: number) {
    this.nodeTypeName = nodeType.name;
  }
}

export class CommentsGroupViewModel {

  selected: boolean = false;

  charName: string = "";
  cx: number = 0;
  cy: number = 0;
  labelLines: Array<TextLineViewModel> = [];
  dragged: boolean = false;

  constructor(
    readonly id: ProcessCommentsGroupId,
    public gridX: number,
    public gridY: number,
    public shiftX: number,
    public shiftY: number,
    readonly r: number) {}

  calculateCharName() {
    if(this.id <= ProcessMapComments.chars.length) {
      return ProcessMapComments.chars.charAt(this.id - 1);
    } else {
      const a = Math.floor((this.id - 1) / ProcessMapComments.chars.length);
      const b = (this.id - 1) % ProcessMapComments.chars.length;
      return ProcessMapComments.chars.charAt(a - 1) + ProcessMapComments.chars.charAt(b);
    }
  }

  gridShiftXY() {
    return new ShiftXY(this.shiftX, this.shiftY);
  }
}

export class PreviewFormViewModel {

  constructor(readonly id: ProcessPreviewFormId,
              readonly screenRoot: ScreenComponentRefIdentifier,
              public name: I18nText,
              public customAccess: boolean,
              readonly allowedRoles: Array<ProcessRoleId>) {
  }
}


export abstract class ProcessMapCommonViewModel {

  private static emptyHeaderColumns = 10;
  private static minimumHeaderColumns = 8;
  private static minimumRolesRows = 5;
  private static emptyRoleRow = 10;


  protected modelEventBus: ProcessMapEditorModelEventBus|undefined;

  background: BackgroundViewModel = new BackgroundViewModel(0, 0, 0, 0);

  designCanvasTransformations: string = "";

  xyCalculator = new XYCalculator(this.config, this.viewPort);
  edgeXYCalculator: EdgeXYCalculator = new EdgeXYCalculator(this.xyCalculator, this.config);

  nodesTemplates: Array<ProcessNodeTemplateViewModel> = [
    new ProcessNodeTemplateViewModel(NodeType.startForm, this.config.nodeSize.width, this.config.nodeSize.height),
    new ProcessNodeTemplateViewModel(NodeType.startAutomatic, this.config.nodeSize.width, this.config.nodeSize.height),
    new ProcessNodeTemplateViewModel(NodeType.finish, this.config.nodeSize.width, this.config.nodeSize.height),
    new ProcessNodeTemplateViewModel(NodeType.condition, this.config.nodeSize.width, this.config.nodeSize.height),
    new ProcessNodeTemplateViewModel(NodeType.actionForm, this.config.nodeSize.width, this.config.nodeSize.height),
    new ProcessNodeTemplateViewModel(NodeType.actionAutomatic, this.config.nodeSize.width, this.config.nodeSize.height),
    new ProcessNodeTemplateViewModel(NodeType.external, this.config.nodeSize.width, this.config.nodeSize.height),
    new ProcessNodeTemplateViewModel(NodeType.externalFinish, this.config.nodeSize.width, this.config.nodeSize.height),
    new ProcessNodeTemplateViewModel(NodeType.subProcess, this.config.nodeSize.width, this.config.nodeSize.height),
    new ProcessNodeTemplateViewModel(NodeType.wait, this.config.nodeSize.width, this.config.nodeSize.height),
    new ProcessNodeTemplateViewModel(NodeType.emailEvent, this.config.nodeSize.width, this.config.nodeSize.height),
    new ProcessNodeTemplateViewModel(NodeType.messageEvent, this.config.nodeSize.width, this.config.nodeSize.height),
    new ProcessNodeTemplateViewModel(NodeType.delay, this.config.nodeSize.width, this.config.nodeSize.height),
    new ProcessNodeTemplateViewModel(NodeType.annotation, this.config.nodeSize.width, this.config.nodeSize.height),
    new ProcessNodeTemplateViewModel(NodeType.comment, this.config.nodeSize.width, this.config.nodeSize.height),
  ];


  nodeIdGenerator: number = 0;
  roleIdGenerator: number = 0;
  edgeIdGenerator: number = 0;
  previewFormIdGenerator: number = 0;
  annotationIdGenerator: number = 0;
  actionIdGenerator: number = 0;
  actionRefIdGenerator: number = 0;


  actions: Array<AutomaticAction> = [];

  nodes: Array<ProcessNodeViewModel> = [];
  edges: Array<ProcessEdgeViewModel> = [];
  temporaryEdges: Array<TempEdgeViewModel> = [];
  annotations: Array<ProcessAnnotationViewModel> = [];
  comments: Array<CommentsGroupViewModel> = [];

  visitedEdges: Array<ProcessEdgeViewModel> = [];
  roles: Array<ProcessRoleViewModel> = [];
  rowsHeader: Array<ProcessRowHeaderViewModel> = [];
  columnsHeader: Array<ProcessColumnHeaderViewModel> = [];

  additionalPhasesDefinitions: Array<ProcessPhasesDefinitionViewModel> = [];
  allPhasesDefinitions: Array<ProcessPhasesDefinitionViewModel> = [];
  mainPhasesDefinition: ProcessPhasesDefinitionViewModel = new ProcessPhasesDefinitionViewModel(undefined, []);

  previewForms: Array<PreviewFormViewModel> = [];

  columnHeader: RowsHeaderViewModel = new RowsHeaderViewModel(0);
  rolesHeader: RolesHeaderViewModel = new RolesHeaderViewModel(0, 0, 0);

  private rolesLabelText = i18n('process_role_in_process').toUpperCase();

  corner: CornerViewModel = new CornerViewModel(
    0,
    0,
    "",
    0,
    0,
    0,
    0,
    0,
    0,
    []
  );

  protected waitDelayIconSize = 20;
  protected largeIconSize = 25;
  protected smallIconSize = 13;
  protected roleLabelLineHeight = 16;
  protected roleInitialsSize = new Size(20,11);
  protected roleAvatarSize = new Size(32,32);
  protected roleIconSize = new Size(24,24);
  protected roleAvatarRounding = 10;
  protected roleIconLabelMargin = 7;
  protected roleNameLabelMargin = 7;

  maxLegacySectionId: FormSectionId = new FormSectionId(0);
  maxLegacyElementId: FormElementId = new FormElementId(0);
  maxLegacyElementRefId: FormElementRefId = new FormElementRefId(0);
  maxLegacyActionId: AutomaticActionId = new AutomaticActionId(0);


  protected cornerHeaderWrapperConfig = WrapperConfig.create().center().bottom()
    .width(this.config.rolesHeaderWidth - this.config.gridTextMargin * 2)
    .arialFont().fontSize(12).letterSpacing("0.02em");

  protected roleHeaderWrapperConfig = WrapperConfig.create().center().top().horizontalMargin(this.roleIconLabelMargin)
    .width(this.config.rolesHeaderWidth - this.config.gridTextMargin * 2)
    .height(this.config.cellSize.height * 2 / 3 + this.roleIconLabelMargin)
    .arialFont().fontSize(13).letterSpacing("0.02em");

  protected mainPhaseHeaderWrapperConfig = WrapperConfig.create().center().middle()
    .height(this.config.columnHeaderHeight)
    .arialFont().fontSize(13).letterSpacing("0.02em");

  protected additionalPhaseHeaderWrapperConfig = WrapperConfig.create().center().middle()
    .height(this.config.columnHeaderHeight)
    .arialFont().fontSize(13).letterSpacing("0.02em");


  protected columnHeaderWrapperConfig = WrapperConfig.create().center().top()
    .width(this.config.cellSize.width - this.config.gridTextMargin * 2)
    .height(this.config.columnHeaderHeight)
    .arialFont().fontSize(13).letterSpacing("0.02em");

  protected actionNodeConfig = WrapperConfig.create().center().middle()
    .width(this.config.rectangleNodeLabelSize.width - this.config.nodeTextMargin * 2)
    .height(this.config.rectangleNodeLabelSize.height)
    .arialFont().fontSize(13).lineHeight(14).letterSpacing("0.02em")
    .cacheKey("rectangleLabel");

  protected taskTimestampConfig = WrapperConfig.create().center().bottom()
    .width(this.config.rectangleNodeLabelSize.width)
    .height(this.config.rectangleNodeLabelSize.height)
    .arialFont().fontSize(12).letterSpacing("0.02em");


  protected taskDurationConfig = WrapperConfig.create().left().bottom()
    .width(this.config.cellSize.width)
    .height(this.config.cellSize.height)
    .arialFont().fontSize(11).letterSpacing("0.02em");

  protected taskRepetitionsConfig = WrapperConfig.create().right().top()
    .width(this.config.cellSize.width)
    .height(this.config.cellSize.height)
    .arialFont().fontSize(11).letterSpacing("0.02em");

  protected taskDelayConfig = WrapperConfig.create().right().bottom()
    .width(this.config.cellSize.width)
    .height(this.config.cellSize.height)
    .arialFont().fontSize(11).letterSpacing("0.02em");

  protected startFinishNodeConfig = WrapperConfig.create().middle().center()
    .width(this.config.roundNodeLabelSize.width - this.config.nodeTextMargin * 2)
    .height(this.config.roundNodeLabelSize.height)
    .arialFont().lineHeight(14).letterSpacing("0.02em")
    .cacheKey("roundLabel")
    .dynamicWidth((y: number) => {
      const yNormalized = y / this.config.roundNodeLabelSize.height - 0.5;
      return Math.sqrt(0.5 * 0.5 - yNormalized * yNormalized) * this.config.roundNodeLabelSize.width * 2;
    });

  protected signalNodeConfig = WrapperConfig.create().middle().center()
    .width(this.config.signalNodeLabelSize.width - this.config.nodeTextMargin * 2)
    .height(this.config.signalNodeLabelSize.height)
    .arialFont().lineHeight(14).letterSpacing("0.02em")
    .cacheKey("roundLabel")
    .dynamicWidth((y: number) => {
      const yNormalized = y / this.config.signalNodeLabelSize.height - 0.5;
      return Math.sqrt(0.5 * 0.5 - yNormalized * yNormalized) * this.config.signalNodeLabelSize.width * 2;
    });

  protected conditionNodeConfig = WrapperConfig.create().middle().center()
    .width(this.config.diamondNodeLabelSize.width - this.config.nodeTextMargin * 2)
    .height(this.config.diamondNodeLabelSize.height)
    .arialFont().lineHeight(14).letterSpacing("0.02em")
    .cacheKey("diamondLabel")
    .dynamicWidth((y: number) => {
      const yNormalized = y / this.config.diamondNodeLabelSize.height;
      return (1 - Math.abs((1 - yNormalized * 2))) * this.config.diamondNodeLabelSize.width;
    });

  protected annotationConfig = WrapperConfig.create().left().top()
    .splitLinesBy("\n")
    .width(this.config.annotationMaximumSize.width - this.config.annotationTextHorizontalMargin * 2)
    .height(this.config.annotationMaximumSize.height)
    .arialFont().fontSize(13).lineHeight(14).letterSpacing("0.02em")
    .cacheKey("annotation");


  protected commentConfig = WrapperConfig.create().center().middle().singleLine().noDots()
    .width(this.config.commentIndicatorRadius * 2)
    .height(this.config.commentIndicatorRadius * 2)
    .arialFont().fontSize(13).cacheKey("commentLabel");

  gridProcessModel!: GridProcessModel;
  processInfo?: ProcessInfo;
  screenWithRelease: ScreenWithRelease|null = null;
  screenBasedProcess = false;
  formBasedProcess = false;

  private destroyed: boolean = false;
  legacyVariables: LegacyVariablesViewModel = new LegacyVariablesViewModel([]);

  constructor(readonly config: DesignMapConfig,
              readonly canvasModel: EditorCanvasModel,
              readonly viewPort: DesignCanvasViewPort,
              readonly i18nService: I18nService,
              readonly gravatarService: GravatarService) {
    this.updateNodeTemplatesDisplayValues();
  }


  getScale() {
    return this.viewPort.scale;
  }

  nextLegacySectionId() {
    const next = new FormSectionId(this.maxLegacySectionId.id + 1);
    this.maxLegacySectionId = next;
    return next;
  }
  nextLegacyElementId() {
    const next = new FormElementId(this.maxLegacyElementId.id + 1);
    this.maxLegacyElementId = next;
    return next;
  }
  nextLegacyElementRefId() {
    const next = new FormElementRefId(this.maxLegacyElementRefId.id + 1);
    this.maxLegacyElementRefId = next;
    return next;
  }
  nextLegacyActionId() {
    const next = new AutomaticActionId(this.maxLegacyActionId.id + 1);
    this.maxLegacyActionId = next;
    return next;
  }

  protected redraw(gridProcessModel: GridProcessModel, force: boolean = false) {
    const updateOnly = !force && this.gridProcessModel === gridProcessModel;

    this.additionalPhasesDefinitions = [];

    this.updateGenerators(gridProcessModel);

    this.updateActions(updateOnly);
    this.updateEdges(updateOnly);
    this.updateNodes(updateOnly);
    if(!updateOnly) {
      this.updateEdgesEndsAndLabels(this.edges);
    }
    this.updateAnnotations(updateOnly);
    this.updateComments(updateOnly);
    this.updatePreviewForms(updateOnly);


    if(!updateOnly) {
      this.updateBackground();
      this.updateColumnsHeader();
      this.updateCornerElement();
    }

    this.updateRoles(updateOnly);
    this.updateRowsHeader(updateOnly);// must be after roles
    this.updatePhasesDefinitions(updateOnly);// must be after roles
  }

  initModel(gridProcessModel: GridProcessModel, processInfo: ProcessInfo|undefined, screenWithRelease: ScreenWithRelease|null) {
    try {
      this.gridProcessModel = gridProcessModel;
      this.processInfo = processInfo;
      this.screenWithRelease = screenWithRelease;

      this.screenBasedProcess = screenWithRelease !== null;
      this.formBasedProcess = screenWithRelease === null;

      this.legacyVariables = LegacyProcessNodeViewModel.createVariables(gridProcessModel);

      this.designCanvasTransformations =  "translate("+ this.viewPort.position.x + " " + this.viewPort.position.y + ")" +
        " scale("+this.viewPort.scale+")";

      this.canvasModel.changeModel(gridProcessModel);

      this.updateGenerators(gridProcessModel);

      this.additionalPhasesDefinitions = [];

      this.maxLegacySectionId = gridProcessModel.maxSectionId();
      this.maxLegacyElementId = gridProcessModel.maxFormElementId();
      this.maxLegacyElementRefId = gridProcessModel.maxFormElementRefId();
      this.maxLegacyActionId = new AutomaticActionId(__(gridProcessModel.actionsUnwrapped()).maxOfOrZero(a => a.id.id));


      this.updateBackground();
      this.updateAnnotations();
      this.updateComments();
      this.updateActions();
      this.updateEdges();
      this.updateNodes();
      this.updateEdgesEndsAndLabels(this.edges);
      this.updatePreviewForms();


      this.updateRoles();
      this.updateRowsHeader(); // must be after roles
      this.updatePhasesDefinitions(); // must be after roles

      this.updateColumnsHeader();
      this.updateCornerElement();
    } catch(e) {
      // this is catched so only editor will not work, but rest of the screen will, so e.g. version revert will be possible
      console.error("Cannot initialize model", e);
      toastr.error("Cannot initialize model " + e);
    }
  }

  private updateGenerators(gridProcessModel: GridProcessModel) {
    this.nodeIdGenerator = Math.max(this.nodeIdGenerator, gridProcessModel.nodeIdGenerator);
    this.roleIdGenerator = Math.max(this.roleIdGenerator, gridProcessModel.roleIdGenerator);
    this.edgeIdGenerator = Math.max(this.edgeIdGenerator, gridProcessModel.edgeIdGenerator);
    this.previewFormIdGenerator = Math.max(this.previewFormIdGenerator, gridProcessModel.previewFormIdGenerator);
    this.annotationIdGenerator = Math.max(this.annotationIdGenerator, gridProcessModel.annotationIdGenerator);
    this.actionIdGenerator = Math.max(this.actionIdGenerator, gridProcessModel.actionIdGenerator);
    this.actionRefIdGenerator = Math.max(this.actionRefIdGenerator, gridProcessModel.actionRefIdGenerator);
  }

  private updateBackground() {
    this.background.x = this.viewPort.position.x;
    this.background.y = this.viewPort.position.y;
    this.background.width = this.canvasModel.gridWidth * this.config.cellSize.width * this.viewPort.scale;
    this.background.height = this.canvasModel.gridHeight * this.config.cellSize.height * this.viewPort.scale;
  }

  nextNodeId() {
    this.nodeIdGenerator++;
    return this.nodeIdGenerator;
  }

  nextRoleId() {
    this.roleIdGenerator++;
    return this.roleIdGenerator;
  }

  nextEdgeId() {
    this.edgeIdGenerator++;
    return this.edgeIdGenerator;
  }

  nextPreviewFormId() {
    this.previewFormIdGenerator++;
    return this.previewFormIdGenerator;
  }

  nextAnnotationId() {
    this.annotationIdGenerator++;
    return this.annotationIdGenerator;
  }

  nextCommentId() {
    return ___(this.comments).map(n => n.id).maxOrZero() + 1;
  }

  nextActionId() {
    this.actionIdGenerator++;
    return new AutomaticActionId(this.actionIdGenerator);
  }

  nextActionRefId() {
    const fromNodes = ___(this.nodes).map(n => n.maxActionRefId()).maxOrZero();
    this.actionRefIdGenerator = Math.max(fromNodes, this.actionRefIdGenerator) + 1;
    return new AutomaticActionRefId(this.actionRefIdGenerator);
  }

  protected updateComments(updateOnly: boolean = false) {
    if(!updateOnly) {
      this.comments = [];
    }

    if(this.processInfo) {
      this.processInfo.comments.forEach(comment => {

        let c: CommentsGroupViewModel | undefined = this.comments.find(c => c.id == comment.id);

        if (!c) {
          c = new CommentsGroupViewModel(
            comment.id,
            comment.gridXY.gridX,
            comment.gridXY.gridY,
            comment.xShift,
            comment.yShift,
            this.config.commentIndicatorRadius);

          this.comments.push(c);
        }
        this.updateCommentDisplayValues(c);
      });
    } // otherwise it's flow preview

  }

  protected updateActions(updateOnly: boolean = false) {

    if(!updateOnly) {
      this.actions = this.gridProcessModel.actionsUnwrapped();
    }


    this.actionIdGenerator = Math.max(this.actionIdGenerator, __(this.actions.map(e => e.id)).maxOrZero());

  }

  protected updateAnnotations(updateOnly: boolean = false) {

    if(!updateOnly) {
      this.annotations = [];
    }

    this.gridProcessModel.annotations.forEach(annotation => {

      let a: ProcessAnnotationViewModel|undefined = this.annotations.find(n => n.id == annotation.id);
      if(!a) {
        a = new ProcessAnnotationViewModel(
          annotation.id,
          annotation.gridXY.gridX,
          annotation.gridXY.gridY,
          annotation.gridShiftXY.x,
          annotation.gridShiftXY.y,
          annotation.text,
          annotation.nodes,
          annotation.edges,
        );

        this.annotations.push(a);
      }

      this.updateAnnotationDisplayValues(a);

    });

    this.annotationIdGenerator = Math.max(this.annotationIdGenerator, __(this.annotations.map(e => e.id)).maxOrZero());

  }

  protected updatePhasesDefinitions(updateOnly: boolean = false) {
    if(!updateOnly) {
      this.additionalPhasesDefinitions = [];
    }

    this.gridProcessModel.phasesDefinitions.forEach(phasesDefinition => {

      let pd: ProcessPhasesDefinitionViewModel|undefined = this.additionalPhasesDefinitions.find(n => n.roleId === phasesDefinition.roleId.getOrUndefined());

      if(!pd && phasesDefinition.roleId.isEmpty()) {
        pd = this.mainPhasesDefinition;
      }

      if(!pd) {
        pd = new ProcessPhasesDefinitionViewModel(
          phasesDefinition.roleId.getOrUndefined(),
          phasesDefinition.phases.map(p => new ProcessPhaseViewModel(
            phasesDefinition.roleId.getOrUndefined(),
            p.id,
            p.gridX,
            p.columns,
            p.name,
            p.identifier
          ))
        );

        if(pd.roleId !== undefined) {
          this.additionalPhasesDefinitions.push(pd);
        }
      } else {
        this.updatePhaseDefinitionPhases(pd, phasesDefinition);
      }
    });

    this.updatePhasesDefinitionsDisplayValues();
  }

  private updatePhaseDefinitionPhases(pd: ProcessPhasesDefinitionViewModel, phasesDefinition: GridProcessPhasesDefinition) {

    removeFromArrayBy(pd.phases, p => !phasesDefinition.phases.some(p2 => p2.id == p.id));

    phasesDefinition.phases.forEach(phase => {

      let p = pd.phases.find(p => p.id == phase.id);

      if(p) {
        p.gridX = phase.gridX;
        p.columns = phase.columns;
        p.phaseName = phase.name;
        p.identifier = phase.identifier;
      } else {
        p = new ProcessPhaseViewModel(
          pd.roleId,
          phase.id,
          phase.gridX,
          phase.columns,
          phase.name,
          phase.identifier
        );
        pd.phases.push(p);
      }

    });

  }


  protected updateNodes(updateOnly: boolean = false) {

    if(!updateOnly) {
      this.nodes = [];
    }

    this.gridProcessModel.nodes.forEach(node => {

      let n: ProcessNodeViewModel|undefined = this.nodes.find(n => n.id == node.id);
      if(!n) {
        n = new ProcessNodeViewModel(
           this.modelEventBus,
           node.id,
           node.nodeType,
           NodeMode.normal,
           node.gridXY.gridX,
           node.gridXY.gridY,
           node.name,
           node.startLabel,
           node.description,
           node.instruction,
           node.identifier.getOrElse(""),
           ProcessNodeMetadataViewModel.of(node.metadata),
           node.automaticActions.before.slice(),
           node.automaticActions.after.slice(),
          node.nodeType.isCondition() ? this.toConditionalViewModel(node.id, node.conditionProperties, this.gridProcessModel.edges) : undefined,
           node.form.screenComponentRef.getOrUndefined(),
          node.endProperties.terminate,
          StartupViewModel.of(node),
          EventViewModel.of(node),
          ProcessNodeExternalProcessViewModel.of(node),
          LegacyProcessNodeViewModel.of(node, this.gridProcessModel, this.modelEventBus, this)
        );

        this.nodes.push(n);
      }


      this.updateNodeInfo(node, n);
    });

    this.nodes.forEach(node => {
      this.updateNodeDisplayValues(node);
    });

    this.nodeIdGenerator = Math.max(this.nodeIdGenerator, __(this.nodes.map(e => e.id)).maxOrZero());

  }


  private toConditionalViewModel(nodeId: ProcessNodeId, condition: ConditionProperties, edges: Array<GridProcessEdge>): ProcessNodeConditionViewModel {
    return new ProcessNodeConditionViewModel(
      condition.conditions
        .filter(c => edges.find(e => e.id == c.edgeId && e.fromNodeId === nodeId)) // do not show edges that were deleted
        .map(c => {
          return new EdgeConditionViewModel(c.edgeId, new ConditionRuleViewModel(c.operationType, this.toLogicRuleViewModel(c.logicRule), c.expressionRule));
        }),
      condition.defaultEdge.getOrUndefined(),
      condition.conditionType.getOrUndefined(),
      condition.openType.getOrUndefined(),
      condition.closeParallelType.getOrUndefined())
  }

  private toLogicRuleViewModel(logicRule: LogicRule): LogicRuleViewModel {
    if(logicRule.isOperation) {
      const operation = logicRule.operation.getOrError("Expressions required");
      return new LogicRuleViewModel("operation", this.toLogicOperationViewModel(operation), [], "and");
    } else {
      return new LogicRuleViewModel("complex", undefined,
        logicRule.subRules.map(s => this.toLogicRuleViewModel(s)), logicRule.operator.toOperator());
    }

  }

  private toLogicOperationViewModel(operation: LogicOperation): LogicOperationViewModel {
    return new LogicOperationViewModel(operation.leftUnwrapped().map(this.toLogicOperationValueViewModel).getOrUndefined(), operation.operator.toSimpleOperator(), operation.rightUnwrapped().map(this.toLogicOperationValueViewModel).getOrUndefined());
  }

  private toLogicOperationValueViewModel(value: LogicOperationValue): LogicOperationValueViewModel {
    if(value instanceof StringValue) {
      return new LogicOperationValueViewModel("string", value.value);
    } else if(value instanceof NumberValue) {
      return new LogicOperationValueViewModel("number", "", value.value.getOrNull());
    } else if(value instanceof BooleanValue) {
      return new LogicOperationValueViewModel("boolean", "", null, value.value);
    } else if(value instanceof VariableValue) {
      return new LogicOperationValueViewModel("variable", value.value.path);
    } else if(value instanceof ExpressionValue) {
      return new LogicOperationValueViewModel("expression", value.value);
    } else {
      throw new Error("Unknown value type");
    }
  }


  protected updateCommentDisplayValues(comment: CommentsGroupViewModel): void {
    const gridXY = new GridXY(comment.gridX, comment.gridY);
    comment.charName = comment.calculateCharName();
    const position = this.xyCalculator.gridXYToCellPosition(gridXY.shiftXY(comment.shiftX, comment.shiftY));
    comment.cx = position.x;
    comment.cy = position.y;

    const textX = position.x - this.config.commentIndicatorRadius;
    const textY = position.y - this.config.commentIndicatorRadius;

    let textLines = wrapSingleStringText(comment.charName, this.commentConfig);

    comment.labelLines = this.textLinesToText(textX - 1, textY - 1, textLines); //minus 1 to center the text (manual adjustment)
  }

  protected updateAnnotationDisplayValues(annotation: ProcessAnnotationViewModel) {
    const position = this.xyCalculator.gridXYToNodePosition(annotation.gridXY().shiftXY(annotation.shiftX, annotation.shiftY));
    let textLines = wrapSingleStringText(annotation.text.getCurrentWithFallback(), this.annotationConfig);

    annotation.x = position.x;
    annotation.y = position.y;
    annotation.textLines = this.textLinesToText(position.x + this.config.annotationTextHorizontalMargin, position.y + this.config.annotationTextVerticalMargin, textLines);
    annotation.width = this.config.annotationMinimumSize.width;
    annotation.height = this.config.annotationMinimumSize.height;

    myRequestAnimationFrame(() => {
      this.updateAnnotationBackgroundSize(annotation);

      const lines: Array<ProcessAnnotationLineViewModel> = [];
      annotation.nodes.forEach(nodeId => {
        const node = this.getNodeById(nodeId);
        lines.push(new ProcessAnnotationLineViewModel(
          node.x + this.config.cellSize.width / 2,
          node.y + this.config.cellSize.height / 2,
          position.x + annotation.width / 2,
          position.y + annotation.height / 2));
      });
      annotation.lines = lines;
    });
  }

  protected updateNodeDisplayValues(node: ProcessNodeViewModel) {

    const gridXY = new GridXY(node.gridX, node.gridY);
    const nodePosition = this.xyCalculator.gridXYToNodePosition(gridXY);
    const x = nodePosition.x;
    const y = nodePosition.y;
    let nodeLabel = node.label.getCurrentWithFallback();
    let wrapperConfig: WrapperConfig|undefined;
    let textX: number = 0;
    let textY: number = 0;

    let icon: string | undefined = undefined;
    let infoIcon: string | undefined = undefined;


    if (node.nodeType.isAnyActionRectangle()) {
      wrapperConfig = this.actionNodeConfig
      textX = this.config.nodeSize.width / 2 - this.config.rectangleNodeLabelSize.width / 2 + this.config.nodeTextMargin;
      textY = this.config.nodeSize.height / 2 - this.config.rectangleNodeLabelSize.height / 2 - 3; // -3 to compensate font position in line

      if(node.mode.isSequential()) {
        infoIcon = MyIcons.MI_SEQUENTIAL;
      } else if(node.mode.isParallel()) {
        infoIcon = MyIcons.MI_PARALLEL;
      }
      if(node.nodeType.isSubProcess()) {
        icon = MyIcons.MI_PROCESS;
      } else if(node.nodeType.isExternal()) {
        icon = MyIcons.MI_INCOMING;
      } else if(node.nodeType.isAnyForm()) {
        icon = undefined; // no icon for default node
      } else if(node.nodeType.isAnyAutomatic()) {
        icon = MyIcons.MI_CALCULATOR;
      }
    } else if (node.nodeType.isAnyStartOrFinishCircle()) {
      wrapperConfig = this.startFinishNodeConfig
      textX = this.config.nodeSize.width / 2 - this.config.roundNodeLabelSize.width / 2 + this.config.nodeTextMargin;
      textY = this.config.nodeSize.height / 2 - this.config.roundNodeLabelSize.height / 2 - 3; // -3 to compensate font position in line

      if(node.nodeType.isAnyForm()) {
        icon = undefined; // no icon for default node
      } else if(node.nodeType.isAnyAutomatic()) {
        icon = MyIcons.MI_CALCULATOR;
      }

    } else if (node.nodeType.isAnyEvent()) {
      wrapperConfig = this.signalNodeConfig
      textX = this.config.nodeSize.width / 2 - this.config.signalNodeLabelSize.width / 2 + this.config.nodeTextMargin;
      textY = this.config.nodeSize.height / 2 - this.config.signalNodeLabelSize.height / 2 - 3; // -3 to compensate font position in line
      if(node.nodeType.isDelay()) {
        icon = MyIcons.MI_CLOCK;
      } else if(node.nodeType.isWait()) {
        icon = MyIcons.MI_CHECK_CIRCLE;
      } else if(node.nodeType.isEmailEvent()) {
        icon = MyIcons.MI_MAIL;
      } else if(node.nodeType.isMessageEvent()) {
        icon = MyIcons.MI_PAPER_PLANE;
      }

    } else if (node.nodeType.isCondition()) {
      const condition = node.requiredCondition();
      wrapperConfig = this.conditionNodeConfig
      textX = this.config.nodeSize.width / 2 - this.config.diamondNodeLabelSize.width / 2 + this.config.nodeTextMargin;
      textY = this.config.nodeSize.height / 2 - this.config.diamondNodeLabelSize.height / 2 - 3; // -3 to compensate font position in line

      if (Option.of(condition.openType).exists(t => t.isSimple())) {
        icon = MyIcons.MI_CIRCLE_CROSS;
      } else if (Option.of(condition.openType).exists(t => t.isParallelAll())) {
        icon = MyIcons.MI_CIRCLE_PLUS;
      } else if (Option.of(condition.openType).exists(t => t.isParallelAccepted())) {
        icon = MyIcons.MI_CIRCLE_CIRCLE;
      } else if (Option.of(condition.openType).exists(t => t.isParallelAdvanced())) {
        icon = MyIcons.MI_CIRCLE_CIRCLE; // seems not to be used
      } else if (Option.of(condition.closeParallelType).exists(t => t.isFirst())) {
        icon = MyIcons.MI_CIRCLE_CROSS;
      } else if (Option.of(condition.closeParallelType).exists(t => t.isAll())) {
        icon = MyIcons.MI_CIRCLE_PLUS;
      } else if (Option.of(condition.closeParallelType).exists(t => t.isFirst())) {
        icon = MyIcons.MI_CIRCLE_CIRCLE;
      }
    } else {
      throw new Error("Unknown node type: " + node.nodeType.name);
    }

    let textLines = wrapperConfig ? wrapSingleStringText(nodeLabel, wrapperConfig) : [];


    node.infoIcon = infoIcon;
    if(infoIcon) {
      node.infoIconX = this.config.nodeSize.width - 40;
      node.infoIconY = 43;
      node.infoIconSize = this.smallIconSize;
    }

    node.x = x;
    node.y = y;
    node.width = this.config.nodeSize.width;
    node.height = this.config.nodeSize.height;
    node.icon = icon;
    node.labelLines = this.textLinesToText(textX, textY, textLines);
    if(node.labelLines.length === 0 && (node.nodeType.isCondition() || node.nodeType.isAnyEvent())) {
      node.iconX = this.config.nodeSize.width / 2 - this.largeIconSize / 2;
      node.iconY = this.config.nodeSize.height / 2 + this.largeIconSize / 2;
      node.iconSize = this.largeIconSize;
    } else if(node.nodeType.isAnyEvent()) {
      node.iconX = this.config.nodeSize.width / 2 - this.smallIconSize / 2;
      node.iconY = this.config.nodeSize.height - 35;
      node.iconSize = this.smallIconSize;

      const textBottom = this.getTextBottom(node.labelLines, 0);
      if(node.icon && textBottom + this.smallIconSize + 5 > node.iconY) {
        node.labelLines = node.labelLines.map(l => l.updateY(l.y - 5));
      }
    } else if(node.nodeType.isAnyStartOrFinishCircle()) {
      node.iconX = this.config.nodeSize.width / 2 - this.smallIconSize / 2;
      node.iconY = this.config.nodeSize.height - 33;
      node.iconSize = this.smallIconSize;

      const textBottom = this.getTextBottom(node.labelLines, 0);
      if(node.icon && textBottom + this.smallIconSize + 5 > node.iconY) {
        node.labelLines = node.labelLines.map(l => l.updateY(l.y - 5));
      }
    } else if(node.nodeType.isAnyActionRectangle()) {
      node.iconX = this.config.nodeSize.width - 40;
      node.iconY = this.config.nodeSize.height - 33;
      node.iconSize = this.smallIconSize;

      const textBottom = this.getTextBottom(node.labelLines, 0);
      if(node.icon && textBottom + this.smallIconSize + 5 > node.iconY) {
        node.labelLines = node.labelLines.map(l => l.updateY(l.y - 5));
      }
    } else if(node.nodeType.isCondition()) {
      node.iconX = this.config.nodeSize.width / 2 - this.smallIconSize / 2;
      node.iconY = this.config.nodeSize.height - 35;
      node.iconSize = this.smallIconSize;

    }

    node.isWait = node.nodeType.isWait();
    node.isDelay = node.nodeType.isDelay();
    node.isEmailEvent = node.nodeType.isEmailEvent();
    node.isMessageEvent = node.nodeType.isMessageEvent();
    node.isAnyEvent = node.isWait || node.isDelay || node.isEmailEvent || node.isMessageEvent;
    node.hasForm = node.nodeType.isAnyForm() || node.legacy.sections.length > 0; //  || node.beforeActions.length > 0 is for handling legacy process

    node.isConditional = node.nodeType.isCondition();
    node.canBeTerminating = node.nodeType.isFinish() || node.nodeType.isExternalFinish();
    node.canBeParallelOrSequential = node.nodeType.isActionForm() || node.nodeType.isActionAutomatic() || node.nodeType.isSubProcess() || node.nodeType.isExternal();

    node.hasAutomaticStartup = node.nodeType.isStartAutomatic();
    node.hasIframeStartup = node.nodeType.isStartForm();
    node.hasActions = node.nodeType.isActionAutomatic();
    node.hasExternal = node.nodeType.isExternal() || node.nodeType.isExternalFinish();
    node.hasBeforeActions = !node.hasActions && (node.nodeType.isActionForm() || node.nodeType.isStartForm() || node.nodeType.isFinish() || node.beforeActions.length > 0); //  || node.beforeActions.length > 0 is for handling legacy process
    node.hasAfterActions = node.nodeType.isActionForm() || node.nodeType.isStartForm() || node.nodeType.isStartAutomatic() || node.afterActions.length > 0;//  || node.afterActions.length > 0 is for handling legacy process

    node.hasStartLabel = node.nodeType.isStartForm();

    node.hasDelay = node.nodeType.isDelay();
    node.hasWait = node.nodeType.isWait();

    if(node.condition) {
      this.updateNodeConditionDisplayValues(node.condition, node);
    }

  }


  protected updateNodeConditionDisplayValues(condition: ProcessNodeConditionViewModel, node: ProcessNodeViewModel) {
    // condition type not yet specified by edges
    condition.conditionTypeSelectorVisible = this.gridProcessModel.findInEdgesForNode(node.id).length <= 1 && this.gridProcessModel.findOutEdgesForNode(node.id).length <= 1;

    condition.isOpen = condition.conditionType !== undefined && condition.conditionType.isOpen();
    condition.isCloseParallel = condition.conditionType !== undefined && condition.conditionType.isCloseParallel();


    condition.isOpenSimple = condition.isOpen && condition.openType !== undefined && condition.openType.isSimple();
    condition.isOpenParallelAll = condition.isOpen && condition.openType !== undefined && condition.openType.isParallelAll();
    condition.isOpenParallelAccepted = condition.isOpen && condition.openType !== undefined && condition.openType.isParallelAccepted();

    condition.isCloseParallelAll = condition.isCloseParallel && condition.closeParallelType !== undefined && condition.closeParallelType.isAll();
    condition.isCloseParallelFirst = condition.isCloseParallel && condition.closeParallelType !== undefined && condition.closeParallelType.isFirst();

    condition.conditions.forEach(c => {
      const edge = this.findEdgeById(c.edgeId);
      if(edge) {
        c.edge = edge;
        c.nextNode = this.getNodeById(c.edge.toNodeId);
      } else {
        // ignore as edge was removed
      }

    });

  }

  protected updateEdgeDisplayValues(edge: ProcessEdgeViewModel) {
    edge.pathD = this.calculateEdgePath(edge.path);

    edge.alternative = edge.edgeType.isAnyAlternative();
    edge.otherAlternative = edge.edgeType.isAnyDelayAlternative();
  }


  protected updateNodeInfo(node: GridProcessNode, n: ProcessNodeViewModel) {
    // override in child class if needed
  }

  protected updatePreviewForms(updateOnly: boolean = false) {

    if(!updateOnly) {
      this.previewForms = this.gridProcessModel.casePreview.map(d => {
        return new PreviewFormViewModel(
          d.id,
          d.screenComponentRef,
          d.name,
          d.customAccess,
          d.rolesAllowed
        );
      });


    }

    this.previewFormIdGenerator = Math.max(this.previewFormIdGenerator, __(this.previewForms.map(e => e.id)).maxOrZero());
  }

  protected updateEdges(updateOnly: boolean = false) {

    if(!updateOnly) {
      this.edges = this.gridProcessModel.edges.map(e => {
        const delay = e.properties.unwrappedOptionEdgeDelay();
        return new ProcessEdgeViewModel(
          e.id,
          e.fromNodeId,
          e.toNodeId,
          e.edgePath,
          this.fixEdgeType(e.edgeType),
          e.name,
          e.terminating,
          ProcessBooleanValueViewModel.of(e.outEnabled),
          ProcessBooleanValueViewModel.of(e.inEnabled),
          e.waitEvent.expression, e.waitEvent.checkActively, ProcessDurationValueViewModel.of(e.waitEvent.interval),
          e.delayEvent.delayType.name, ProcessDurationValueViewModel.of(e.delayEvent.delay),
          e.rolesAllowed,
          delay.isDefined(),
          delay.map(d => d.getMethod()).getOrElse("ActionDelay"),
          delay.map(d => d.getDuration()).getOrNull(),
          delay.map(d => d.getExpression()).getOrElse(""),
          delay.map(d => d.useOrganizationCalendar).getOrElse(false),
          ProcessEdgeStartViewModel.empty(e.id),
          ProcessEdgeEndViewModel.empty(e.id, this.config.edgeEndSize, this.config.edgeEndSize, this.config.edgeEndSize),
          ProcessEdgeLabelViewModel.empty(),
          ProcessEdgeLabelBackgroundViewModel.empty(8, 8)
        );
      });


      this.edges.forEach(e => this.updateEdgeDisplayValues(e));
    }

    this.edgeIdGenerator = Math.max(this.edgeIdGenerator, __(this.edges.map(e => e.id)).maxOrZero());
  }


  protected fixEdgeType(edgeType: EdgeType): EdgeType {
    if (edgeType.name === EdgeType.alternative.name) {
      return EdgeType.actionUserRedirect;
    } else {
      return edgeType;
    }
  }

  protected updateEdgesEndsAndLabels(edges: Array<ProcessEdgeViewModel>) {
    try {
      const edgesWithShifts: Array<EdgeWithShift> = this.edgeXYCalculator.findShifts(edges, this.nodes);
      this.updateEdgesStarts(edgesWithShifts);
      this.updateEdgesEnds(edgesWithShifts);
      this.updateEdgesLabels(edgesWithShifts);
      myRequestAnimationFrame(() => {
        this.updateEdgesLabelsBackgrounds(edgesWithShifts);
      });
      edges.forEach(e => {
        e.labelPlaceholder = e.name.isEmpty();
      });
    } catch (e) {
      console.error(e);
    }
  }

  private updateEdgesStarts(edgesWithShifts: Array<EdgeWithShift>) {
    edgesWithShifts.map(edgeWithShift => {
      const edgeStart = edgeWithShift.edge.start;
      const startPosition = this.edgeXYCalculator.calculatePositionOfEnding(this.nodes, edgeWithShift, true, edgeWithShift.defaultEdge, NodeType.nodeSize)
      edgeStart.r = edgeWithShift.edge.edgeType.isAnyDelayAlternative() ? this.config.edgeSignalStartSize : this.config.edgeStartSize;
      edgeStart.innerR = edgeStart.r - 1;
      edgeStart.cx = startPosition.x + this.config.edgeStartSize / 2;
      edgeStart.cy = startPosition.y + this.config.edgeStartSize / 2;
      edgeStart.edgeDefault = startPosition.edgeDefault;

      switch (edgeWithShift.edge.edgeType.name) {
        case EdgeType.normal.name: edgeStart.icon = undefined; break;
        case EdgeType.actionUserRedirect.name: edgeStart.icon = undefined; break;
        case EdgeType.actionDelay.name: edgeStart.icon = MyIcons.MI_CLOCK; break;
        case EdgeType.actionWait.name: edgeStart.icon = MyIcons.MI_CHECK_CIRCLE; break;
        default: throw new Error("Unknown edge type: " + edgeWithShift.edge.edgeType.name);
      }

      edgeStart.iconSize = this.config.alternativeEdgeIconSize;
      edgeStart.iconX = edgeStart.cx - this.config.alternativeEdgeIconSize / 2;
      edgeStart.iconY = edgeStart.cy + this.config.alternativeEdgeIconSize / 2 - 1;
    });
  }

  private updateEdgesEnds(edgesWithShifts: Array<EdgeWithShift>) {
    edgesWithShifts.map(edgeWithShift => {
      const edgeEnd = edgeWithShift.edge.end;
      const endPosition = this.edgeXYCalculator.calculatePositionOfEnding(this.nodes, edgeWithShift, false, edgeWithShift.defaultEdge, NodeType.nodeSize);
      edgeEnd.updateXY(endPosition.x, endPosition.y);
      edgeEnd.transform = endPosition.rotateDegrees === 0 ? "" : `rotate(${endPosition.rotateDegrees}, 32, 32)`
    });
  }

  private updateEdgesLabels(edgesWithLabels: Array<EdgeWithShift>) {
    edgesWithLabels.map(edgeWithLabel => {
      const edgeLabel = edgeWithLabel.edge.label;
      const center = edgeWithLabel.labelCenterPosition;
      edgeLabel.x = center.x;
      edgeLabel.y = center.y;
      edgeLabel.transform = center.vertical ? `rotate(${this.config.verticalEdgesLabelsDirection * (-90)} ${center.x},${center.y})` : undefined;
      edgeLabel.text = center.shortenedLabel;
    });
  }

  private updateEdgesLabelsBackgrounds(edges: Array<EdgeWithShift>, tryNumber: number = 0) {
    if(!this.destroyed) {

      const [initialized, labels] = this.findLabelsRectanglesFromDOM(edges);
      if (initialized) { // if edges is empty we need to update rects to hide deleted edges' rects

        labels.map(label => {
          const edgeLabelBackground = label.edge.labelBackground;
          edgeLabelBackground.x = label.x + (label.width < label.height ? 0.5 : 0);
          edgeLabelBackground.y = label.y + (label.width >= label.height ? 0.5 : 0)
          edgeLabelBackground.width = label.width;
          edgeLabelBackground.height = label.height;
        });

      } else { // otherwise waiting for labels to be initialized
        if (tryNumber < 100) {
          mySetTimeout(() => {
            this.updateEdgesLabelsBackgrounds(edges, tryNumber + 1);
          }, 30);
        }
      }
    }
  }


  private updateAnnotationBackgroundSize(annotation: ProcessAnnotationViewModel, tryNumber: number = 0) {
    if(!this.destroyed) {
      const [initialized, rect] = this.findAnnotationRectangleFromDOM(annotation);
      if (initialized) { // if edges is empty we need to update rects to hide deleted edges' rects
        annotation.width = rect.width + this.config.annotationTextHorizontalMargin * 2;
        annotation.height = rect.height + this.config.annotationTextVerticalMargin * 2;
      }
    } else { // otherwise waiting for annotations to be initialized
      if (tryNumber < 100) {
        mySetTimeout(() => {
          this.updateAnnotationBackgroundSize(annotation, tryNumber + 1);
        }, 30);
      }
    }
  }



  findLabelsRectanglesFromDOM(edgesWithLabels: Array<EdgeWithShift>): [boolean, Array<EdgeLabelRect>] {

    let initialized = false;

    const designMapScreenPosition = getElementPosition($$(document.body).findOrError(".processMapCommon"));

    const result = edgesWithLabels.map(edge => {
      const label = $$(document.body).findOrError(".graphEdgesLayer .edgeGroup"+edge.edge.id+" text.edgeLabel");
      const rect = getElementPositionAndSize(label);

      if (rect.width != 0 || rect.height != 0) {
        initialized = true;
      }
      const center = edge.labelCenterPosition;

      const canvasPosition = this.xyCalculator.screenPositionToCanvasPosition(new PositionXY(rect.x - designMapScreenPosition.x, rect.y - designMapScreenPosition.y));
      if (center.vertical) {
        return new EdgeLabelRect(edge.edge, canvasPosition.x - 2, canvasPosition.y - 6, rect.width / this.viewPort.scale + 4, rect.height / this.viewPort.scale + 12);
      } else {
        return new EdgeLabelRect(edge.edge, canvasPosition.x - 6, canvasPosition.y - 2, rect.width / this.viewPort.scale + 12, rect.height / this.viewPort.scale + 4);
      }
    });

    return [initialized, result];
  }


  findAnnotationRectangleFromDOM(annotation: ProcessAnnotationViewModel): [boolean, RectXY] {

    let initialized = false;

    const designMapScreenPosition = getElementPosition($$(document.body).findOrError(".processMapCommon"));

    const label = $$(document.body).findOrError(".graphAnnotationsLayer .graphAnnotation_"+annotation.id+" text.graphAnnotation");
    const rect = getElementPositionAndSize(label);

    if (rect.width != 0 || rect.height != 0) {
      initialized = true;
    }

    return [initialized, new RectXY(rect.x, rect.y, rect.width / this.viewPort.scale, rect.height / this.viewPort.scale)];
  }

  protected calculateEdgePath(path: GridXYPath) {

    if(path.points.length === 0) {
      return "";
    } else {

      const result = path.points
        .map(point => this.xyCalculator.gridXYToPathPosition(point))
        .map((point) => "L" + point.x + " " + point.y)
        .join(" ");

      return "M " + roundPathCorners(result, 12, false).substring(2);
    }
  };

  protected textLinesToText(x: number, y: number, textLines: Array<CachedLine>): Array<TextLineViewModel> {
    return textLines.map(line => this.textLineToText(x, y, line));
  }

  protected textLineToText(x: number, y: number, line: CachedLine): TextLineViewModel {
    return new TextLineViewModel(
      x,
      y + line.y,
      line.dx,
      line.dy,
      line.width,
      line.height,
      line.text
    );
  }

  protected updateRoles(updateOnly: boolean = false) {


    if(!updateOnly) {
      this.roles = [];
    }

    // Fill in roles
    this.gridProcessModel.actors.forEach(role => {

      let r = this.roles.find(r => r.id === role.id);
      if(r) {
        r.gridY = role.gridY;
        r.rows = role.rowsCount;
        r.roleName = role.name;
        r.identifier = role.identifier.getOrElse("");
      } else {
        r = new ProcessRoleViewModel(
          role.id,
          role.gridY,
          role.rowsCount,
          role.name,
          role.identifier.getOrElse(""),
          role.description,
          role.nodeAssignment,
          role.taskDistribution,
          role.roleMembersCanChangeTaskAssignment,
          role.roleMembersCanChangeTaskAssignmentExpression,
          role.incompatibleRoles,
          role.tasksVisibility,
          role.assigneeLimit,
          role.canChangeCaseImportance,
          role.canChangeCaseImportanceExpression,
          role.canChangeCaseUrgency,
          role.canChangeCaseUrgencyExpression,
          role.canChangeCaseLabels,
          role.canChangeCaseLabelsExpression,
          role.commentsAccess
        );
        this.roles.push(r);
      }

      this.updateRoleDisplayValues(r);
    });


    this.roleIdGenerator = Math.max(this.roleIdGenerator, __(this.roles.map(e => e.id)).maxOrZero());

  }

  protected updateRowsHeader(updateOnly: boolean = false) {

    this.rolesHeader.x = - this.viewPort.position.x / this.viewPort.scale;
    this.rolesHeader.width = this.config.rolesHeaderWidth;
    this.rolesHeader.height = this.canvasModel.gridHeight * this.config.cellSize.height * this.viewPort.scale;


    this.rowsHeader = [];

    // FIll in missing rows
    const gridHeight = this.canvasModel.gridHeight;
    for (let gridY = 0; gridY < gridHeight; gridY++) {
      const rowHeader = new ProcessRowHeaderViewModel(gridY);
      this.rowsHeader.push(rowHeader);
      this.updateRowDisplayValues(rowHeader);
    }
  }

  protected updateRowDisplayValues(row: ProcessRowHeaderViewModel) {
    let iconBottom = this.config.cellSize.height / 2 + this.roleIconSize.height / 2; // icon is text so it's position is calculated from bottom

    row.x = 0;
    row.y = row.gridY * this.config.cellSize.height;
    row.width = this.config.rolesHeaderWidth;
    row.height = this.config.cellSize.height;

    row.icon = MyIcons.MI_USER_ADD;
    row.iconX = this.config.rolesHeaderWidth / 2 - this.roleIconSize.width / 2;
    row.iconY = iconBottom;

    row.notHiddenByRole = this.findRoleAt(row.gridY) === undefined;

  }

  protected updatePhasesDefinitionsDisplayValues() {

    this.roles.forEach(role => role.hasPhasesDefinition = false);

    this.allPhasesDefinitions = this.additionalPhasesDefinitions.concat([this.mainPhasesDefinition]);
    this.allPhasesDefinitions.forEach((definition, index) => {

      if(definition.roleId === undefined) {
        definition.y = - this.viewPort.position.y / this.viewPort.scale;
      } else {
        const role = this.getRoleById(definition.roleId);
        definition.y = role.gridY * this.config.cellSize.height;

        role.hasPhasesDefinition = true;
      }

      definition.x = 0;
      definition.width = this.config.cellSize.width * this.canvasModel.gridColumns.length;
      definition.height = this.config.columnHeaderHeight;

      definition.phases.forEach(p => this.updatePhaseDisplayValues(p));
    });
  }

  protected updatePhaseDisplayValues(phase: ProcessPhaseViewModel) {

    if(phase.roleId === undefined) {
      phase.y = 0;
    } else {
      const role = this.getRoleById(phase.roleId);
      phase.y = role.gridY * this.config.cellSize.height;
    }

    phase.x = phase.gridX * this.config.cellSize.width;
    phase.width = phase.columns * this.config.cellSize.width;
    phase.dragHandleWidth = 7;
    phase.startDragHandleX = phase.x;
    phase.endDragHandleX = phase.x + phase.width - phase.dragHandleWidth;

    const phasesDefinition = this.getPhasesDefinitionByRoleId(phase.roleId);

    this.mainPhaseHeaderWrapperConfig.width(phase.width);
    this.additionalPhaseHeaderWrapperConfig.width(phase.width);
    let phaseName = phase.phaseName.getCurrentWithFallback().trim();
    if(phaseName.length === 0) {
      phaseName = i18n("Phase ") + (phase.gridX + 1) + " - " + (phase.gridX + phase.columns);
    }
    let phaseNameLines = wrapSingleStringText(phaseName, phasesDefinition.additional ? this.additionalPhaseHeaderWrapperConfig : this.mainPhaseHeaderWrapperConfig);
    let roleNameTextLines = this.textLinesToText(phase.x, 0, phaseNameLines);

    phase.phaseNameLines = roleNameTextLines;
  }

  protected updateRoleDisplayValues(role: ProcessRoleViewModel) {

    let y = role.gridY * this.config.cellSize.height;

    const relativeCenterPosition = this.config.cellSize.height / 2 * role.rows;
    const elementCenterPosition = y + relativeCenterPosition;
    let iconTop = relativeCenterPosition - this.roleIconSize.height / 2 - 7; // -7 manual adjustment
    let iconBottom = relativeCenterPosition + this.roleIconSize.height / 2 - 7; // icon is text so it's position is calculated from bottom

    //
    let roleNameLines = wrapSingleStringText(role.roleName.getCurrentWithFallback(), this.roleHeaderWrapperConfig);
    if(roleNameLines.length > 5) {
      roleNameLines = roleNameLines.slice(0, 5);
    }
    //
    let roleNameTop = iconBottom + this.roleNameLabelMargin;
    let roleNameTextLines = this.textLinesToText(0, roleNameTop, roleNameLines);
    const roleNameBottom = this.getTextBottom(roleNameTextLines, roleNameTop);


    let centeringShift = Math.min(this.config.cellSize.height - roleNameBottom - 10, 0);

    let shiftedRoleName = roleNameTextLines.map(l => l.updateY(l.y + centeringShift));

    role.y = y;
    role.width = this.config.rolesHeaderWidth;
    role.height = role.rows * this.config.cellSize.height;

    role.icon = MyIcons.MI_USER;
    role.iconX = this.config.rolesHeaderWidth / 2 - this.roleIconSize.width / 2;
    role.iconY = centeringShift + iconBottom;

    role.hasPhasesDefinitionIconX = this.config.rolesHeaderWidth - 25;
    role.hasPhasesDefinitionIconY = 26;

    role.roleNameLines = shiftedRoleName;

    role.strokeDasharray = "";

    role.hasPhasesDefinition = this.findPhasesDefinitionForRole(role.id) !== undefined;

    this.updateRoleInfo(role.id, role, elementCenterPosition, iconTop, iconBottom, roleNameTextLines);

  }


  protected updateRoleInfo(roleId: ProcessRoleId, a: ProcessRoleViewModel, elementCenterPosition: number, iconTop: number, iconBottom: number, roleNameLines: Array<TextLineViewModel>) {
    // override in child class if needed
  }

  protected updateColumnsHeader() {
    this.allPhasesDefinitions = this.additionalPhasesDefinitions.concat([this.mainPhasesDefinition]);

    this.updatePhasesDefinitionsDisplayValues();

    this.columnHeader.y = - this.viewPort.position.y / this.viewPort.scale;
    this.columnsHeader = this.canvasModel.gridColumns.map(column => {

      const columnName = i18n("process_phase") + " " + (column + 1);
      this.columnHeaderWrapperConfig.height(this.config.columnHeaderHeight);
      const textLines = wrapSingleStringText(columnName, this.columnHeaderWrapperConfig);

      const x = column * this.config.cellSize.width;
      return new ProcessColumnHeaderViewModel(
        column,
        x,
        0,
        this.config.cellSize.width,
        this.config.columnHeaderHeight,
        ``,
        0,
        0,
        this.textLinesToText(x, 8, textLines),
      )});

  }


  protected updateCornerElement() {

    this.corner.width = this.config.rolesHeaderWidth;
    this.corner.height = this.config.columnHeaderHeight;
    this.corner.strokeDasharray = ``;
    this.corner.x = - this.viewPort.position.x / this.viewPort.scale;
    this.corner.y = - this.viewPort.position.y / this.viewPort.scale;

    this.corner.labelX = 0;
    this.corner.labelY = this.corner.height - this.config.columnHeaderHeight / 2 + 1;
    this.corner.labelWidth = this.config.rolesHeaderWidth;
    this.corner.labelHeight = this.config.columnHeaderHeight;

    const paddingBottom = 10;
    this.cornerHeaderWrapperConfig = this.cornerHeaderWrapperConfig.height(this.corner.height - paddingBottom);
    const labeltexts = wrapSingleStringText(this.rolesLabelText, this.cornerHeaderWrapperConfig);

    this.corner.label = this.textLinesToText(this.config.gridTextMargin, 0, labeltexts);
  }

  protected getTextBottom(label: Array<TextLineViewModel>, top: number): number {
    let bottom = top;
    label.forEach(l => {
      bottom = Math.max(bottom, l.y + l.dy);
    });
    return bottom;
  }

  paddingByNode(nodeTypeName: string) {
    switch (nodeTypeName) {
      case NodeType.startAutomatic.name: return new ShiftXY(38, 14);
      case NodeType.startForm.name: return new ShiftXY(38, 14);
      case NodeType.actionForm.name: return new ShiftXY(0, 5);
      case NodeType.actionAutomatic.name: return new ShiftXY(0, 5);
      case NodeType.external.name: return new ShiftXY(0, 5);
      case NodeType.condition.name: return new ShiftXY(0, 5);
      case NodeType.finish.name: return new ShiftXY(38, 14);
      case NodeType.externalFinish.name: return new ShiftXY(38, 14);
      case NodeType.delay.name: return new ShiftXY(38, 14);
      case NodeType.wait.name: return new ShiftXY(38, 14);
      case NodeType.emailEvent.name: return new ShiftXY(38, 14);
      case NodeType.messageEvent.name: return new ShiftXY(38, 14);
      default: throw new Error("Node type '" + nodeTypeName + "' not found!");
    }
  }

  destroy() {
    this.destroyed = true;
  }

  getNodeById(nodeId: ProcessNodeId) {
    return required(this.nodes.find(node => node.id === nodeId), "node");
  }

  getEdgeById(edgeId: ProcessEdgeId) {
    return required(this.edges.find(edge => edge.id === edgeId), "edge");
  }

  findEdgeById(edgeId: ProcessEdgeId) {
    return this.edges.find(edge => edge.id === edgeId);
  }

  getRoleById(roleId: ProcessRoleId) {
    return required(this.roles.find(a => a.id === roleId), "role");
  }

  getAnnotationById(id: ProcessAnnotationId) {
    return required(this.annotations.find(a => a.id === id), "annotation");
  }

  getActionById(id: AutomaticActionId): AutomaticAction {
    return required(this.actions.find(a => a.id.id === id.id), "action");
  }

  getActionByIdOrIdentifier(id: Either<AutomaticActionId, string>): AutomaticAction {
    if(id.isLeft()) {
      const i = id.getLeft();
      return required(this.actions.find(a => a.id.id === i.id), "action");
    } else if(id.isLeft()) {
      const i = id.getRight();
      return required(this.actions.find(a => a.identifier.contains(i)), "action");
    } else {
      throw new Error("Unknown id type");
    }
  }


  findAnnotationsForNode(id: ProcessNodeId) {
    return this.annotations.filter(a => a.nodes.includes(id));
  }

  edgesSortedByDrawPriority() {
    return __(this.edges).sortBy(e => -e.drawPriority * 1000000 + e.id);
  }


  getPhasesDefinitionByRoleId(roleId: ProcessRoleId|undefined) {
    if(this.mainPhasesDefinition.roleId === roleId) {
      return this.mainPhasesDefinition;
    } else {
      return required(this.additionalPhasesDefinitions.find(a => a.roleId === roleId), "phase header");
    }
  }

  getCommentById(id: ProcessCommentsGroupId) {
    return required(this.comments.find(a => a.id === id), "comment");
  }

  getLastAnnotation() {
    const maxId = __(this.annotations.map(a => a.id)).max();
    return this.getAnnotationById(maxId);
  }

  findRowAt(gridY: number) {
    return this.rowsHeader.find(a => a.gridY === gridY);
  }

  findRoleAt(gridY: number) {
    return this.roles.find(r => gridY >= r.gridY && gridY < r.gridY + r.rows);
  }

  gridSize(fakeNodeGridXY:GridXY = new GridXY(0, 0), maxNodeGridX = this.maxNodeGridX(), maxNodeGridY = this.maxNodeGridY(), maxRoleGridY = this.maxRoleGridY()):GridSize {
    return new GridSize(
      Math.max(fakeNodeGridXY.gridX + 2, maxNodeGridX + 2, ProcessMapCommonViewModel.minimumHeaderColumns) + ProcessMapCommonViewModel.emptyHeaderColumns - 1,
      Math.max(fakeNodeGridXY.gridY + 3, maxRoleGridY + 3, this.maxNodeGridY() + 3, ProcessMapCommonViewModel.minimumRolesRows + ProcessMapCommonViewModel.emptyRoleRow) - 1)
  }

  protected maxNodeGridX() {
    return ___(this.nodes).map(n => n.gridX).maxOrZero();
  }

  protected maxNodeGridY() {
    return ___(this.nodes).map(n => n.gridY).maxOrZero();
  }

  protected maxRoleGridY() {
    return ___(this.roles).map(n => n.gridY).maxOrZero();
  }

  protected findNodeAt(newNodePosition: GridXY) {
    return this.nodes.find(n => n.gridXY().isEqual(newNodePosition));
  }

  protected findNodeWithName(name: string) {
    return this.nodes.filter(n => n.label.containsAnyCase(name));
  }

  protected findNodesByType(nodeType: NodeType) {
    return this.nodes.filter(n => n.nodeType.name === nodeType.name);
  }

  protected findNodeWithIdentifier(identifier: string) {
    const lowerCase = identifier.toLowerCase();
    return this.nodes.find(n => n.identifier.toLowerCase() === lowerCase);
  }

  protected findPhasesDefinitionForRole(roleId: ProcessRoleId) {
    return this.allPhasesDefinitions.find(p => p.roleId === roleId);
  }

  protected findEdgesWithNode(id: ProcessNodeId) {
    return this.edges.filter(e => e.toNodeId === id || e.fromNodeId === id);
  }

  protected findEdgesEndsForEdges(edges: Array<ProcessEdgeViewModel>) {
    return __(edges).map(e => e.end);
  }

  protected findEdgesStartsForEdges(edges: Array<ProcessEdgeViewModel>) {
    return __(edges).map(e => e.start);
  }

  private updateNodeTemplatesDisplayValues() {
    this.nodesTemplates.forEach(node => {
      if (node.nodeType.isAnyEvent()) {
        node.iconX = this.config.nodeSize.width / 2 - (this.waitDelayIconSize) / 2;
        node.iconY = this.config.nodeSize.height / 2 + this.waitDelayIconSize / 2 - 1;
        node.iconSize = this.waitDelayIconSize;
      }
    });
  }

  private findEdgesToNode(nodeId: ProcessNodeId): Array<ProcessEdgeViewModel> {
    return this.edges.filter(e => e.toNodeId === nodeId);
  }

}
