import {
  ActionAsyncExecutionCompleted,
  ActionAsyncExecutionFailed,
  ActionAsyncExecutionStarted,
  ActionExecutionTriggered,
  ActionSyncExecutionCompleted,
  ActionSyncExecutionFailed,
  ActionValidationFailed,
  ChangeModel,
  ComponentInnerRepeatableContextChanged,
  ComponentModelChange,
  ComponentModelChanged,
  ComponentModelInProgress,
  ComponentPropertyChanged,
  ComponentPropertyErrorOccurred,
  ComponentPropertyInProgress,
  ComponentRefPropertyChanged,
  ComponentRefPropertyErrorOccurred,
  ComponentRefPropertyInProgress,
  ComponentRefValidationChanged,
  ComponentSingleContextChanged,
  ComponentStateCreated,
  ComponentStateDeleted,
  ComponentStatusChanged,
  ComponentValidationChanged,
  ComponentValidationErrorOccurred,
  ModelContextIdChanged, ModelToChange,
  OptionalContextComponentState,
  OutputParameterChanged,
  RefIdInContext,
  RefStateCreated,
  RefStateDeleted,
  RepeatableComponentEntryAdded,
  RepeatableComponentEntryDeleted,
  RepeatableContainerState,
  RepeatableContextComponentState,
  ScreenDebugInfo,
  ScreenInstanceService,
  ScreenInstanceState,
  ScreenInstanceStateEventFactory,
  ScreenRuntimeEventBus,
  UpdateableState
} from "./";
import {
  __, ___, AnyFlowId,
  BusinessEntityIdWithType,
  FileUri, FlowId,
  None,
  Option,
  ScreenId,
  screenInstanceDownloadUrl,
  ScreenInstanceId,
  Some,
  toastr,
  Typed,
  VariableId
} from "@utils";
import {NewScreenInstanceEvents, ScreenInstanceSharedService, ServerEventsService} from "@shared"
import {
  BusinessEntitySharedService,
  BusinessEntitySummary,
  BusinessVariable, EmailBasicInfo, EmailsSharedService,
  FileInfoModel,
  FilesSharedService, FlowAndTasksInfoForUser,
  NumberVariable,
  ProcessEdgeId, ProcessesNamingQueryService, ProcessesNamingResponseTransformed
} from "@shared-model";
import {FlowService} from "../task-form.module/service/FlowService";

export class ScreenInstanceServerModel {

  version: number = 0;
  private active = true;

  private subscriptionId: Option<string> = None();
  private eventsSubscription: Option<number> = None();

  private terminated = false;

  constructor(
    private readonly id: ScreenId,
    private readonly eventBus: ScreenRuntimeEventBus,
    private readonly screenInstanceService: ScreenInstanceService,
    private readonly screenInstanceSharedService: ScreenInstanceSharedService,
    private readonly instance: ScreenInstanceState,
    private readonly serverEventsService: ServerEventsService,
    private readonly filesSharedService: FilesSharedService,
    private readonly businessEntitySharedService: BusinessEntitySharedService,
    private readonly emailsSharedService: EmailsSharedService,
    private readonly flowService: FlowService,
    private readonly processesNamingQueryService: ProcessesNamingQueryService,
    private readonly reinitNeeded: () => void) {

    if (instance) { //TODO
      this.version = instance.version;
      this.subscribeForNewEvents();
    }
  }

  subscribeForNewEvents() {

    this.subscriptionId.forEach(s => this.serverEventsService.unsubscribe(s));
    this.subscriptionId = None();
    this.serverEventsService.subscribeForScreenInstance(this.instance.id, this.version, (consumerSubscriptionId => {
      this.subscriptionId = Some(consumerSubscriptionId);
    }), () => {
      this.reinitNeeded();
    });

    this.eventsSubscription = Some(this.serverEventsService.serverEventBus.on(this.serverEventsService.serverEventBus.screenInstanceEvents, (newEventsNotCopied: NewScreenInstanceEvents) => {
      const newEvents = ScreenInstanceStateEventFactory.copyWrapper(newEventsNotCopied);
      if (this.active && this.getInstanceId().id == newEvents.screenInstanceId.id) {
        // console.log("New events", newEvents.eventsUnwrapped);

        let statesToUpdate: Array<UpdateableState> = [];

        if (newEvents.error.isEmpty()) {

          newEvents.eventsUnwrapped.forEach((event) => {

            // console.log("Event handled "+event.className(), event);

            switch (event.className()) {
              case ComponentPropertyInProgress.className:
                statesToUpdate = statesToUpdate.concat(this.handleComponentPropertyInProgress(<ComponentPropertyInProgress>event));
                break;
              case ComponentPropertyChanged.className:
                statesToUpdate = statesToUpdate.concat(this.handleComponentPropertyChanged(<ComponentPropertyChanged>event));
                break;
              case ComponentPropertyErrorOccurred.className:
                statesToUpdate = statesToUpdate.concat(this.handleComponentPropertyErrorOccurred(<ComponentPropertyErrorOccurred>event));
                break;
              case ComponentRefPropertyInProgress.className:
                statesToUpdate = statesToUpdate.concat(this.handleComponentRefPropertyInProgress(<ComponentRefPropertyInProgress>event));
                break;
              case ComponentRefPropertyChanged.className:
                statesToUpdate = statesToUpdate.concat(this.handleComponentRefPropertyChanged(<ComponentRefPropertyChanged>event));
                break;
              case ComponentRefPropertyErrorOccurred.className:
                statesToUpdate = statesToUpdate.concat(this.handleComponentRefPropertyErrorOccurred(<ComponentRefPropertyErrorOccurred>event));
                break;
              case ComponentModelInProgress.className:
                statesToUpdate = statesToUpdate.concat(this.handleComponentModelInProgress(<ComponentModelInProgress>event));
                break;
              case ComponentModelChanged.className:
                statesToUpdate = statesToUpdate.concat(this.handleComponentModelChanged(<ComponentModelChanged>event));
                break;
              case ComponentSingleContextChanged.className:
                statesToUpdate = statesToUpdate.concat(this.handleComponentSingleContextChanged(<ComponentSingleContextChanged>event));
                break;
              case ActionExecutionTriggered.className:
                statesToUpdate = statesToUpdate.concat(this.handleActionExecutionTriggered(<ActionExecutionTriggered>event));
                break;
              case ActionValidationFailed.className:
                statesToUpdate = statesToUpdate.concat(this.handleActionValidationFailed(<ActionValidationFailed>event));
                break;
              case ActionSyncExecutionFailed.className:
                statesToUpdate = statesToUpdate.concat(this.handleActionSyncExecutionFailed(<ActionSyncExecutionFailed>event));
                break;
              case ActionSyncExecutionCompleted.className:
                statesToUpdate = statesToUpdate.concat(this.handleSyncActionExecutionCompleted(<ActionSyncExecutionCompleted>event));
                break;
              case ActionAsyncExecutionStarted.className:
                statesToUpdate = statesToUpdate.concat(this.handleActionAsyncExecutionStarted(<ActionAsyncExecutionStarted>event));
                break;
              case ActionAsyncExecutionFailed.className:
                statesToUpdate = statesToUpdate.concat(this.handleActionAsyncExecutionFailed(<ActionAsyncExecutionFailed>event));
                break;
              case ActionAsyncExecutionCompleted.className:
                statesToUpdate = statesToUpdate.concat(this.handleActionAsyncExecutionCompleted(<ActionAsyncExecutionCompleted>event));
                break;
              case ComponentValidationChanged.className:
                statesToUpdate = statesToUpdate.concat(this.handleComponentValidationChanged(<ComponentValidationChanged>event));
                break;
              case ComponentValidationErrorOccurred.className:
                statesToUpdate = statesToUpdate.concat(this.handleComponentValidationErrorOccurred(<ComponentValidationErrorOccurred>event));
                break;
              case ComponentRefValidationChanged.className:
                statesToUpdate = statesToUpdate.concat(this.handleComponentRefValidationChanged(<ComponentRefValidationChanged>event));
                break;
              case ComponentInnerRepeatableContextChanged.className:
                statesToUpdate = statesToUpdate.concat(this.handleComponentInnerRepeatableContextChanged(<ComponentInnerRepeatableContextChanged>event));
                break;
              case RefStateCreated.className:
                statesToUpdate = statesToUpdate.concat(this.handleRefStateCreated(<RefStateCreated>event));
                break;
              case RefStateDeleted.className:
                statesToUpdate = statesToUpdate.concat(this.handleRefStateDeleted(<RefStateDeleted>event));
                break;
              case ComponentStateCreated.className:
                statesToUpdate = statesToUpdate.concat(this.handleComponentStateCreated(<ComponentStateCreated>event));
                break;
              case ComponentStateDeleted.className:
                statesToUpdate = statesToUpdate.concat(this.handleComponentStateDeleted(<ComponentStateCreated>event));
                break;
              case ComponentStatusChanged.className:
                statesToUpdate = statesToUpdate.concat(this.handleComponentStatusChanged(<ComponentStatusChanged>event));
                break;
              case RepeatableComponentEntryAdded.className:
                statesToUpdate = statesToUpdate.concat(this.handleRepeatableComponentEntryAdded(<RepeatableComponentEntryAdded>event));
                break;
              case RepeatableComponentEntryDeleted.className:
                statesToUpdate = statesToUpdate.concat(this.handleRepeatableComponentEntryDeleted(<RepeatableComponentEntryDeleted>event));
                break;
              case OutputParameterChanged.className:
                this.onOutputParameterChanged(<OutputParameterChanged>event);
                break;
              case ModelContextIdChanged.className:
                this.onModelContextIdChanged(<ModelContextIdChanged>event);
                break;
            }

            this.version = event.version
          });

          __(statesToUpdate).unique().forEach(state => state.updated());
          this.eventBus.componentsStateUpdated();

        } else {
          toastr.error("Error while getting view events: " + newEvents.error.get());
        }
      } // otherwise ignore
    }));

  }

  handleComponentRefPropertyChanged(event: ComponentRefPropertyChanged): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentRefState(event.contextId, event.componentRefId);
    if (event.value.isDefined()) {
      state.properties.putValue(event.propertyName, event.unwrappedValue.get());
    } else {
      state.properties.clearValue(event.propertyName);
    }
    return [state];

  }

  handleComponentRefPropertyInProgress(event: ComponentRefPropertyInProgress): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentRefState(event.contextId, event.componentRefId);
    state.properties.putInProgress(event.propertyName);
    return [state];
  }

  handleComponentRefPropertyErrorOccurred(event: ComponentRefPropertyErrorOccurred): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentRefState(event.contextId, event.componentRefId);
    state.properties.putError(event.propertyName, event.timestamp, event.message);
    return [state];
  }

  handleComponentPropertyInProgress(event: ComponentPropertyInProgress): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentState(event.contextId, event.componentId);
    state.properties.putInProgress(event.propertyName);
    return [state];
  }

  handleComponentPropertyChanged(event: ComponentPropertyChanged): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentState(event.contextId, event.componentId);
    if (event.value.isDefined()) {
      state.properties.putValue(event.propertyName, event.unwrappedValue.get());
    } else {
      state.properties.clearValue(event.propertyName);
    }
    return [state];
  }

  handleComponentPropertyErrorOccurred(event: ComponentPropertyErrorOccurred): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentState(event.contextId, event.componentId);
    state.properties.putError(event.propertyName, event.timestamp, event.message);
    return [state];
  }

  handleComponentModelChanged(event: ComponentModelChanged): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentState(event.contextId, event.componentId);
    state.updateModel(event.modelName, event.unwrappedValue);
    return [state];
  }

  handleComponentModelInProgress(event: ComponentModelInProgress): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentState(event.contextId, event.componentId);
    state.setModelInProgress(event.modelName);
    return [state];
  }

  handleComponentSingleContextChanged(event: ComponentSingleContextChanged): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentState(event.contextId, event.componentId);

    if (state instanceof OptionalContextComponentState) {
      state.updateInnerContext(event.innerContext);
    } else {
      throw new Error("Only single context container supported")
    }

    state.properties.putValue("$status", new NumberVariable(event.status));
    return [state];
  }


  handleActionExecutionTriggered(event: ActionExecutionTriggered): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentState(event.contextId, event.componentId);
    state.properties.setActionStateInProgress(event.actionName, event.timestamp);
    return [state];
  }


  handleActionValidationFailed(event: ActionValidationFailed): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentState(event.contextId, event.componentId);
    state.properties.setActionStateValidationError(event.actionName, event.messages);
    return [state];
  }

  handleComponentValidationChanged(event: ComponentValidationChanged): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentState(event.contextId, event.componentId);
    state.properties.setComponentValidationErrors(event.errors);
    return [state];
  }

  handleComponentValidationErrorOccurred(event: ComponentValidationErrorOccurred): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentState(event.contextId, event.componentId);
    state.properties.setComponentValidationException(event.message, event.timestamp, event.index);
    return [state];
  }

  handleComponentRefValidationChanged(event: ComponentRefValidationChanged): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentRefState(event.contextId, event.componentRefId);
    state.properties.setComponentValidationErrors(event.errors);
    return [state];
  }


  handleActionSyncExecutionFailed(event: ActionSyncExecutionFailed): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentState(event.contextId, event.componentId);
    state.properties.setActionSyncStateError(event.actionName, event.timestamp, event.index, event.message);
    return [state];
  }

  handleSyncActionExecutionCompleted(event: ActionSyncExecutionCompleted): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentState(event.contextId, event.componentId);
    state.properties.setActionStateSyncCompleted(event.actionName);
    return [state];
  }

  handleActionAsyncExecutionStarted(event: ActionAsyncExecutionStarted): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentState(event.contextId, event.componentId);
    state.properties.setActionStateInAsyncStarted(event.actionName, event.asyncActions);
    return [state];
  }

  handleActionAsyncExecutionFailed(event: ActionAsyncExecutionFailed): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentState(event.contextId, event.componentId);
    state.properties.setActionAsyncStateError(event.actionName, event.timestamp, event.index, event.message);
    return [state];
  }

  handleActionAsyncExecutionCompleted(event: ActionAsyncExecutionCompleted): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentState(event.contextId, event.componentId);
    state.properties.setActionStateInAsyncCompleted(event.actionName, event.index);
    return [state];
  }

  handleComponentInnerRepeatableContextChanged(event: ComponentInnerRepeatableContextChanged): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentState(event.contextId, event.componentId);

    if (state instanceof RepeatableContextComponentState) {
      if (event.innerContext.isDefined()) {
        state.updateInnerContext(event.innerContext.get());
      } else {
        state.clearInnerContext();
      }
    } else {
      throw new Error("Only repeatable context container supported")
    }

    state.properties.putValue("$status", new NumberVariable(event.status));
    return [state];
  }


  handleRefStateCreated(event: RefStateCreated): Array<UpdateableState> {
    this.instance.componentsState.putComponentRefState(event.contextId, event.refId, event.stateUnwrapped);
    return [];
  }

  handleRefStateDeleted(event: RefStateDeleted): Array<UpdateableState> {
    this.instance.componentsState.deleteComponentRefState(event.contextId, event.refId);
    return [];
  }

  handleComponentStateCreated(event: ComponentStateCreated): Array<UpdateableState> {
    const state = event.stateUnwrapped;
    state.properties.putValue("$status", new NumberVariable(event.status));
    this.instance.componentsState.putComponentState(event.contextId, event.componentId, state);
    return event.parent.map(p => this.instance.componentsState.getComponentRefState(p.context, p.refId)).toArray();
  }

  handleComponentStateDeleted(event: ComponentStateDeleted): Array<UpdateableState> {
    this.instance.componentsState.deleteComponentState(event.contextId, event.componentId);
    return event.parent.map(p => this.instance.componentsState.getComponentRefState(p.context, p.refId)).toArray();
  }

  handleComponentStatusChanged(event: ComponentStatusChanged): Array<UpdateableState> {
    const state = this.instance.componentsState.getComponentState(event.contextId, event.componentId);
    state.properties.putValue("$status", new NumberVariable(event.status));
    return [state];
  }

  handleRepeatableComponentEntryAdded(event: RepeatableComponentEntryAdded): Array<UpdateableState> {
    const state = <RepeatableContainerState>this.instance.componentsState.getComponentState(event.contextId, event.componentId);
    state.pushToInnerContext(event.entryId);
    return [state];
  }

  handleRepeatableComponentEntryDeleted(event: RepeatableComponentEntryDeleted): Array<UpdateableState> {
    const state = <RepeatableContainerState>this.instance.componentsState.getComponentState(event.contextId, event.componentId);
    state.removeFromModel(event.entryId);
    return [state];
  }

  changeModel(refsPath: Array<RefIdInContext>, modelName: string, value: BusinessVariable) {
    this.screenInstanceService.changeModel(this.instance.id, refsPath, modelName, value, [], () => {

    });
  }

  clearModel(refsPath: Array<RefIdInContext>, modelName: string) {
    this.screenInstanceService.clearModel(this.instance.id, refsPath, modelName, [], () => {

    });
  }

  changeMultipleModelsWithAction(refsPath: Array<RefIdInContext>, modelChanges: Array<ComponentModelChange>, actionTriggered: string) {
    this.screenInstanceService.changeMultipleModels(this.instance.id, refsPath, modelChanges, [actionTriggered], () => {

    });
  }

  changeMultipleModels(refsPath: Array<RefIdInContext>, modelChanges: Array<ComponentModelChange>) {
    this.screenInstanceService.changeMultipleModels(this.instance.id, refsPath, modelChanges, [], () => {

    });
  }

  changeModelWithAction(refsPath: Array<RefIdInContext>, modelName: string, value: BusinessVariable, actionTriggered: string) {
    this.screenInstanceService.changeModel(this.instance.id, refsPath, modelName, value, [actionTriggered], () => {

    });
  }

  changeModelsWithAction(refsPath: Array<RefIdInContext>, models: Array<ModelToChange>, actionTriggered: string) {
    this.screenInstanceService.changeModels(this.instance.id, refsPath, models, [actionTriggered], () => {

    });
  }
  closeModal(refsPath: Array<RefIdInContext>, accept: boolean) {
    this.screenInstanceService.closeModal(this.instance.id, refsPath, accept, () => {

    });
  }

  clearModelWithAction(refsPath: Array<RefIdInContext>, modelName: string, actionTriggered: string) {
    this.screenInstanceService.clearModel(this.instance.id, refsPath, modelName, [actionTriggered], () => {

    });
  }

  appendToModel(refsPath: Array<RefIdInContext>, modelName: string, expectedModelLength: Option<number>, value: BusinessVariable): void {
    this.screenInstanceService.appendToModel(this.instance.id, refsPath, modelName, expectedModelLength, value, () => {

    });
  }

  removeFromModelWithAction(refsPath: Array<RefIdInContext>, modelName: string, valueIndex: number, value: BusinessVariable, actionTriggered: string): void {
    this.screenInstanceService.removeFromModel(this.instance.id, refsPath, modelName, valueIndex, value, [actionTriggered], () => {

    });
  }




  executeAction(refsPath: Array<RefIdInContext>, actionName: string, optional: boolean = false, changeModelAfter: Option<ChangeModel> = None()): void {
    this.screenInstanceService.executeAction(this.instance.id, refsPath, actionName, optional, changeModelAfter,() => {

    });
  }

  executeEntryAction(refsPath: Array<RefIdInContext>, entryContextId: VariableId, actionName: string): void {
    this.screenInstanceService.executeEntryAction(this.instance.id, refsPath, entryContextId, actionName, () => {

    });
  }


  addRepeatableContextEntry(contextLength: number, refsPath: Array<RefIdInContext>, actionTriggered: string, onComplete: () => void): void {
    this.screenInstanceService.addRepeatableContextEntry(this.instance.id, contextLength, refsPath, [actionTriggered], () => {
      onComplete();
    });
  }

  deleteRepeatableContextEntry(refsPath: Array<RefIdInContext>, entryId: VariableId, entryIndex: number, actionTriggered: string, onComplete: () => void): void {
    this.screenInstanceService.deleteRepeatableContextEntry(this.instance.id, refsPath, entryId, entryIndex, [actionTriggered], () => {
      onComplete();
    });
  }

  moveEntry(refsPath: Array<RefIdInContext>, entryId: VariableId, fromIndex: number, toIndex: number, actionTriggered: string, onComplete: () => void): void {
    this.screenInstanceService.moveEntry(this.instance.id, refsPath, entryId, fromIndex, toIndex, [actionTriggered], () => {
      onComplete();
    });
  }

  destroy() {
    this.active = false;
    this.subscriptionId.forEach(s => this.serverEventsService.unsubscribe(s));
    this.eventsSubscription.forEach(s => this.serverEventsService.serverEventBus.unsubscribe(s));
  }

  terminate(onSuccess: () => void) {
    this.terminated = true;
    this.screenInstanceSharedService.terminate(this.instance.id, () => {
      onSuccess();
    });
  }

  getInstanceId(): ScreenInstanceId {
    return this.instance.id;
  }


  loadFilesInfo(refPath: Array<RefIdInContext>, files: Array<FileUri>, onSuccess: (filesInfo: Array<FileInfoModel>) => void): void {
    if (files.length == 0) {
      onSuccess([]);
    } else {
      this.filesSharedService.filesInfo(files, files => {
        onSuccess(__(files).map(f => FileInfoModel.ofBasicInfo(f, (uri: any) => screenInstanceDownloadUrl(this.instance.id, this.serializeRefPath(refPath), uri))));
      });
    }
  }


  serializeRefPath(refPath: Array<RefIdInContext>): string {
    return refPath.map(r => r.contextId.id+"@"+r.refId.screenId+"@"+r.refId.id).join(">");
  }

  loadFileInfo(refPath: Array<RefIdInContext>, file: FileUri, onSuccess: (fileInfo: FileInfoModel) => void): void {
    this.loadFilesInfo(refPath, [file], (filesInfo) => onSuccess(filesInfo[0]));
  }

  getFileDownloadUri(refPath: Array<RefIdInContext>, fileUri: FileUri): string {
    return screenInstanceDownloadUrl(this.instance.id, this.serializeRefPath(refPath), fileUri);
  }

  loadEmailsInfo(requestNumber: number, emails: Array<FileUri>, onSuccess: (requestNumber: number, emailsInfo: Array<EmailBasicInfo>) => void): void {
    if(emails.length == 0) {
      onSuccess(requestNumber, []);
    } else {
      this.emailsSharedService.loadEmailsSummary(requestNumber, emails, onSuccess);
    }
  }

  loadEmailInfo(requestNumber: number, email: FileUri, onSuccess: (requestNumber: number, emailsInfo: EmailBasicInfo) => void): void {
    this.loadEmailsInfo(requestNumber, [email], (requestNumber: number, emailsInfo: Array<EmailBasicInfo>) => onSuccess(requestNumber, emailsInfo[0]));
  }

  loadFlowsInfo(requestNumber: number, flows: Array<AnyFlowId>, onSuccess: (requestNumber: number, flowsInfo: Array<Option<FlowAndTasksInfoForUser>>) => void): void {
    if(flows.length == 0) {
      onSuccess(requestNumber, []);
    } else {
      this.flowService.loadProcessFlowInfoWithTasksForUserByFlowId(flows.map(Typed.of), (flowsInfo: Array<Option<FlowAndTasksInfoForUser>>) => {

        const processesIds = ___(flowsInfo).filter(f => f.isDefined() && f.get().processName.isEmpty()).flatMap(f => f.flatMap(ff => ff.processId()).toArray()).uniqueBy(v => v.id.id).value();

        if(processesIds.length > 0) {
          this.processesNamingQueryService.queryForNames([], processesIds.map(p => p.id), [], (names: ProcessesNamingResponseTransformed) => {
            onSuccess(requestNumber, flowsInfo.map(f => {
              if(f.isDefined()) {
                const flow = f.get();
                if(flow.processName.isEmpty()) {
                  flow.processName = Option.of(names.processes[flow.processId().getOrError("No process id").id.id]);
                  return Some(flow);
                } else {
                  return Some(flow);
                }
              } else {
                return f;
              }
            }));
          });

        } else {
          onSuccess(requestNumber, flowsInfo);
        }
      });
    }
  }

  loadFlowInfo(requestNumber: number, flow: FlowId, onSuccess: (requestNumber: number, flowsInfo: Option<FlowAndTasksInfoForUser>) => void): void {
    this.loadFlowsInfo(requestNumber, [flow], (requestNumber: number, flowsInfo: Array<Option<FlowAndTasksInfoForUser>>) => onSuccess(requestNumber, flowsInfo[0]));
  }

  checkDebugInfoAvailable() {
    this.screenInstanceService.getDebugInfoAvailable(this.instance.id, (debugInfoAvailable: boolean) => {
      this.eventBus.debugInfoAvailableLoaded(debugInfoAvailable);
    });
  }

  loadDebugInfo() {
    this.screenInstanceService.getDebugInfo(this.instance.id, (debugInfo: ScreenDebugInfo) => {
      this.eventBus.debugInfoLoaded(debugInfo);
    });
  }

  private evaluateExpressionCallNo = 0;

  evaluateExpression(expression: string, contextPath: string): number {
    const callNo = this.evaluateExpressionCallNo++;
    this.screenInstanceService.evaluateExpression(this.instance.id, expression, contextPath, (result: BusinessVariable, durationMillis: number) => {
      this.eventBus.expressionEvaluated(callNo, result, durationMillis);
    }, (error: string, durationMillis: number) => {
      this.eventBus.expressionEvaluatedWithError(callNo, error, durationMillis);
    });
    return callNo;
  }

  private onOutputParameterChanged(event: OutputParameterChanged) {
    this.eventBus.outputParameterChanged(event.input, event.variableName, event.value.map(v => Typed.value(v)));
  }

  private onModelContextIdChanged(event: ModelContextIdChanged) {
    this.eventBus.screenModelContextIdChanged(event.externalContextId);
  }

  changeInputParameters(params: Array<[string, string]>) {
    if(!this.terminated) {
      this.screenInstanceService.changeInputParameters(this.instance.id, params, () => {

      });
    }
  }

  submit(edgeId: ProcessEdgeId, onSubmitted: () => void) {
    this.screenInstanceService.submit(this.instance.id, edgeId, () => {
      onSubmitted();
    });
  }


  loadBusinessEntityInfo(id: BusinessEntityIdWithType, onSuccess: (entityInfo: BusinessEntitySummary) => void) {
    this.businessEntitySharedService.loadEntitySummary(id, entity => {
      if(entity.isDefined()) {
        onSuccess(entity.getOrError("No entity"));
      } else {
        toastr.error("Not found");
      }
    })
  }
}
