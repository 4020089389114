import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from "@angular/router";
import {TaskComponent} from "./flow-and-task/components/task.component";
import {FileSelectorComponent} from "./documents/file-selector/file-selector.component";
import {SharedModule} from "../shared/shared.module";
import {CommentAttachmentsListComponent} from "./comments/comment-attachments-list.component";
import {NewCommentComponent} from "./comments/new-comment.component";
import {CommentsComponent} from "./comments/comments.component";
import {FileViewerComponent} from "./documents/file-viewer/file-viewer.component";
import {FlowImportanceSelectorComponent} from "./flow-and-task/components/flow-importance-selector.component";
import {TaskStatusSelectorComponent} from "./flow-and-task/components/task-status-selector.component";
import {TaskDeadlineSelectorComponent} from "./flow-and-task/components/task-deadline-selector.component";
import {FlowSummaryComponent} from "./flow-and-task/components/flow-summary.component";
import {MetadataComponent} from "./metadata/metadata.component";
import {BooleanVariableInputComponent} from "./business-variable-input/boolean-variable-input.component";
import {BusinessEntityVariableInputComponent} from "./business-variable-input/business-entity-variable-input.component";
import {CaseVariableInputComponent} from "./business-variable-input/case-variable-input.component";
import {DateTimeVariableInputComponent} from "./business-variable-input/date-time-variable-input.component";
import {EmailVariableInputComponent} from "./business-variable-input/email-variable-input.component";
import {FileVariableInputComponent} from "./business-variable-input/file-variable-input.component";
import {I18nTextVariableInputComponent} from "./business-variable-input/i18n-text-variable-input.component";
import {IntervalVariableInputComponent} from "./business-variable-input/interval-variable-input.component";
import {NumberVariableInputComponent} from "./business-variable-input/number-variable-input.component";
import {StringVariableInputComponent} from "./business-variable-input/string-variable-input.component";
import {TimeVariableInputComponent} from "./business-variable-input/time-variable-input.component";
import {PasswordVariableInputComponent} from "./business-variable-input/password-variable-input.component";
import {CaseCodeVariableInputComponent} from "./business-variable-input/case-code-variable-input.component";
import {
  OrganizationNodeVariableInputComponent
} from "./business-variable-input/organization-node-variable-input.component";
import {DateVariableInputComponent} from "./business-variable-input/date-variable-input.component";
import {BusinessVariableInputComponent} from "./business-variable-input/business-variable-input.component";
import {MetadataArrayComponent} from "./metadata/metadata-array.component";
import {MetadataObjectComponent} from "./metadata/metadata-object.component";
import {ApplicationsMenuComponent} from "./application/components/applications-menu.component";
import {ApplicationsListComponent} from "./application/components/applications-list.component";
import {TaskFormComponent} from "./flow-and-task/components/task-form.component";
import {MetadataSimpleComponent} from "./metadata/metadata-simple.component";
import {SharedScreenComponent} from "./screen/shared-screen.component";
import {PersonsAssignedComponent} from "./flow-and-task/components/persons-assigned.component";
import {PersonsAssignedSearchComponent} from "./flow-and-task/components/persons-assigned-search.component";
import {EmailViewerComponent} from "./email/email-viewer/email-viewer.component";
import {DataTableComponent} from "./data-table/data-table.component";
import {DataTableFormComponent} from "./data-table/data-table-form.component";
import {DataVisualizationComponent} from "./dashboard/data-visualization.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    ApplicationsMenuComponent,
    ApplicationsListComponent,
    FlowSummaryComponent,
    FlowImportanceSelectorComponent,
    TaskStatusSelectorComponent,
    TaskDeadlineSelectorComponent,
    TaskComponent,
    DataVisualizationComponent,
    PersonsAssignedComponent,
    PersonsAssignedSearchComponent,
    SharedScreenComponent,
    TaskFormComponent,
    FileSelectorComponent,
    CommentAttachmentsListComponent,
    CommentsComponent,
    NewCommentComponent,
    FileViewerComponent,
    EmailViewerComponent,
    MetadataComponent,
    MetadataArrayComponent,
    MetadataObjectComponent,
    MetadataSimpleComponent,
    BooleanVariableInputComponent,
    BusinessEntityVariableInputComponent,
    CaseVariableInputComponent,
    CaseCodeVariableInputComponent,
    DateTimeVariableInputComponent,
    EmailVariableInputComponent,
    FileVariableInputComponent,
    I18nTextVariableInputComponent,
    IntervalVariableInputComponent,
    NumberVariableInputComponent,
    StringVariableInputComponent,
    TimeVariableInputComponent,
    OrganizationNodeVariableInputComponent,
    DateVariableInputComponent,
    BusinessVariableInputComponent,
    DataTableComponent,
    DataTableFormComponent,
    PasswordVariableInputComponent
  ],
  exports: [
    ApplicationsMenuComponent,
    ApplicationsListComponent,
    FormsModule,
    CommonModule,
    RouterModule,
    FlowSummaryComponent,
    FlowImportanceSelectorComponent,
    TaskStatusSelectorComponent,
    TaskDeadlineSelectorComponent,
    TaskComponent,
    DataVisualizationComponent,
    PersonsAssignedComponent,
    SharedScreenComponent,
    TaskFormComponent,
    FileSelectorComponent,
    CommentAttachmentsListComponent,
    CommentsComponent,
    NewCommentComponent,
    FileViewerComponent,
    EmailViewerComponent,
    MetadataComponent,
    BooleanVariableInputComponent,
    BusinessEntityVariableInputComponent,
    CaseVariableInputComponent,
    CaseCodeVariableInputComponent,
    DateTimeVariableInputComponent,
    EmailVariableInputComponent,
    FileVariableInputComponent,
    I18nTextVariableInputComponent,
    IntervalVariableInputComponent,
    NumberVariableInputComponent,
    StringVariableInputComponent,
    TimeVariableInputComponent,
    OrganizationNodeVariableInputComponent,
    DateVariableInputComponent,
    BusinessVariableInputComponent,
    PersonsAssignedSearchComponent,
    DataTableComponent,
    DataTableFormComponent,
    PasswordVariableInputComponent
  ]
})
export class SharedModelModule {
}
