import {
  __,
  AggregateVersion,
  AnyPersonId,
  AnyPersonIdFactory,
  AnyPersonIdHelper,
  EmailId,
  EventBus,
  FileUri,
  FlowId,
  LocalDateTime,
  MailboxId,
  Option,
  PersonId,
  Typed
} from "@utils";
import {BusinessVariable, BusinessVariableType} from "../variables/BusinessVariables";
import {DocumentTypeInfo} from "@shared";
import {EmailAttachmentSummary} from "../../modules/mailbox.module/service/emails.common-model";
import {EmailAttachmentSummaryViewModel} from "../../modules/mailbox.module/mailbox.view-model";

export class EmailInfo {
  constructor(readonly id: EmailId,
              readonly uri: FileUri,
              public senderReceiver: string,
              public received: boolean,
              public subject: string,
              public created: LocalDateTime,
              public exists: boolean,
              public browseUrl: string,
              public mock: boolean) {}

}


export class EmailBasicInfo {
  constructor(readonly id: EmailId,
              readonly uri: FileUri,
              readonly version: AggregateVersion,
              readonly mailboxId: MailboxId,
              readonly subject: string,
              readonly contentSummary: string,
              readonly created: LocalDateTime,
              readonly receivedSent: Option<LocalDateTime>,
              readonly received: boolean,  // received or sent
              readonly senderReceiverName: string,
              readonly senderReceiverAddress: string,
              readonly startedFlow: Array<FlowId>,
              readonly attachments: Array<EmailAttachmentSummary>,
              readonly seen: Array<Typed<AnyPersonId>>,
              readonly labels: Array<string>,
              readonly deleted: boolean
  ) {}

  static copy(other: EmailBasicInfo) {
    return new EmailBasicInfo(EmailId.copy(other.id),
      FileUri.copy(other.uri),
      AggregateVersion.copy(other.version),
      MailboxId.copy(other.mailboxId),
      other.subject,
      other.contentSummary,
      LocalDateTime.copy(other.created),
      Option.copy(other.receivedSent, LocalDateTime.copy),
      other.received,
      other.senderReceiverName,
      other.senderReceiverAddress,
      other.startedFlow.map(FlowId.copy),
      other.attachments.map(EmailAttachmentSummary.copy),
      other.seen.map(AnyPersonIdFactory.copyTyped),
      other.labels,
      other.deleted);
  }

  seenBy(personId: PersonId) {
    return __(this.seen).exists(p => AnyPersonIdHelper.equals(Typed.value(p), personId));
  }
}

export class EmailEventBus extends EventBus {

  emailNotFound(emailUri: FileUri) {}

  attachmentPreviewRequested(attachment: EmailAttachmentSummaryViewModel) {}


  documentTypesLoaded(documentTypes: Array<DocumentTypeInfo>) {}

  // LABELS
  labelAdded(emailId: EmailId, label: string): void {}
  labelDeleted(emailId: EmailId, label: string): void {}


  emailMarkedAsSeen(id: EmailId) {}

  emailDeleted(emailId: EmailId) {}
  emailReverted(emailId: EmailId) {}
  emailDeletedPermanently(emailId: EmailId) {}
}
