import {ScreenComponentViewModel} from "./screen-component.view-model";
import {SectionContainerComponent} from "./section/section-container.component";
import {ModalContainerComponent} from "./modal/modal-container.component";
import {RepeatableContainerComponent} from "./repeatable/repeatable-container.component";
import {TabsContainerComponent} from "./tabs/tabs-container.component";
import {ViewSwitcherContainerComponent} from "./view-switcher/view-switcher-container.component";
import {SingleAttachmentInputComponentComponent} from "./attachment/single-attachment-input-component.component";
import {MultiAttachmentInputComponentComponent} from "./attachment/multi-attachment-input-component.component";
import {LabelComponentComponent} from "./label/label-component.component";
import {ButtonComponentComponent} from "./button/button-component.component";
import {LinkComponentComponent} from "./link/link-component.component";
import {ImageComponentComponent} from "./image/image-component.component";
import {MapComponentComponent} from "./map/map-component.component";
import {CalendarComponentComponent} from "./calendar/calendar-component.component";
import {HtmlComponentComponent} from "./html/html-component.component";
import {DateInputComponentComponent} from "./date/date-input-component.component";
import {TimeInputComponentComponent} from "./date/time-input-component.component";
import {DateTimeInputComponentComponent} from "./date/date-time-input-component.component";
import {DurationInputComponentComponent} from "./date/duration-input-component.component";
import {SingleCheckboxComponentComponent} from "./select/single-checkbox-component.component";
import {MultiCheckboxComponentComponent} from "./select/multi-checkbox-component.component";
import {RadioButtonComponentComponent} from "./select/radio-button-component.component";
import {DropListComponentComponent} from "./select/drop-list-component.component";
import {PersonSelectComponentComponent} from "./person-select/person-select-component.component";
import {TextInputComponentComponent} from "./input/text-input-component.component";
import {NumberInputComponentComponent} from "./input/number-input-component.component";
import {PasswordInputComponentComponent} from "./input/password-input-component.component";
import {SwitchComponentComponent} from "./switch/switch-component.component";
import {TableContainerComponent} from "./table/table-container.component";
import {WidgetComponentComponent} from "./widget/widget-component.component";

export class ContainerChildrenHandler {
  private children: Map<ScreenComponentViewModel, {component: any, inputs: {viewModel: any}}> = new Map<ScreenComponentViewModel, {component: any, inputs: {viewModel: any}}>();

  private components = new Map<string, any>([
    ['Section', SectionContainerComponent],
    ['Modal', ModalContainerComponent],
    ['Repeatable', RepeatableContainerComponent],
    ['Tabs', TabsContainerComponent],
    ['ViewSwitcher', ViewSwitcherContainerComponent],
    ['Table', TableContainerComponent],
    ['SingleAttachmentInput', SingleAttachmentInputComponentComponent],
    ['MultiAttachmentInput', MultiAttachmentInputComponentComponent],
    ['Label', LabelComponentComponent],
    ['Button', ButtonComponentComponent],
    ['Link', LinkComponentComponent],
    ['Image', ImageComponentComponent],
    ['Map', MapComponentComponent],
    ['Calendar', CalendarComponentComponent],
    ['Html', HtmlComponentComponent],
    ['DateInput', DateInputComponentComponent],
    ['TimeInput', TimeInputComponentComponent],
    ['DateTimeInput', DateTimeInputComponentComponent],
    ['DurationInput', DurationInputComponentComponent],
    ['SingleCheckbox', SingleCheckboxComponentComponent],
    ['MultiCheckbox', MultiCheckboxComponentComponent],
    ['RadioButton', RadioButtonComponentComponent],
    ['DropList', DropListComponentComponent],
    ['PersonSelect', PersonSelectComponentComponent],
    ['TextInput', TextInputComponentComponent],
    ['NumberInput', NumberInputComponentComponent],
    ['PasswordInput', PasswordInputComponentComponent],
    ['Switch', SwitchComponentComponent],
    ['Widget', WidgetComponentComponent]
  ]);

  componentOf(viewModel: ScreenComponentViewModel): any {
    let componentWithInjector = this.children.get(viewModel);
    if (componentWithInjector === undefined) {
      componentWithInjector = this.initComponent(viewModel);
    }
    return componentWithInjector.component;
  }

  initComponent(viewModel: ScreenComponentViewModel) {
    const component = this.components.get(viewModel.typeName);
    const componentWithInjector = {component: component, inputs: {viewModel: viewModel}};
    this.children.set(viewModel, componentWithInjector);

    return componentWithInjector;
  }


  inputsOf(viewModel: ScreenComponentViewModel) {
    let componentWithInjector = this.children.get(viewModel);
    if (componentWithInjector === undefined) {
      componentWithInjector = this.initComponent(viewModel);
    }
    return componentWithInjector.inputs;
  }
}
