import {
  __,
  ___,
  AggregateId,
  AnyFlowId,
  AnyFlowIdHelper,
  AnyPersonId,
  AnyPersonIdHelper,
  documentRepositoryUri,
  FileUri,
  FlowId,
  FormElementId,
  FormElementRefId,
  LocalDateTime,
  None,
  ObjectId,
  Option,
  PersonId,
  ScreenInstanceId,
  Some,
  toastr,
  Typed
} from "@utils";
import {
  ArrayVariable,
  BusinessVariable,
  BusinessVariablesUtil,
  BusinessVariableType,
  ContextPath,
  ContextVariable,
  CursorInfo,
  EmailEventBus,
  EmailPreviewRequest,
  FilePreviewRequest,
  ObjectVariable,
  ProcessNodeId,
  RootVariable,
  RootVariableType,
  RootVariableWithType,
  StringVariableValidation,
  TaskIdentifier,
  TasksEventBus
} from "@shared-model";
import {FormSectionId, FormSectionRefId, ViewableFile, ViewableFileUrl} from "@shared"
import {FormDiff} from "./FormDiff";
import {TaskEventBus} from "./TaskEventBus";
import {SectionValidationError, VariableValidationError} from "./model/ProcessFlowValidationErrors";
import {TaskServerModel} from "./TaskServerModel";
import {FormHelper} from "./FormHelper";
import {
  AttachmentsUrlViewModel,
  ElementWithData,
  SimpleElementWithDataInterface,
  StaticElementWithData,
  VariableWithType
} from "./FormData";
import {EmailAttachmentSummaryViewModel} from "../mailbox.module/mailbox.view-model";
import {
  ActionButton,
  ActionButtonRef,
  Checkbox,
  DropList,
  FormElement,
  FormElementRef,
  FormSection,
  FormSectionInfo,
  FormSectionRef,
  InputElement,
  InputElementRef,
  Label,
  LabelRef,
  RadioButton,
  StaticElement,
  StaticElementRef
} from "../process-common.module";
import {FormFieldsState, FormFieldState, TaskModel} from "./model/ProcessFlow";
import {ModelContextIdChanged} from "../screen.module";


export class ElementWithSectionIndex {
    constructor(readonly sectionIndex: number, readonly element: InputFieldViewModel){}
  }

	export class TaskInfo {
		constructor(readonly flowId: AnyFlowId,
		            readonly nodeId: ProcessNodeId,
		            readonly cursorInfo: Option<CursorInfo>,
                readonly authorizeByFlow: boolean) {}
	}

  export class TaskFormConfig {
    static fixScale = 14 / 16;
    static readonly gridRemSize = 1.5 * TaskFormConfig.fixScale;
    static readonly gridColumns = 42;
    static readonly formHeaderGridHeight = 2;
    static readonly elementsMargin = 1 / 16; // 16 - default rem size so by default it's 1 px
    static readonly formGridWidth = 42;
  }


  export class CssRect {
    constructor(readonly left: string,
                readonly top: string,
                readonly width: string,
                readonly height: string,
                readonly index: number) {}

    static getFloatValue(withRem: string) {
      return parseFloat(withRem.slice(0, withRem.length - 3))
    }
  }


  export class StaticFieldViewModel<ELEMENT extends StaticElement> {

    public readOnly: boolean;

    constructor(readonly parentTask: TaskModel,
                public cssPosition: CssRect,
                readonly elementRef: StaticElementRef,
                readonly element: ELEMENT,
                readonly sectionId: FormSectionId,
                public elementState: FormFieldState,
                private ownReadOnly: boolean,
                private inheritedReadOnly: boolean,
                private formReadOnly: boolean,
                public hidden: boolean,
                readonly gridShiftY: number,
                public contextObjectId: Option<ObjectId>,
                readonly tooltipVisible: boolean) {
      this.readOnly = this.ownReadOnly || this.inheritedReadOnly || this.formReadOnly;
      if(this.element.tooltip.notEmpty() && this.element instanceof Label) {
        this.shiftLabelWithTooltip();
      }

    }

    private shiftLabelWithTooltip() {
      console.log("shiftLabelWithTooltip");
      this.cssPosition = new CssRect(CssRect.getFloatValue(this.cssPosition.left) - 2.1 + "rem",
        this.cssPosition.top, this.cssPosition.width, this.cssPosition.height, this.cssPosition.index)
    }

    setOwnReadOnly(ownReadOnly: boolean) {
      this.ownReadOnly = ownReadOnly;
      this.readOnly = this.ownReadOnly || this.formReadOnly;
    }

    setInheritedReadOnly(inheritedReadOnly: boolean, formReadOnly: boolean) {
      this.inheritedReadOnly = inheritedReadOnly;
      this.formReadOnly = formReadOnly;
      this.readOnly = this.ownReadOnly || this.inheritedReadOnly || this.formReadOnly;
    }


  }

  export class InputFieldViewModel {

    public readOnly: boolean;
    public wasUpdated: boolean;

    constructor(readonly parentTask: TaskModel,
    	          readonly eventBus: TaskEventBus,
                public cssPosition: CssRect,
                readonly elementRef: InputElementRef,
                readonly element: InputElement,
                readonly sectionRefId: FormSectionRefId,
                readonly sectionId: FormSectionId,
                public contextObjectId: Option<ObjectId>,
                readonly variableWithType: VariableWithType,
                public elementState: FormFieldState,
                private ownReadOnly: boolean,
                private inheritedReadOnly: boolean,
                private formReadOnly: boolean,
                public required: boolean,
                public hidden: boolean,
                public gridShiftY: number,
                public errorMessages: Array<VariableValidationError>,
                public labelVisible: boolean,
                public willBeUpdated: boolean) {
      this.readOnly = this.ownReadOnly || this.inheritedReadOnly;
      this.wasUpdated = false;

      this.eventBus.on(this.eventBus.inputFieldValidationUpdated, (flowId: AnyFlowId, nodeId: ProcessNodeId,
                                                                   elementRefId: FormElementRefId,
                                                                   contextId: Option<ObjectId>,
                                                                   error: VariableValidationError) => {
        if(AnyFlowIdHelper.equals(this.parentTask.flowIdUnwrapped(), flowId) && this.parentTask.nodeId === nodeId && this.elementRef.id.id === elementRefId.id) {
          if(contextId.equals(this.contextObjectId, (a, b) => a.id === b.id)) {
            this.errorMessages = [error];
          }
        }
      })
    }

    labelIsVisible() {
      return this.elementRef.label.notEmpty() && this.elementRef.labelPosition.isVisible();
    }

    setOwnReadOnly(ownReadOnly: boolean) {
      this.ownReadOnly = ownReadOnly;
      this.readOnly = this.ownReadOnly || this.inheritedReadOnly;
    }

    setInheritedReadOnly(inheritedReadOnly: boolean, formReadOnly: boolean) {
      this.inheritedReadOnly = inheritedReadOnly;
      this.formReadOnly = formReadOnly;
      this.readOnly = this.ownReadOnly || this.inheritedReadOnly;
    }

    resetErrors() {
      this.errorMessages = [];
    }

    getErrorTooltip(): string | undefined {
      if (this.errorMessages.length === 0) {
        return undefined;
      } else {
        return this.errorMessages.map(e => e.translate()).join('\n');
      }
    }

    getMask(): Option<string> {
      return this.element.validation.flatMap( typedValidation => {
        const validation = Typed.value(typedValidation);
        if (validation instanceof StringVariableValidation) {
          return (<StringVariableValidation> validation).mask;
        } else {
          return None();
        }
      });
    }
	}


  export class FieldsAreaViewModel {
    constructor(readonly eventBus: TaskEventBus,
                readonly taskInfo: () => TaskInfo,
                readonly formDiff: FormDiff,
                readonly gridHeight: number,
                public cssHeight: string,
                public elements: Array<InputFieldViewModel | StaticFieldViewModel<StaticElement>>,
                public readOnly: boolean,
                public formReadOnly: boolean,
                public attachmentsUrlsViewModel: AttachmentsUrlViewModel) {
    }

    elementById(id: FormElementId): Option<InputFieldViewModel | StaticFieldViewModel<StaticElement>> {
      return __(this.elements).find(e => e.element.id.id === id.id);
    }

    inputElements(): Array<InputFieldViewModel> {
      return __(this.elements).flatMap( element => {
        if (element instanceof InputFieldViewModel) {
          return [element];
        } else {
          return [];
        }
      })
    }

    staticElements(): Array<StaticFieldViewModel<StaticElement>> {
      return __(this.elements).flatMap( element => {
        if (element instanceof StaticFieldViewModel) {
          return [element];
        } else {
          return [];
        }
      })
    }

    setReadOnly(sectionReadOnly: boolean, formReadOnly: boolean) {
      this.readOnly = sectionReadOnly;
      this.formReadOnly = formReadOnly;
      this.elements.forEach(e => e.setInheritedReadOnly(sectionReadOnly, formReadOnly));
    }

    allRequiredInputFilled() {
      return !__(this.elements).exists(input => input instanceof InputFieldViewModel && input.required && !input.hidden && input.variableWithType.variableIsEmpty());
    }

  }

  export abstract class FormSectionViewModel {
    abstract sectionId: FormSectionId;
    abstract sectionRef: FormSectionRef;
    abstract visible: boolean;
    abstract isSingularSection: boolean;
    abstract isRepeatableSection: boolean;
    abstract name: string;
    abstract headerVisible: boolean;
    abstract expanded: boolean;
    errors: Array<string> = [];
    abstract errorMessages: Array<SectionValidationError>;

    abstract setformReadOnly(formReadOnly: boolean): void;

    abstract asSingularSection(): SingularSectionViewModel;
    abstract asRepeatableSection(): RepeatableSectionViewModel;

    abstract wasUpdated: boolean;

    toggleExpanded() {
      this.expanded = !this.expanded;
    }

    abstract getErrorTooltip(): string | undefined;
  }

  export class SingularSectionViewModel extends FormSectionViewModel {
    readonly isSingularSection = true;
    readonly isRepeatableSection = false;

    public readOnly: boolean = false;

    public wasUpdated: boolean = false;

    errorMessages: Array<SectionValidationError> = [];

    constructor(
      override readonly sectionId: FormSectionId,
      override readonly sectionRef: FormSectionRef,
      public visible: boolean,
      readonly name: string,
      readonly headerVisible: boolean,
      public expanded: boolean,
      private ownReadOnly: boolean,
      private formReadOnly: boolean,
      public fieldsArea: FieldsAreaViewModel) {
      super();
    }

    setformReadOnly(formReadOnly: boolean): void {
      this.formReadOnly = formReadOnly;
      this.readOnly = this.ownReadOnly || this.formReadOnly;
      this.fieldsArea.setReadOnly(this.ownReadOnly, this.formReadOnly);
    }

    asSingularSection(): SingularSectionViewModel {
      return this;
    }

    asRepeatableSection(): RepeatableSectionViewModel {
      throw new Error("Not a repeatable section [Singular Section]");
    }

    getErrorTooltip(): string | undefined {
      if (this.errorMessages.length === 0) {
        return undefined;
      } else {
        return this.errorMessages.map(e => e.translate()).join('\n');
      }
    }

  }



  export class RepeatableSectionViewModel extends FormSectionViewModel {
    readonly isSingularSection = false;
    readonly isRepeatableSection = true;

    public finalReadOnly: boolean;
    public wasUpdated: boolean = false;

    public errorMessages: Array<SectionValidationError> = [];

    constructor(
      readonly parentTask: TaskModel,
      readonly nodeId: ProcessNodeId,
      readonly eventBus: TaskEventBus,
      override readonly sectionId: FormSectionId,
      readonly section: FormSection,
      override readonly sectionRef: FormSectionRef,
      public visible: boolean,
      readonly name: string,
      readonly headerVisible: boolean,
      readonly forEachVariableName: string,
      public expanded: boolean,
      public ownReadOnly: boolean,
      public formReadOnly: boolean,
      public addNewEntryVisible: boolean,
      public entryTemplate: RepeatableSectionEntryTemplate,
      public entries: Array<RepeatableSectionEntryViewModel>,
      readonly tableMode: boolean) {
      super();
      this.finalReadOnly = ownReadOnly || formReadOnly;

      eventBus.on(eventBus.formRepeatableSectionValidationUpdated, (flowId: AnyFlowId, nodeId: ProcessNodeId,
      	                                                            sectionRefId: FormSectionRefId,
                                                                    error: SectionValidationError) => {
        if(AnyFlowIdHelper.equals(this.parentTask.flowIdUnwrapped(), flowId) && this.nodeId === nodeId && sectionRef.id.id === sectionRefId.id) {
          this.errorMessages = [error];
        }
      })
    }

	  resetErrors() {
		  this.errorMessages = [];
	  }

    setformReadOnly(formReadOnly: boolean): void {
      this.formReadOnly = formReadOnly;
      this.finalReadOnly = this.ownReadOnly || this.formReadOnly;
      this.entries.forEach(entry => entry.setReadOnly(this.ownReadOnly, this.formReadOnly));
    }

    asSingularSection(): SingularSectionViewModel {
      throw new Error("Not a singaluar section [Repeatable Section]");
    }

    asRepeatableSection(): RepeatableSectionViewModel {
      return this;
    }

    getErrorTooltip(): string | undefined {
        if (this.errorMessages.length === 0) {
            return undefined;
        } else {
            return this.errorMessages.map(e => e.translate()).join('\n');
        }
    }

  }


  export class InputElementTemplate {
    constructor(readonly elementRef: InputElementRef,
                readonly element: InputElement,
                readonly sectionId: FormSectionId,
                readonly variableType: BusinessVariableType) {
    }
  }

  export class StaticElementTemplate {
    constructor(readonly elementRef: StaticElementRef,
                readonly element: FormElement,
                readonly sectionId: FormSectionId) {
    }
  }

  export class RepeatableSectionEntryTemplate {
    constructor(
      readonly sectionGridHeight: number,
      readonly inputElements: Array<InputElementTemplate>,
      readonly staticElements: Array<StaticElementTemplate>,
      readonly tableMode: boolean
    ) {}
  }

  export class RepeatableSectionEntryViewModel {

    finalReadOnly: boolean;

    constructor(
      public sectionIndex: number,
      public contextObjectId: ObjectId,
      public fieldsArea: FieldsAreaViewModel,
      public removeEntryVisible: boolean,
      private ownReadOnly: boolean,
      private formReadOnly: boolean,
    ) {
      this.finalReadOnly = this.ownReadOnly || this.formReadOnly;
    }

    updateSectionIndex(index: number) {
      this.sectionIndex = index;
    }

    setReadOnly(ownReadOnly: boolean, formReadOnly: boolean) {
      this.ownReadOnly = ownReadOnly;
      this.formReadOnly = formReadOnly;
      this.finalReadOnly = this.ownReadOnly || this.formReadOnly;
      this.fieldsArea.setReadOnly(this.ownReadOnly, this.formReadOnly);
    }
  }

  export class TaskFormViewModel {

	  private subscriptions: Array<number> = [];
    private tasksSubscriptions: Array<number> = [];
    public sections: Array<FormSectionViewModel> = [];
    public visibleSections: Array<FormSectionViewModel> = [];
    public formDiff: FormDiff;
    public formCssWidth = TaskFormConfig.formGridWidth * TaskFormConfig.gridRemSize + 4 + "rem"; //4 - form horizontal padding
    public emailViewerVisible: boolean = false;
    public emailEventBus: EmailEventBus = new EmailEventBus();
    public viewableEmail?: FileUri;
    public fileViewerVisible: boolean = false;
    public viewableFiles: Array<ViewableFile> = [];
    public fileViewerIndex: number = 0;
    public currentInputSelected: Option<InputFieldViewModel> = None();

    private deletedRows: Array<string> = []; // used to filter out events generated by me

    private flowCode: string = "";
    private started: boolean = false;
    private completed: boolean = false;
    private personsAssigned: Array<AnyPersonId> = [];
    public readOnly: boolean = false;
    screenDisabled: boolean = false;
    screenInstanceId?: ScreenInstanceId;

    constructor( readonly eventBus: TaskEventBus,
                 readonly tasksEventBus: TasksEventBus,
                 readonly taskServerModel: TaskServerModel, // this might be null for preview only
                 private currentPersonId: PersonId,
                 readonly task: TaskModel,
                 readonly attachmentsUrlsViewModel: AttachmentsUrlViewModel,
                 readonly authorizeByFlow: boolean,
                 readonly initFlowScreen: () => Promise<ScreenInstanceId>,
                 readonly onTaskCompleted: () => void) {

      this.screenDisabled = !task.screenBased;

	    this.formDiff = new FormDiff(eventBus);

	    this.subscriptions.push(eventBus.on(eventBus.taskAutoUpdated, (flowId: AnyFlowId,
	                                                modifiedVariables: ContextVariable<BusinessVariable>[],
	                                                variablesCleared: ContextPath[],
	                                                variablesEvaluated: ContextPath[]) => {
		    if (AnyFlowIdHelper.equals(task.flowIdUnwrapped(), flowId)) {
			    this.onTaskAutoUpdated(modifiedVariables, variablesCleared, variablesEvaluated, []);
		    }
	    }));

	    this.subscriptions.push(eventBus.on(eventBus.variablesWillBeEvaluated, (flowId: AnyFlowId, variables: ContextPath[]) => {
        if (AnyFlowIdHelper.equals(task.flowIdUnwrapped(), flowId)) {
          this.onTaskAutoUpdated([], [], [], variables);
        }
      }));

      this.subscriptions.push(eventBus.on(eventBus.sectionVisibilityChanged, (flowId: AnyFlowId,
                                                                                   shownSections: FormSectionId[],
                                                                                   hiddenSections: FormSectionId[]) => {
        if (AnyFlowIdHelper.equals(task.flowIdUnwrapped(), flowId)) {
          this.onSectionVisibilityChanged(shownSections, hiddenSections);
        }
      }));

      this.subscriptions.push(eventBus.on(eventBus.fieldStateUpdated, (flowId: AnyFlowId,
                                                                            elementId: FormElementId,
                                                                            contextObjectId: Option<ObjectId>,
                                                                            propertyName: string,
                                                                            value: Option<BusinessVariable>) => {
        if (AnyFlowIdHelper.equals(task.flowIdUnwrapped(), flowId)) {
          this.onFieldStateUpdated(elementId, contextObjectId, propertyName, value);
        }
      }));

      this.subscriptions.push(eventBus.on(eventBus.sectionLengthAutoUpdated, (flowId: AnyFlowId, sectionVariableName: string, removedRows: Array<ObjectId>, addedRows: Array<ObjectVariable>) => {
        if (AnyFlowIdHelper.equals(task.flowIdUnwrapped(), flowId)) {
          this.onSectionLengthAutoUpdated(sectionVariableName, removedRows, addedRows);
        }
	    }));

      this.subscriptions.push(eventBus.on(eventBus.emailViewRequested, (attachmentInputField: InputFieldViewModel, emailUri: FileUri) => {
        this.currentInputSelected = Some(attachmentInputField);
        this.emailViewerVisible = true;
        this.viewableEmail = emailUri;
        this.onCloseFileViewer();
      }));

      this.emailEventBus.on(this.emailEventBus.attachmentPreviewRequested, (attachment: EmailAttachmentSummaryViewModel) => {
        //this.currentInputSelected = ...;  this should have been set in emailViewRequested event handler
        this.fileViewerVisible = true;
        this.viewableFiles = [new ViewableFile(attachment.name, attachment.size, None(), new ViewableFileUrl(documentRepositoryUri(attachment.uri)), false, LocalDateTime.now(), attachment.uri,
          false, None(), None(), None(), None(), false)];
        this.fileViewerIndex = 0;
      });

      this.eventBus.on(this.eventBus.fileViewerCloseRequested, () => {
        if(!this.viewableEmail) {
          this.currentInputSelected = None();
        }
        this.fileViewerVisible = false;
      });

      this.subscriptions.push(eventBus.on(eventBus.fileViewRequested, (attachmentInputField: InputFieldViewModel, files: Array<ViewableFile>, index: number) => {
		    this.currentInputSelected = Some(attachmentInputField);
		    this.fileViewerVisible = true;
		    this.viewableFiles = files;
		    this.fileViewerIndex = index;
        this.onCloseEmailViewer();
	    }));

      this.subscriptions.push(eventBus.on(eventBus.fileListChanged, (attachmentInputField: InputFieldViewModel, files: Array<ViewableFile>) => {
		    if (this.currentInputSelected.contains(attachmentInputField)) {
			    this.viewableFiles = files;
			    if (files.length > 0) {
				    this.fileViewerIndex = 0;
			    } else {
				    this.currentInputSelected = None();
			    }
		    }
	    }));

      this.subscriptions.push(eventBus.on(eventBus.taskStarted, (taskId: TaskIdentifier) => {
		    if (task.is(taskId)) {
			    this.onTaskStarted();
		    }
	    }));

      this.subscriptions.push(eventBus.on(eventBus.taskStopped, (taskId: TaskIdentifier) => {
        if (task.is(taskId)) {
          this.onTaskStopped();
        }
      }));

      this.tasksSubscriptions.push(tasksEventBus.on(tasksEventBus.taskAssigned, (taskId: TaskIdentifier, personId: AnyPersonId) => {
		    if (task.is(taskId)) {
			    this.onTaskAssigned(personId);
		    }
	    }));

      this.tasksSubscriptions.push(tasksEventBus.on(tasksEventBus.taskUnassigned, (taskId: TaskIdentifier, personId: AnyPersonId) => {
		    if (task.is(taskId)) {
			    this.onTaskUnassigned(personId);
		    }
	    }));

      this.tasksSubscriptions.push(tasksEventBus.on(tasksEventBus.taskSubmitted, (flowId: AnyFlowId, nodeId: ProcessNodeId) => {
		    if (task.is(TaskIdentifier.of(flowId, nodeId))) {
			    this.onTaskSubmitted();
		    }
	    }));

      this.tasksSubscriptions.push(tasksEventBus.on(tasksEventBus.taskRedirected, (flowId: AnyFlowId, nodeId: ProcessNodeId) => {
		    if (task.is(TaskIdentifier.of(flowId, nodeId))) {
			    this.onTaskRedirected();
		    }
	    }));

      this.subscriptions.push(eventBus.on(eventBus.taskUpdateFailed, (labels: Option<Array<string>>, personsAssigned: Option<Array<AnyPersonId>>, started: Option<Option<LocalDateTime>>) => {
		    this.revertTaskChanges(labels, personsAssigned, started);
	    }));

      this.subscriptions.push(eventBus.on(eventBus.formElementHeightChanged, () => {
		    this.onFormElementHeightChanged();
	    }));

      this.subscriptions.push(eventBus.on(eventBus.taskUpdated, (started: Option<LocalDateTime>) => this.onTaskUpdated(started)));

      this.tasksSubscriptions.push(tasksEventBus.on(tasksEventBus.flowIdChanged, (oldId: AnyFlowId, newId: AnyFlowId) => this.onFlowIdChanged(oldId, newId)));


	    this.init(task);
	  }

    onCloseEmailViewer() {
      this.currentInputSelected = None();
      this.emailViewerVisible = false;
      this.viewableEmail = undefined;
    }

    onCloseFileViewer() {
      this.fileViewerVisible = false;
      this.viewableFiles = [];
      this.fileViewerIndex = 0;
    }

	  public destroy() {
      this.subscriptions.forEach( sub => {
        this.eventBus.unsubscribe(sub);
      });
      this.tasksSubscriptions.forEach( sub => {
        this.tasksEventBus.unsubscribe(sub);
      });
    }

    private onFlowIdChanged(oldId: AnyFlowId, newId: AnyFlowId) {
      if (AnyFlowIdHelper.equals(this.attachmentsUrlsViewModel.flowId, oldId)) {
        this.attachmentsUrlsViewModel.flowId = newId;
      }
      if (AnyFlowIdHelper.equals(this.task.flowIdUnwrapped(), oldId)) {
        this.task.flowId = Typed.of(newId);
      }
    }

    private onTaskUpdated(started: Option<LocalDateTime>) {
      this.started = started.isDefined();
      this.updateReadOnly();
      toastr.info(this.completed+"");
    }

    private updateReadOnly(): void {
      this.readOnly = !((this.currentPersonId && PersonId.isWebClient(this.currentPersonId) || __(this.personsAssigned).exists(p => AnyPersonIdHelper.equals(p, this.currentPersonId))) &&
        (this.flowCode.length === 0 || !this.completed));
      this.sections.forEach(s => s.setformReadOnly(this.readOnly));
      this.eventBus.taskRequiredFieldsFilled(this.allRequiredFieldsFilled());
    }

    revertTaskChanges(labels: Option<Array<string>>, personsAssigned: Option<Array<AnyPersonId>>, started: Option<Option<LocalDateTime>>) {
      personsAssigned.forEach(p => this.personsAssigned = p);
      started.forEach(s => this.started = s.isDefined());
    }

    init(task: TaskModel) {

      if(!this.screenDisabled) {
        this.initFlowScreen().then((screenInstanceId: ScreenInstanceId) => {
            this.screenInstanceId = screenInstanceId;
        });
      }

      this.flowCode = task.flowCode;
      this.started = task.started.isDefined();
      this.completed = task.completed.isDefined();
      this.personsAssigned = task.personsAssignedUnwrapped();

      this.readOnly = !((this.currentPersonId && PersonId.isWebClient(this.currentPersonId) || __(this.personsAssigned).exists(p => AnyPersonIdHelper.equals(p, this.currentPersonId))) &&
        (this.flowCode.length === 0 || !this.completed));

      this.sections = task.formSections
        .map(sectionInfo => {
          const sectionVisible = this.sectionIsVisible(sectionInfo.section, task.visibleFormSections);
          if(sectionInfo.section.forEachVariableName.isDefined()) {
            return this.initRepeatableSectionViewModel(sectionInfo, sectionVisible, task.formElementsUnwrapped(), task.formVariables, task.fieldsState, this.readOnly);
          } else {
            return this.initSingularSectionViewModel(sectionInfo, sectionVisible, task.formElementsUnwrapped(), task.formVariables, task.fieldsState, this.readOnly);
          }
        });

      this.formDiff.updateDiff(this.sections);

      this.visibleSections = this.sections.filter(s => s.visible);

      this.eventBus.taskRequiredFieldsFilled(this.allRequiredFieldsFilled());
    }

    allRequiredFieldsFilled() {
      const inputFields = this.extractInputFields();

      return !__(inputFields).exists(element => element.required && !element.readOnly && !element.hidden && element.variableWithType.variableIsEmpty());
    }

    repeatableSections(): Array<RepeatableSectionViewModel> {
      return this.sections.filter(s => s.isRepeatableSection).map(s => <RepeatableSectionViewModel>s)
    }

    onTaskAssigned(personId: AnyPersonId) {
      this.personsAssigned.push(personId);
      this.updateReadOnly();
    }

    onTaskAssignedOverride(persons: Array<AnyPersonId>) {
      this.personsAssigned = persons;
      this.updateReadOnly();
    }

    onTaskUnassigned(personId: AnyPersonId) {
      this.personsAssigned = this.personsAssigned.filter(p => !p.equals(personId));
      this.updateReadOnly();
    }

    onTaskStarted() {
      this.started = true;
      this.updateReadOnly();
    }

    onTaskStopped() {
      this.started = false;
      this.updateReadOnly();
    }

    onTaskSubmitted() {
      this.completed = true;
      this.onTaskCompleted();
      this.updateReadOnly();
    }

    onTaskRedirected() {
      this.completed = true;
      this.onTaskCompleted();
      this.updateReadOnly();
    }

    onFieldStateUpdated(elementId: FormElementId,
                        contextObjectId: Option<ObjectId>,
                        propertyName: string,
                        value: Option<BusinessVariable>) {

      this.sections.forEach((s: FormSectionViewModel) => {

        if (s.isSingularSection) {
          const section = <SingularSectionViewModel> s;
          section.fieldsArea.elementById(elementId).forEach(e => this.updateElement(e, propertyName, value));
        } else if (s.isRepeatableSection) {
          const section = <RepeatableSectionViewModel> s;
          section.entries.forEach((entry: RepeatableSectionEntryViewModel) => {
            if(contextObjectId.exists(oId => oId.id == entry.contextObjectId.id)) {
              entry.fieldsArea.elementById(elementId).forEach(e => this.updateElement(e, propertyName, value));
            }
          });
        }
      });

    }

    private updateElement(element: InputFieldViewModel | StaticFieldViewModel<StaticElement>,
                          propertyName: string,
                          value: Option<BusinessVariable>) {
      element.elementState.updateProperty(propertyName, value);

      element.hidden = this.isElementHidden(element.elementRef, element.elementState);

      if(element instanceof InputFieldViewModel) {
        element.setOwnReadOnly(this.isElementReadonly(element.elementRef, element.elementState));
      } else if(element.element instanceof ActionButton) {
        element.setOwnReadOnly(this.isButtonReadonly(<ActionButtonRef>element.elementRef, element.elementState));
      }



      if(element instanceof InputFieldViewModel) {
        element.required = !element.hidden && !element.readOnly && this.isElementRequired(element.elementRef, element.elementState);
      } else if(element instanceof StaticFieldViewModel) {
        // do nothing
      }
    }

    onSectionVisibilityChanged(shownSections: FormSectionId[], hiddenSections: FormSectionId[]) {
      this.sections.forEach(section => {
        if (__(hiddenSections).exists(id => id.id === section.sectionId.id)) {
          section.visible = false;
        } else if (__(shownSections).exists(id => id.id === section.sectionId.id)) {
          section.visible = true;
        } // otherwise no change
      });

      this.visibleSections = this.sections.filter(s => s.visible);
    }

    onTaskAutoUpdated(modifiedVariables: ContextVariable<BusinessVariable>[],
                      variablesCleared: ContextPath[],
                      variablesEvaluated: ContextPath[],
                      variablesToEvaluate: ContextPath[]) {

        this.sections.forEach((s: FormSectionViewModel) => {

          if (s.isSingularSection) {
            const section = <SingularSectionViewModel> s;

            section.fieldsArea.elements.forEach(element => {
              if (element instanceof InputFieldViewModel) {

                const newVariable: Option<RootVariable<BusinessVariable>> =
                  __(modifiedVariables).find(v => v.isRootVariable() && v.location.lastName() === element.variableWithType.path.lastName()).map(v => new RootVariable(v.location.lastName(), v.variable));
                if (newVariable.isDefined()) {
                  element.variableWithType.variable = Some(newVariable.get().unwrappedVariable());
                }

                const clearedVariable: boolean = variablesCleared.filter(v => v.isRoot() && v.location.lastName() === element.variableWithType.path.lastName()).length > 0;
                if (clearedVariable) {
                  element.variableWithType.variable = None();
                }

                const justUpdated = variablesEvaluated.filter(v => v.isRoot() && v.location.lastName() === element.variableWithType.path.lastName()).length > 0;
                if(justUpdated) {
                  element.willBeUpdated = false;
                }
                const willBeUpdated = variablesToEvaluate.filter(v => v.isRoot() && v.location.lastName() === element.variableWithType.path.lastName()).length > 0;
                if(willBeUpdated) {
                  element.willBeUpdated = true;
                }

              } else {
               // do nothing
              }
            });

            // this.task.summaryElements = this.task.summaryElements.
            //
            //   section.fieldsArea.inputElements().filter(v => v.elementRef.visibleInSummary && !v.variableWithType.variableIsEmpty())
            //   .map(v => new VariableValueWithLabel(v.elementRef.label, Typed.of(v.variableWithType.variable.get())));

          } else if (s.isRepeatableSection) {
            const section = <RepeatableSectionViewModel> s;

            const variable = __(modifiedVariables)
              .find(v => v.isRootVariable() && v.location.headName() === section.forEachVariableName)
              .map(v => new RootVariable(v.location.lastName(), v.variable));
            const clearedVariable = __(variablesCleared).find(v => v.isRoot() && v.location.headName() === section.forEachVariableName);


            if (variable.isDefined()) {
              const array = (<ArrayVariable<BusinessVariable>>variable.get().unwrappedVariable());
              const arrayValue = array.unwrappedValue();
              const sectionContextObjectsIds = arrayValue.map(e => (<ObjectVariable>e).id);


              const addNewEntryVisible = section.section.lengthEditable && (section.section.maximumLength.isEmpty() || section.section.maximumLength.get() > arrayValue.length);
              const removeEntryVisible = section.section.lengthEditable && (section.section.minimumLength.isEmpty() || section.section.minimumLength.get() < arrayValue.length);

              section.addNewEntryVisible = addNewEntryVisible;

              section.entries = sectionContextObjectsIds.map((contextObjectId, index) => {
                return this.initRepeatableSectionEntry(section.sectionId, section.sectionRef, index, contextObjectId, section.entryTemplate, variable.toArray(), this.task.fieldsState, removeEntryVisible, section.ownReadOnly)
              });

              // sectionInstanceFor(section.sectionRef, section.section, index, scope.formElements, scope.variablesDataWithTypes, fieldsState);
            } else if (clearedVariable.isDefined()) {
              section.entries = [];
            }

            section.entries.forEach(sectionEntry => {

              const foundVariables = modifiedVariables.filter(v => v.context.exists(o => o.id === sectionEntry.contextObjectId.id));
              foundVariables.forEach(foundVariable => {
                const newVariable: Option<ContextVariable<BusinessVariable>> = <Option<ContextVariable<BusinessVariable>>> Option.of(foundVariable);
                if (newVariable.isDefined()) {
                  const newVar = newVariable.get();
                  const element = __(sectionEntry.fieldsArea.inputElements()).find(elem => elem.variableWithType.path.lastName() === newVar.location.lastName());
                  if (element.isDefined()) {
                    element.get().variableWithType.variable = Some(newVar.unwrappedVariable());
                  } // otherwise it's update of non related data
                }
              });

              const clearedFields = variablesCleared.filter(v => v.context.exists(o => o.id === sectionEntry.contextObjectId.id));
              clearedFields.forEach(clearedField => {
                const element = __(sectionEntry.fieldsArea.inputElements()).find(elem => elem.variableWithType.path.lastName() === clearedField.location.lastName());
                if (element.isDefined()) {
                  element.get().variableWithType.variable = None();
                }// otherwise it's update of non related data
              });

              const evaluatedFields = variablesEvaluated.filter(v => v.context.exists(o => o.id === sectionEntry.contextObjectId.id));
              evaluatedFields.forEach(field => {
                const element = __(sectionEntry.fieldsArea.inputElements()).find(elem => elem.variableWithType.path.lastName() === field.location.lastName());
                if (element.isDefined()) {
                  element.get().willBeUpdated = false;
                }
              });

              const fieldsToEvaluate = variablesToEvaluate.filter(v => v.context.exists(o => o.id === sectionEntry.contextObjectId.id));
              fieldsToEvaluate.forEach(field => {
                const element = __(sectionEntry.fieldsArea.inputElements()).find(elem => elem.variableWithType.path.lastName() === field.location.lastName());
                if (element.isDefined()) {
                  element.get().willBeUpdated = true;
                }
              });

              // this.task.summaryVariables = this.task.summaryVariables.concat(sectionEntry.fieldsArea.inputElements().filter(v => v.elementRef.visibleInSummary && !v.variableWithType.variableIsEmpty())
              //   .map(v => new VariableValueWithLabel(v.elementRef.label, Typed.of(v.variableWithType.variable.get()))));
            });

          }
        });

        this.eventBus.taskRequiredFieldsFilled(this.allRequiredFieldsFilled());
    }

    onSectionLengthAutoUpdated(sectionVariableName: string, removedRows: Array<ObjectId>, addedRows: Array<ObjectVariable>) {

      const variables = this.task.formVariables.filter(v => v.variable.isDefined()).map(v => new RootVariable(v.name, v.variable.get()));
      const fieldsState = this.task.fieldsState;

      this.repeatableSections()
        .filter(s => s.forEachVariableName === sectionVariableName)
        .forEach(section => {

          if(removedRows.length === 1 && addedRows.length === 0 && __(this.deletedRows).contains(section.sectionId.id+"|"+removedRows[0])) {
            // ignore this event because it was most probably triggered by me
            this.deletedRows = __(this.deletedRows).filterNot(r => r ===section.sectionId.id+"|"+removedRows[0].id);
          } else {
            section.entries = section.entries.filter(e => __(removedRows).notExists(r => e.contextObjectId.id == r.id));
            section.entries.forEach((entry, index) => entry.updateSectionIndex(index));
          }

          __(addedRows)
            .filterNot(row => __(section.entries).exists(e => e.contextObjectId.id == row.id.id))
            .forEach(row => this.addRowToSectionViewModel(section, variables, fieldsState, row.id));
        });

    }

    makeFormReadOnly() {
      this.readOnly = true;
	    this.sections.forEach(s => s.setformReadOnly(this.readOnly));
    }


    private sectionIsVisible(section: FormSection, visibleSections: Array<FormSectionId>) {
      return section.visibilityExpression.isEmpty() || __(visibleSections).exists(vs => vs.id == section.id.id);
    }

    initRepeatableSectionViewModel(sectionInfo: FormSectionInfo,
                                   sectionVisible: boolean,
                                   formElements: Array<FormElement>,
                                   variables: Array<RootVariableWithType<BusinessVariable, BusinessVariableType>>,
                                   fieldsState: FormFieldsState,
                                   taskReadOnly: boolean): RepeatableSectionViewModel {

      const section = sectionInfo.section;

      const headerVisible = !section.hideHeader;
      const expanded = true;
      const entryTemplate = this.createEntryTemplate(sectionInfo, variables.map(r => new RootVariableType(r.name, r.variableType)), formElements);

      const sectionReadonly = sectionInfo.sectionRef.readOnly;

      const entries = this.initRepeatableSectionEntries(sectionInfo.section.id, sectionInfo.section, sectionInfo.sectionRef,
        sectionInfo.section.forEachVariableName.get(), entryTemplate, variables, fieldsState, sectionReadonly, taskReadOnly); // TODO fill from variables

      const addNewEntryVisible = section.lengthEditable && (section.maximumLength.isEmpty() || section.maximumLength.get() > entries.length);


      return new RepeatableSectionViewModel(this.task, this.task.nodeId, this.eventBus, section.id, sectionInfo.section, sectionInfo.sectionRef, sectionVisible, section.name.getCurrentWithFallback(), headerVisible,
        sectionInfo.section.forEachVariableName.get(), expanded, sectionReadonly,taskReadOnly, addNewEntryVisible, entryTemplate, entries, entryTemplate.tableMode);

    }

    initRepeatableSectionEntries(sectionId: FormSectionId, section: FormSection, sectionRef: FormSectionRef, forEachVariableName: string,
                                 entryTemplate: RepeatableSectionEntryTemplate,
                                 variablesInfo: Array<RootVariableWithType<BusinessVariable, BusinessVariableType>>,
                                 fieldsState: FormFieldsState, sectionReadOnly: boolean, formReadOnly: boolean): Array<RepeatableSectionEntryViewModel> {

      const sectionVariableInfo = __(variablesInfo).find(v => v.name === forEachVariableName);

      if(sectionVariableInfo.isDefined()) {
        const array = <Option<ArrayVariable<BusinessVariable>>>(sectionVariableInfo.get().unwrappedVariableOption());

        if(array.isDefined()) {

          const variables = variablesInfo.filter(v => v.variable.isDefined()).map(v => new RootVariable(v.name, v.variable.get()));

          const removeEntryVisible = section.lengthEditable && (section.minimumLength.isEmpty() || section.minimumLength.get() < array.get().unwrappedValue().length);

          return array.get().unwrappedValue().map((entryObject, index) =>
            this.initRepeatableSectionEntry(sectionId, sectionRef, index, (<ObjectVariable>entryObject).id, entryTemplate, variables, fieldsState, removeEntryVisible, sectionReadOnly));
        } else {
          return [];
        }

      } else {
        throw new Error("Variable for repeatable section not found! ["+forEachVariableName+"]");
      }
    }

    private initRepeatableSectionEntry(sectionId: FormSectionId, sectionRef: FormSectionRef,
                                       sectionIndex: number,
                                       contextObjectId: ObjectId,
                               entryTemplate: RepeatableSectionEntryTemplate,
                               variablesInfo: Array<RootVariable<BusinessVariable>>,
                               fieldsState: FormFieldsState,
                               removeEntryVisible: boolean,
                               readOnly: boolean) {
      return new RepeatableSectionEntryViewModel(sectionIndex, contextObjectId, this.initFieldsArea(entryTemplate, sectionId, sectionRef, Some(sectionIndex), Some(contextObjectId),
        variablesInfo, fieldsState, readOnly),  removeEntryVisible, readOnly, this.readOnly)
    }

    private createEntryTemplate(sectionInfo: FormSectionInfo,
                                variablesTypes: Array<RootVariableType<BusinessVariableType>>,
                                formElements: Array<FormElement>): RepeatableSectionEntryTemplate {

      const inputElementsRefs: Array<InputElementRef> = sectionInfo.section.inputElementsRefsUnwrapped();
      const staticElementsRefs: Array<StaticElementRef> = sectionInfo.section.staticElementsRefsUnwrapped();

      const inputElements = inputElementsRefs.map(elementRef => {
        const element = <InputElement>___(formElements).find(e => e.id.id == elementRef.elementId.id).get();
        const variableType = FormHelper.variableTypeByPath(element.variableTypePath, variablesTypes);
        return new InputElementTemplate(elementRef, element, sectionInfo.section.id, variableType);
      });


      const staticElements = staticElementsRefs.map(e => {
        if(e.className() === LabelRef.className) {
          return LabelRef.copy(<LabelRef>e);
        } else if(e.className() === ActionButtonRef.className) {
          return ActionButtonRef.copy(<ActionButtonRef>e);
        } else {
          throw new Error("Unsupported type");
        }
      }).map(elementRef => {
        const element = <StaticElement>___(formElements).find(e => e.id.id == elementRef.elementId.id).get();
        return new StaticElementTemplate(elementRef, element, sectionInfo.section.id);
      });

      return new RepeatableSectionEntryTemplate(sectionInfo.section.gridHeight, inputElements, staticElements, sectionInfo.section.tableMode);

    }



    private initFieldsArea(entryTemplate: RepeatableSectionEntryTemplate, sectionId: FormSectionId, sectionRef: FormSectionRef,
                           sectionIndex: Option<number>, contextObjectId: Option<ObjectId>,
                           variables: RootVariable<BusinessVariable>[],
                           fieldsState: FormFieldsState, sectionReadOnly: boolean): FieldsAreaViewModel {

      const hideLabels = entryTemplate.tableMode && sectionIndex.exists(i => i > 0);

      let [sectionEntryGridHeight, elementsShiftY] = this.adjustSectionEntryDimensions(entryTemplate, sectionIndex);


      const inputElements = entryTemplate.inputElements.map(elementTemplate => {

        const variablePath = elementTemplate.element.variableTypePath.isRoot()
          ? elementTemplate.element.variableTypePath.toVariablePath([None()])
          : elementTemplate.element.variableTypePath.toVariablePath([sectionIndex, None()]);

        const variable = BusinessVariablesUtil.variableByPath(variablePath, variables);

        const elementState: FormFieldState = fieldsState.elementState(elementTemplate.element.id, contextObjectId);

        return new ElementWithData(elementTemplate.elementRef, elementTemplate.element, sectionRef.id, sectionId, contextObjectId,
          new VariableWithType(variablePath, variable, elementTemplate.variableType), elementState, 0, false);

      });

      const staticElements = entryTemplate.staticElements.map(element => {
        const elementState: FormFieldState = fieldsState.elementState(element.element.id, contextObjectId);
        return new StaticElementWithData(element.elementRef, element.element, element.sectionId, elementState, 0, contextObjectId);
      });

      const inputElementsWithPosition = inputElements.map(element => {
        const readOnly = this.isElementReadonly((<InputElementRef>element.elementRef), element.elementState);
        const hidden = this.isElementHidden((<InputElementRef>element.elementRef), element.elementState);
        const required = !hidden && !readOnly && this.isElementRequired((<InputElementRef>element.elementRef), element.elementState);

        return new InputFieldViewModel(this.task, this.eventBus, this.calculateCssPosition(sectionId, contextObjectId, element, elementsShiftY), <InputElementRef>element.elementRef,
          <InputElement>element.element, element.sectionRefId, element.sectionId, element.contextObjectId,
          element.variableWithType, element.elementState, readOnly, sectionReadOnly, this.readOnly, required, hidden, element.gridShiftY, [], !hideLabels, false);
      });

      const staticElementsWithPosition = staticElements.map(element => {
        const hidden = this.isElementHidden(element.elementRef, element.elementState);
        if(element.element instanceof ActionButton) {
          const readOnly = this.isButtonReadonly(<ActionButtonRef>element.elementRef, element.elementState);

          return new StaticFieldViewModel(this.task, this.calculateCssPosition(sectionId, contextObjectId, element, elementsShiftY), element.elementRef, element.element, element.sectionId,
            element.elementState, readOnly, sectionReadOnly, this.readOnly, hidden, element.gridShiftY, element.contextObjectId, !hideLabels);
        } else {
          return new StaticFieldViewModel(this.task, this.calculateCssPosition(sectionId, contextObjectId, element, elementsShiftY), element.elementRef, element.element, element.sectionId,
            element.elementState, false, sectionReadOnly, this.readOnly, hidden, element.gridShiftY, element.contextObjectId, !hideLabels);
        }

      });

      const cssHeight = this.calculateSectionCssHeight(sectionId, contextObjectId, sectionEntryGridHeight);

      const elements = ___((<Array<InputFieldViewModel|StaticFieldViewModel<StaticElement>>> inputElementsWithPosition).concat(staticElementsWithPosition)).
      sortBy( element => element.elementRef.gridXY.gridY * 1000000 + element.elementRef.gridXY.gridX * 1000. + element.element.id.id).value();

      return new FieldsAreaViewModel(this.eventBus,() => this.getTaskInfo(), this.formDiff,
        sectionEntryGridHeight, cssHeight, elements,
        sectionRef.readOnly, this.readOnly, this.attachmentsUrlsViewModel);
    }

    public setAreasChanges(previousTasks: Array<TaskFormViewModel>) {
      this.sections.forEach(section => {
        let anyChange = false;

        if(section.isSingularSection) {
          let previousElements: Array<InputFieldViewModel> = [];

          previousTasks.forEach(t => t.sections.filter(s => s.isSingularSection).forEach(s =>
            s.asSingularSection().fieldsArea.inputElements().forEach(elem => {
              if(__(previousElements).notExists(e => e.element.id.id === elem.element.id.id)){
                previousElements.push(elem)
              } else {
                previousElements = previousElements.map(e => {
                  if(e.element.id.id === elem.element.id.id) {
                    return elem;
                  } else {
                    return e;
                  }
                })
              }
            })));

          section.asSingularSection().fieldsArea.inputElements().forEach(input => {
            input.wasUpdated = __(previousElements).notExists(t => {
              return this.isElementsValueEquals(t, input);
            }) && (previousElements.length > 0 || !input.variableWithType.variableIsEmpty());

            anyChange = anyChange || input.wasUpdated;
          });
        } else if(section.isRepeatableSection) {
          let previousElements: Array<ElementWithSectionIndex> = [];

          previousTasks.forEach(task =>
            task.sections.filter(section => section.isRepeatableSection).forEach(section =>
            section.asRepeatableSection().entries.forEach(entry =>
              entry.fieldsArea.inputElements().forEach(elem => {
                if(__(previousElements).notExists(e => e.element.element.id.id === elem.element.id.id && e.sectionIndex === entry.sectionIndex)){
                  previousElements.push(new ElementWithSectionIndex(entry.sectionIndex, elem))
                } else {
                  previousElements = previousElements.map(e => {
                    if(e.element.element.id.id === elem.element.id.id && e.sectionIndex) {
                      return new ElementWithSectionIndex(entry.sectionIndex, elem);
                    } else {
                      return e;
                    }
                  })
                }
              }))));

          section.asRepeatableSection().entries.forEach(entry => {
            entry.fieldsArea.inputElements().forEach(input => {
              input.wasUpdated = __(previousElements).notExists(t => {
                return this.isElementsValueEquals(t.element, input) && t.sectionIndex === entry.sectionIndex;
              }) && (previousElements.length > 0 || !input.variableWithType.variableIsEmpty());

              anyChange = anyChange || input.wasUpdated;
            })
          });
          section.asRepeatableSection().wasUpdated = anyChange;
        }

        section.expanded = anyChange;
      })
    }

    private isElementsValueEquals(el1: InputFieldViewModel, el2: InputFieldViewModel) {
      return el1.element.id.id === el2.element.id.id &&
        ((el1.variableWithType.variable.isDefined() && el2.variableWithType.variable.isDefined() &&
        el1.variableWithType.variable.get().valueToSimpleString() === el2.variableWithType.variable.get().valueToSimpleString()) ||
        (el1.variableWithType.variable.isEmpty() && el2.variableWithType.variable.isEmpty()));
    }

    private defaultVariable(element: InputElement): Option<RootVariable<BusinessVariable>> {
      if(element instanceof Checkbox) {
        return element.defaultValue.isEmpty() ? None() : Some(new RootVariable(element.variableTypePath.last(), Typed.of(element.defaultValue)));
      } else if(element instanceof RadioButton || element instanceof DropList) {
        return element.defaultValue.isEmpty() ? None() : Some(new RootVariable(element.variableTypePath.last(), element.defaultValue.get()));
      } else {
        return None();
      }
    }

    private updateElementsCssPositions() {
      this.sections.forEach(section => {
        if(section.isRepeatableSection) {
          section.asRepeatableSection().entries.forEach(entry => {
            this.updateRepeatableFieldsAreaElementsPositions(section.asRepeatableSection().entryTemplate, entry.fieldsArea, section.sectionId, Some(entry.sectionIndex), Some(entry.contextObjectId));
          })
        } else if (section.isSingularSection) {
          this.updateSingularFieldsAreaElementsPositions(section.asSingularSection().fieldsArea, section.sectionId);
        } else {
          throw new Error("Incorrect section type");
        }
      });
    }

    private updateSingularFieldsAreaElementsPositions(fieldsArea: FieldsAreaViewModel, sectionId: FormSectionId) {
      fieldsArea.cssHeight = this.calculateSectionCssHeight(sectionId, None(), fieldsArea.gridHeight);
      fieldsArea.elements.forEach(e => e.cssPosition = this.calculateCssPosition(sectionId, None(), e, 0));
      this.updateFieldsAreaElementsOrder(fieldsArea);
    }

    private updateRepeatableFieldsAreaElementsPositions(entryTemplate: RepeatableSectionEntryTemplate, fieldsArea: FieldsAreaViewModel, sectionId: FormSectionId, sectionIndex: Option<number>, contextObjectId: Option<ObjectId>) {

      let [sectionEntryGridHeight, elementsShiftY] = this.adjustSectionEntryDimensions(entryTemplate, sectionIndex);

      const hideLabels = entryTemplate.tableMode && sectionIndex.exists(i => i > 0);

      fieldsArea.cssHeight = this.calculateSectionCssHeight(sectionId, contextObjectId, sectionEntryGridHeight);
      fieldsArea.elements.forEach(e => {
        e.cssPosition = this.calculateCssPosition(sectionId, contextObjectId, e, elementsShiftY);
        if (e instanceof InputFieldViewModel) {
          e.labelVisible = !hideLabels;
        }
      });
      this.updateFieldsAreaElementsOrder(fieldsArea);
    }

    private updateFieldsAreaElementsOrder(fieldsArea: FieldsAreaViewModel) {
      fieldsArea.elements = __(fieldsArea.elements).sortBy( element => element.cssPosition.index);
    }


    private adjustSectionEntryDimensions(entryTemplate: RepeatableSectionEntryTemplate, sectionIndex: Option<number>): [number, number] {
      let sectionEntryGridHeight = entryTemplate.sectionGridHeight;
      let elementsShiftY = 0; // for table mode we need to shift elements to the top of section entry

      if(entryTemplate.tableMode) {
        const minY = ___(entryTemplate.inputElements).map(e => e.elementRef.gridXY.gridY).minOrDefault(0);
        const maxYPlusHeight = ___(entryTemplate.inputElements).map(e => e.elementRef.gridXY.gridY + e.elementRef.gridSize.height).maxOrDefault(0);
        sectionEntryGridHeight = maxYPlusHeight - minY + TaskFormConfig.formHeaderGridHeight; // we need to compress section so it fits elements
        elementsShiftY = -minY + TaskFormConfig.formHeaderGridHeight;

        // For fist table row we need extra space for labels
        if(sectionIndex.contains(0)) {
          if(___(entryTemplate.inputElements).exists(i => i.elementRef.label.notEmpty() && i.elementRef.labelPosition.isTopAlign())) {
            sectionEntryGridHeight += 2; // space for labels in first row
            elementsShiftY += 2;
          }
        }
      }
      return [sectionEntryGridHeight, elementsShiftY];
    }

    getTaskInfo(): TaskInfo {
		  return new TaskInfo(this.task.flowIdUnwrapped(), this.task.nodeId, this.task.cursorInfo, this.authorizeByFlow);
	  }

    calculateCssPosition(sectionId: FormSectionId, contextObjectId: Option<ObjectId>, element: SimpleElementWithDataInterface, elementGridShiftY: number) {

      const left = this.calculateElementLeft(element.elementRef);
      const top = this.calculateElementTop(sectionId, contextObjectId, element.elementRef, elementGridShiftY);

      return new CssRect(left + "rem",
        top + "rem",
        this.calculateElementWidth(element.elementRef) + "rem",
        this.calculateElementHeight(sectionId, contextObjectId, element.elementRef) + "rem",
        100000 * top + left);
    }

    private isElementReadonly(elementRef: InputElementRef, elementState: FormFieldState) {
      return elementRef.readOnly.booleanOrElse(() => elementState.readOnly);
    }

    private isButtonReadonly(actionButtonRef: ActionButtonRef, elementState: FormFieldState) {
      return actionButtonRef.readOnly.booleanOrElse(() => elementState.readOnly);
    }

    private isElementRequired(elementRef: InputElementRef, elementState: FormFieldState) {
      return elementRef.required.booleanOrElse(() => elementState.required);
    }

    private isElementHidden(elementRef: FormElementRef, elementState: FormFieldState) {
      return elementRef.hidden.booleanOrElse(() => elementState.hidden);
    }

    calculateSectionCssHeight(sectionId: FormSectionId, contextObjectId: Option<ObjectId>, sectionGridHeight: number): string {
      const sectionDiff = this.formDiff.diffForSection(sectionId, contextObjectId);
      const gridHeight = (sectionGridHeight + sectionDiff.map(s => s.gridDHeight).getOrElse(0)) - TaskFormConfig.formHeaderGridHeight;
      return gridHeight * TaskFormConfig.gridRemSize + "rem";
    };

    calculateElementLeft(elementRef: FormElementRef): number {
      return elementRef.gridXY.gridX * TaskFormConfig.gridRemSize
    };

    calculateElementTop(sectionId: FormSectionId, contextObjectId: Option<ObjectId>, elementRef: FormElementRef, elementGridShiftY: number): number {
      const elementDiff = this.formDiff.diffForElement(sectionId, contextObjectId, elementRef.id);
      const top = (elementRef.gridXY.gridY + elementGridShiftY - TaskFormConfig.formHeaderGridHeight + elementDiff.map(d => d.gridDY).getOrElse(0)) * TaskFormConfig.gridRemSize;
      return top;
    };

    calculateElementHeight(sectionId: FormSectionId, contextObjectId: Option<ObjectId>, elementRef: FormElementRef) {
      const elementDiff = this.formDiff.diffForElement(sectionId, contextObjectId, elementRef.id);

      return (elementRef.gridSize.height + elementDiff.map(e => e.gridDHeight).getOrElse(0))
        * TaskFormConfig.gridRemSize - TaskFormConfig.elementsMargin
    };

    calculateElementWidth(elementRef: FormElementRef): number {
      return elementRef.gridSize.width * TaskFormConfig.gridRemSize - TaskFormConfig.elementsMargin;
    };


    initSingularSectionViewModel(sectionInfo: FormSectionInfo,
                                 sectionVisible: boolean,
                                 formElements: Array<FormElement>,
                                 variablesInfo: Array<RootVariableWithType<BusinessVariable, BusinessVariableType>>,
                                 fieldsState: FormFieldsState,
                                 taskReadOnly: boolean) {
      const section = sectionInfo.section;
      const sectionReadOnly = sectionInfo.sectionRef.readOnly;

      const headerVisible = !section.hideHeader;
      const expanded = true;
      const entryTemplate = this.createEntryTemplate(sectionInfo, variablesInfo.map(r => new RootVariableType(r.name, r.variableType)), formElements);

      const variables = variablesInfo.filter(v => v.variable.isDefined()).map(v => new RootVariable(v.name, v.variable.get()));
      const fieldsArea: FieldsAreaViewModel = this.initFieldsArea(entryTemplate, sectionInfo.section.id, sectionInfo.sectionRef, None(), None(),
        variables, fieldsState, sectionReadOnly);

      return new SingularSectionViewModel(section.id, sectionInfo.sectionRef, sectionVisible, section.name.getCurrentWithFallback(), headerVisible, expanded, sectionReadOnly, taskReadOnly, fieldsArea);

    }

    addSectionRow(sectionId: FormSectionId){

      if(this.taskServerModel === null) {
        throw new Error("Task server model is not available");
      }

      const contextObjectId = ObjectId.next();
      this.taskServerModel.addSectionRow(this.task.toTaskIdentifier(), sectionId, contextObjectId);

      this.sections
        .filter(section => section.sectionId.id === sectionId.id)
        .forEach(s => {
          const repeatableSection = s.asRepeatableSection();
          const section = repeatableSection.section;
          const defaultValues = repeatableSection.entryTemplate.inputElements.map(element => this.defaultVariable(element.element)).filter(v => v.isDefined()).map(v => v.get());

          this.task.formVariables.filter(v => section.forEachVariableName.isDefined() && v.name === section.forEachVariableName.get()).forEach(v => {
            v.unwrappedVariableOption().forEach(m => {
              (<ArrayVariable<ObjectVariable>>m).unwrappedValue().forEach(aV => {
                defaultValues.forEach(dV => aV.setValue(dV.name, dV.unwrappedVariable()));
              })
            });
          });

          this.addRowToSectionViewModel(repeatableSection,
            this.task.formVariables.filter(v => v.variable.isDefined()).map(v => new RootVariable(v.name, v.variable.get())),
            this.task.fieldsState,
            contextObjectId
            );

          const addNewEntryVisible = section.lengthEditable && (section.maximumLength.isEmpty() || section.maximumLength.get() > repeatableSection.entries.length);
          const removeEntryVisible = section.lengthEditable && (section.minimumLength.isEmpty() || section.minimumLength.get() < repeatableSection.entries.length);

          repeatableSection.addNewEntryVisible = addNewEntryVisible;

          repeatableSection.entries.forEach(entry => {
            entry.removeEntryVisible = removeEntryVisible;
          });

	        repeatableSection.resetErrors();
        });
    }

    private addRowToSectionViewModel(section: RepeatableSectionViewModel, variables: RootVariable<BusinessVariable>[], fieldsState: FormFieldsState, objectId: ObjectId) {
      section.entries.push(new RepeatableSectionEntryViewModel(section.entries.length, objectId,
        this.initFieldsArea(section.entryTemplate, section.sectionId, section.sectionRef, Some(section.entries.length), Some(objectId), variables, fieldsState, section.ownReadOnly), true, section.ownReadOnly, this.readOnly))
    }


    deleteSectionRow(sectionId: FormSectionId, contextObjectId: ObjectId){

      if(this.taskServerModel === null) {
        throw new Error("Task server model is not available");
      }
      this.taskServerModel.deleteSectionRow(this.task.toTaskIdentifier(), sectionId, contextObjectId);

      this.sections
        .filter(section => section.sectionId.id === sectionId.id)
        .map(sectionViewModel => {
          const repeatableSection = <RepeatableSectionViewModel>sectionViewModel;

          const sectionIndex = __(repeatableSection.entries).findIndexOf(e => e.contextObjectId.id === contextObjectId.id).get();

          repeatableSection.entries.splice(sectionIndex, 1);

          const section = repeatableSection.section;
          const addNewEntryVisible = section.lengthEditable && (section.maximumLength.isEmpty() || section.maximumLength.get() > repeatableSection.entries.length);
          const removeEntryVisible = section.lengthEditable && (section.minimumLength.isEmpty() || section.minimumLength.get() < repeatableSection.entries.length);

          repeatableSection.addNewEntryVisible = addNewEntryVisible;

          // this.task.formFieldsStateNonCached.elements.forEach(s => {
          //           //   const elementIsInThatSection = (__(section.inputElementsRefsUnwrapped()).exists((e: InputElementRef) => e.elementId.id === s.elementId.id)
          //           //     || __(section.staticElementsRefsUnwrapped()).exists((e: StaticElementRef) => e.elementId.id === s.elementId.id));
          //           //   if(elementIsInThatSection && s.contextObjectId.exists(i => i > sectionIndex)) {
          //           //     s.sectionIndex = s.sectionIndex.map(i => i - 1); // elements shifted up
          //           //   }
          //           // });

          repeatableSection.entries.forEach((entry, index) => {
            entry.updateSectionIndex(index);
            entry.removeEntryVisible = removeEntryVisible;
          });

	        repeatableSection.resetErrors();
        });


      this.deletedRows.push(sectionId.id+"|"+contextObjectId.id);
    }

    private extractInputFields(): Array<InputFieldViewModel> {
      const fieldsAreas: Array<FieldsAreaViewModel> = __(this.sections).flatMap(section => {
        if (!section.visible) {
          return [];
        } else if (section instanceof RepeatableSectionViewModel) {
          return section.entries.map(entry => entry.fieldsArea);
        } else if (section instanceof SingularSectionViewModel) {
          return [section.fieldsArea];
        } else {
          throw new Error("Unsupported section type [" + section + "]");
        }
      });

      return __(fieldsAreas).flatMap(area => area.inputElements());
    }

    private onFormElementHeightChanged() {
      this.formDiff.updateDiff(this.sections);
      this.updateElementsCssPositions();
      this.eventBus.formElementsPositionChanged();
    }

    filePreviewRequested($event: FilePreviewRequest) {
      this.fileViewerVisible = true;
      this.viewableFiles = $event.files;
      this.fileViewerIndex = $event.index;
    }

    emailPreviewRequested($event: EmailPreviewRequest) {
      this.emailViewerVisible = true;
      this.viewableEmail = $event.emailUri;
    }

    onScreenModelContextIdChanged(newId: AggregateId) {
      this.tasksEventBus.flowIdChanged(this.task.flowIdUnwrapped(), new FlowId(newId.id));
    }
  }


