<!--<div class="error" *ngIf="viewModel !== null && viewModel.error !== null">{{viewModel.error}}</div>-->
<!--<my-screen *ngIf="viewModel !== null && viewModel.screenInstanceId !== null" [screenInstanceId]="viewModel.screenInstanceId"-->
<!--           terminateOnDestroy="always"-->
<!--           [eventBus]="viewModel.screenExternalEventBus" />-->


<ng-container *ngIf="viewModel && taskViewModel && !taskSubmitted">

  <my-task-form [class.mobile]="fullScreen" *ngIf="viewModel.screenDisabled" [viewModel]="viewModel"/>

  <my-shared-screen *ngIf="!viewModel.screenDisabled && viewModel.screenInstanceId"
                    [class.mobile]="fullScreen"
                    [screenInstanceId]="viewModel.screenInstanceId"
                    terminateOnDestroy="always" [readOnly]="taskViewModel.readOnly"
                    [submitRequest]="taskViewModel.submitRequest" (submitted)="taskViewModel.onSubmitted()"
                    (screenModelContextIdChanged)="viewModel.onScreenModelContextIdChanged($event)"
                    [filePreviewHandledExternally]="true"
                    (filePreviewRequest)="viewModel.filePreviewRequested($event)"
                    [emailPreviewHandledExternally]="true"
                    (emailPreviewRequest)="viewModel.emailPreviewRequested($event)"
  ></my-shared-screen>

  <my-task-action-buttons *ngIf="formRendered" [viewModel]="taskViewModel" [mobile]="fullScreen" [hideAlternativeButtons]="true"/>

  <ng-container *ngIf="!viewModel.screenDisabled && !viewModel.screenInstanceId">
    <!-- maybe display preloader -->
  </ng-container>

</ng-container>

<div *ngIf="taskSubmitted" class="submittedInfo">
  {{'old_task_form_out_submitted' | i18n}}
</div>
