import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {Duration, mySetTimeout, mySetTimeoutNoAngular} from "@utils";
import {DurationPickerEventBus} from "./DurationPickerEventBus";
import {DurationPickerViewModel} from "./DurationPickerViewModel";

@Component({
  selector: 'my-duration-picker',
  templateUrl: './duration-picker.component.html',
  // styleUrls: ['./duration-picker.component.shadow.scss']
})
// Directive annotation is here so compiler would not complain, but it should have no side effects
export class DurationPickerComponent implements OnInit {

  private _value: Duration | null = null;
  get value(): Duration | null {
    return this._value;
  }
  @Input({required:true}) set value(value: Duration | null) {
    this._value = value;
    if(this.viewModel) {
      this.viewModel.updateValue(value);
    }
  }
  @Output() valueChange = new EventEmitter<Duration | null>();

  private _disabled: boolean = false;
  get disabled(): boolean {
    return this._disabled;
  }
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    if(this.viewModel) {
      this.viewModel.updateDisabled(value);
    }
  }

  @Input() onChange: ((duration: Duration | null) => void)|undefined;
  @Input({required:true}) hoursInDay!: number;
  @Input({required:true}) useSeconds!: boolean;
  @Input() placeholder: string|null = null;

  readonly eventBus = new DurationPickerEventBus();
  viewModel!: DurationPickerViewModel;

  @ViewChild("DurationPickerInput") inputElement: ElementRef<HTMLInputElement>|undefined;

  ngOnInit() {
    this.viewModel = new DurationPickerViewModel(this.eventBus,
      this.value !== undefined ? this.value : null,
      this.placeholder !== null ? this.placeholder : (this.useSeconds ? "h m s" : "h m"),
      this.hoursInDay,
      this.useSeconds,
      this.disabled,
      this.valueChange,
      this.onChange ? this.onChange : (duration: Duration | null) => {});
  }

  popupClosed() {
    this.viewModel.onBlur();
  };


  onFocused() {
    this.selectInputText();
    this.viewModel.onFocus();
  }

  onAccepted() {
    if(this.inputElement === undefined) {
      throw new Error("No inputElement");
    } else {
      this.inputElement.nativeElement.blur();
    }
    this.viewModel.onBlur();
  }

  private selectInputText() {
    mySetTimeoutNoAngular(() => {
      if(this.inputElement === undefined) {
        throw new Error("No inputElement");
      } else {
        this.inputElement?.nativeElement.select();
      }
    });
  }


}
