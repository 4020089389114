import {AggregateId, EventBus, FileUri, Option, ScreenInstanceId} from "@utils";
import {BusinessVariable} from "@shared-model";
import {ModelContextIdChanged} from "../../modules/screen.module";

export class ScreenExternalEventBus extends EventBus{

  filesPreviewRequested(files: Array<any>, index: number) {}
  emailPreviewRequested(emailUri: FileUri) {}

  screenTerminateRequested(screenInstanceId: ScreenInstanceId) {}
  screenTerminated(screenInstanceId: ScreenInstanceId) {}

  outputParameterChanged(input: boolean, variableName: string, value: Option<BusinessVariable>) {}

  inputParametersChanged(params: Array<[string, string]>) {}

  screenModelContextIdChanged(context: AggregateId) {}

  debugRequested() {}
}
