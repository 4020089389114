export class MyIcons {
  static MI_USER_ADD = "\ue96c";
  static MI_USER = "\ue96b";
  static MI_CIRCLE_CROSS = "\ue9ea";
  static MI_CIRCLE_PLUS = "\uea97";
  static MI_CIRCLE_CIRCLE = "\uea98";
  static MI_PLUS_SMALL = "\ue910";
  static MI_ALARM_CLOCK = "\ue905";
  static MI_CHECK_CIRCLE = "\ue9be";
  static MI_MAIL = "\ue944";
  static MI_PAPER_PLANE = "\uea3e";
  static MI_SEQUENTIAL = "\uea92";
  static MI_PARALLEL = "\uea91";
  static MI_PROCESS = "\uea44";
  static MI_INCOMING = "\uea27";
  static MI_CALCULATOR = "\ue93a";
  static MI_CLOCK = "\ue906";
  static MI_SECTION_HORIZONTAL = "\uea85";
}
