import {
  ApplicationIcon,
  ApplicationIcons, ApplicationName, BusinessVariable,
  FlowImportance, GroupVariable, ProcessesNamingResponseTransformed,
  ProcessFlowSummary,
  TaskSummaryViewModel
} from "@shared-model";
import {
  __,
  AnyFlowId,
  AnyPersonId, AnyPersonIdHelper,
  ApplicationId, colorId,
  FlowId, hashCode, i18n,
  InstanceId,
  LocalDateTime,
  None,
  Option, PersonId,
  ProcessId, Typed
} from "@utils";

interface PersonAssigned {
  personId: AnyPersonId;
  initiator: boolean;
  inactive: boolean;
}

interface FlowLabel {
  name: string;
  color: number;
}

export class FlowSummaryViewModel {

  flowRouterLinkSuffix: string;

  onScreen: boolean = false;
  idClass: string;
  step: string;
  additionalSteps: Array<string> = [];
  additionalStatusesVisible: boolean = false;
  canChangeLabels: boolean = true;
  labels: Array<FlowLabel> = [];
  systemLabels: Array<{ value:BusinessVariable, group: boolean }> = [];
  onTime: boolean = false;
  aRisk: boolean = false;
  delayed: boolean = false;

  flowCodeLabel: string;
  adHocTask: boolean;

  constructor(
    public flowId: AnyFlowId,
    public processId: ProcessId,
    public instanceId: InstanceId,
    public created: LocalDateTime,
    public flowCode: string,
    public flowDescription: string,
    public importance: FlowImportance,
    public processName: string,
    public initiator: PersonId|undefined,
    readonly applicationId: Option<ApplicationId>,
    readonly applicationName: string,
    readonly applicationIcon: ApplicationIcon,
    public personsActive: Array<PersonAssigned>,
    public personsAssigned: Array<PersonAssigned>,
    public completed: boolean,
    readonly notStarted: boolean,
    readonly error: boolean,
    readonly assignmentNeeded: boolean,
    readonly steps: Array<string>,
    labels: Array<string>,
    systemLabels: Array<BusinessVariable>,
    readonly comments: number
  ) {
    this.flowCodeLabel = flowCode.length > 0 ? flowCode : i18n("flow_new");
    this.flowRouterLinkSuffix = flowCode.length > 0 ? flowCode : "@"+this.flowId.urlSerialized();

    this.idClass = "flow-id-"+flowId.urlSerialized();
    this.adHocTask = processId.isAdHocTask();

    if(this.steps.length === 0) {
      this.step = "No steps";
      this.additionalSteps = [];
    } else {
      this.step = steps[0];
      this.additionalSteps = steps.slice(1);
    }

    this.initLabels(labels, systemLabels);
    this.updateFlags();
  }

  private initLabels(labels: Array<string>, systemLabels: Array<BusinessVariable>) {
    this.labels = labels.map(l => {
      return {name: l, color: colorId(l)};
    });

    this.systemLabels = systemLabels.map(l => ({
      value: l,
      group: l.className() === GroupVariable.className
    }));
  }

  static of(f: ProcessFlowSummary, names: ProcessesNamingResponseTransformed, applicationsNames: Array<ApplicationName>): FlowSummaryViewModel {

    let personsActive: Array<PersonAssigned> = [];
    let personsAssigned: Array<PersonAssigned> = [];

    const initiator = f.initialPersonIdUnwrapped().map(p => p.asPersonId()).getOrUndefined();


    __(f.currentTasksSummary).forEach(t => t.personsAssignedUnwrapped().forEach(person => {
      personsAssigned.push({
        personId: person,
        initiator: initiator !== undefined && AnyPersonIdHelper.equals(person, initiator),
        inactive: false
      });
      personsActive.push({
        personId: person,
        initiator: false,
        inactive: false
      });
    }));

    f.assignedPersons.reverse().forEach(personsInRole => {

      personsInRole[1].forEach(person => {
        const personId = Typed.value(person);
        const __personsAssigned = __(personsAssigned);
        if(!__personsAssigned.exists(p => AnyPersonIdHelper.equals(p.personId, personId))) {
          personsAssigned.push({
            personId: personId,
            initiator: initiator !== undefined && AnyPersonIdHelper.equals(personId, initiator),
            inactive: true
          });
        }
      });

    });


    personsActive = __(personsActive).uniqueBy(p => p.personId.serialize());

    const processId = ProcessId.of(f.parentsIds.processId);
    const applicationId = names.getProcessApplication(processId);

    const applicationName = applicationId.flatMap(a => __(applicationsNames).find(n => n.id.id === a.id)).getOrElse(ApplicationName.global);

    const currentStatus = f.cursors.map(c => {
      if(c.cursorPosition.nodeId.isDefined()) {
        return names.getNodeName(f.parentsIds.processReleaseId, c.cursorPosition.nodeId.get()).filter(n => n.length > 0).getOrElse("Node " + c.cursorPosition.nodeId.get());
      } else {
        return "Edge " + c.cursorPosition.edgeId.get();
      }
    });


      // (f.currentTasksSummary.map(t => names.getNodeName(f.parentsIds.processReleaseId, t.nodeId).getOrElse("?"))).concat
      // (f.statusInfo.finishNodesReached.map(n => names.getNodeName(f.parentsIds.processReleaseId, n).getOrElse("?")));



    return new FlowSummaryViewModel(
      new FlowId(f.flowId.id),
      ProcessId.of(f.parentsIds.processId),
      new InstanceId(f.parentsIds.processInstanceId.id),
      f.statusInfo.created,
      f.flowNameData.flowCode,
      f.flowNameData.flowDescription.length > 0 ? f.flowNameData.flowDescription : f.flowNameData.flowCode,
      f.importance,
      names.getProcessName(processId),
      initiator,
      applicationId,
      applicationName.name,
      ApplicationIcons.getApplicationOptionIcon(applicationId, applicationName.iconCode, applicationName.colorId),
      personsActive,
      personsAssigned.reverse(),
      f.statusInfo.isCompleted(),
      f.flowNameData.flowCode.length === 0,
      __(f.cursors).exists(c => c.isError()),
      __(f.currentTasksSummary).exists(t => t.personsAssignedUnwrapped().length === 0),
      currentStatus,
      f.labels,
      f.systemLabelsUnwrapped(),
      f.comments
    )
  }

  setImportance(importance: FlowImportance) {
    this.importance = importance;
  }

  private updateFlags() {
this.onTime = this.step === "On time";
    this.aRisk = this.step === "A risk";
    this.delayed = this.step === "Delayed";
  }
}
