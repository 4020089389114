import {Component, Input} from "@angular/core";
import {WidgetComponentViewModel} from "./widget-component.view-model";

@Component({
  selector: 'widget-component',
  templateUrl: './widget-component.component.html',
  host: {
    "[class]": "'componentBody WidgetComponent ' + viewModel.customCssClass",
    "[style]": "viewModel.css",
    "[class.readonly]": "viewModel.disabled",
    "[class.validationError]": "viewModel.validationError",
  }
})
export class WidgetComponentComponent {
  @Input({required:true}) viewModel!: WidgetComponentViewModel;
}
