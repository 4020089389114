import {AggregateId, EventBus, Option} from "@utils";
import {ModelContextIdChanged, ScreenDebugInfo} from "..";
import {BusinessVariable} from "@shared-model";


export class ScreenRuntimeEventBus extends EventBus {

    componentHeightChanged() {}

    componentsStateUpdated() {}

    debugInfoAvailableLoaded(debugInfo: boolean) {}

    debugInfoLoaded(debugInfo: ScreenDebugInfo) {}

    expressionEvaluated(callNumber: number, result: BusinessVariable, durationMillis: number) {}
    expressionEvaluatedWithError(callNumber: number, error: string, durationMillis: number) {}

    outputParameterChanged(input: boolean, variableName: string, businessVariableOptional: Option<BusinessVariable>) {}

    /** Used to pass information e.g. about new materialized flow id*/
    screenModelContextIdChanged(context: AggregateId) {}

  }
